import { Link } from 'react-router-dom'
import { format } from 'date-fns'

export default function ListOfBoards(data: any) {
  function getAnnouncements() {
    return data.announcementData.map((announcement: any) => (
      <li className="row" key={announcement.id}>
        <div className="subject">
          <div className="chip gray sm natural af">공지</div>
          <div className="post">
            <Link reloadDocument to={`/community/announcement/${announcement.id}`}>
              <span className="txt bold">{announcement.title}</span>
              <span className="img">
                {announcement.content.includes('<img') ? (
                  <img src="/images/contents/ico_camera.svg" alt="images" />
                ) : (
                  <></>
                )}
              </span>
            </Link>
          </div>
        </div>
        <span className="nickname bold">운영자</span>
        <span className="date-noline">{format(new Date(announcement.createdAt), 'yyyy.MM.dd HH:mm')}</span>
      </li>
    ))
  }

  function getBoards() {
    return data.boardData.boards.map((board: any) => (
      <li className="row" key={board.id}>
        <div className="subject">
          <div className="chip gray sm natural af">{board.category.name}</div>
          {/* <div className="hidden">{board.id}</div> */}
          <div className="post">
            <Link reloadDocument to={`/community/boards/view/${board.id}`}>
              <span className="txt">{board.title}</span>
              <span className="img">
                {board.content.includes('<img') ? <img src="/images/contents/ico_camera.svg" alt="images" /> : <></>}
              </span>
              <span className="reply-cnt">
                {'[' + board.comments.filter((comment: any) => !comment.isDeleted).length + ']'}
              </span>
            </Link>
          </div>
        </div>
        <span className="nickname">{board.user.nick}</span>
        <span className="date">{format(new Date(board.createdAt), 'yyyy.MM.dd HH:mm')}</span>
        <span className="reply">
          {board.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
        </span>
        <span className="recommend">
          <img src="/images/contents/ico_main_like.svg" alt="like" />
          {board.likes.length.toLocaleString()}
        </span>
      </li>
    ))
  }

  return (
    <div className="community-box">
      <div className="community-head">
        <span>제목</span>
        <span>닉네임</span>
        <span>작성일</span>
        <span>댓글수</span>
        <span>추천수</span>
      </div>
      <div className="community-body">
        {data.announcementData.length === 0 && data.boardData.boards.length === 0 ? (
          <ul className="body">
            <li className="row list-null">게시글이 없습니다.</li>
          </ul>
        ) : (
          <ul className="body">
            {getAnnouncements()}
            {getBoards()}
          </ul>
        )}
      </div>
    </div>
  )
}
