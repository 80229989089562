import v2 from './v2'

export async function updateUserNickname(nickname: string) {
  return v2.put('/api/user/nickname', { nickname: nickname }, { withCredentials: true }).then(res => res.data)
}

export async function checkNickDuplicate(params: { nick: string }) {
  return v2.get(`/api/community/duplicateNick/${params.nick}`).then(resp => resp.data)
}

export async function getUserComments() {
  return v2.get('/api/user/comments', { withCredentials: true }).then(resp => resp.data)
}

export async function getUserBoards() {
  return v2.get('/api/user/boards', { withCredentials: true }).then(resp => resp.data)
}

export async function getUserPoints() {
  return v2.get('/api/user/points', { withCredentials: true }).then(resp => resp.data)
}
