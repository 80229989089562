import cx from 'classnames'
import { Link } from 'react-router-dom'
import commStyles from '../pages/broadcast/commBroad.module.scss'
import { generateRandomString } from '@/helpers/hidden'

function BjProfile({
  i,
  n,
  data,
  category,
  postfix,
}: {
  i: string
  n: string
  data: number
  category: string
  postfix: string
}) {
  return (
    <Link to={`/broadcast/${i}`} className={commStyles.box}>
      <div className={commStyles.img}>
        <img src={`https://profile.img.afreecatv.com/LOGO/${i.substring(0, 2)}/${i}/${i}.jpg`} alt="thumn" />
      </div>
      <div className={commStyles.txt}>
        <span className={commStyles.nick}>
          {n}
          <span className={commStyles.nickIco}>
            <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
          </span>
        </span>
        <span className={commStyles.category}>{category}</span>
      </div>
      <div className={commStyles.cnt}>
        <span>
          {data.toLocaleString()} {postfix}
        </span>
      </div>
    </Link>
  )
}

function HiddenProfile({ data, postfix }: { data: number; postfix: string }) {
  return (
    <Link to={`https://www.afreecatv.com`} className={commStyles.box}>
      <div className={commStyles.img}>
        <img src={'/images/contents/ico_logo_afreecatv.svg'} alt="thumn" />
      </div>
      <div className={commStyles.txt}>
        <span className={commStyles.nick}>
          {generateRandomString(10)}
          <span className={commStyles.nickIco}>
            <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
          </span>
        </span>
      </div>
      <div className={commStyles.cnt}>
        <span>
          {data.toLocaleString()} {postfix}
        </span>
      </div>
    </Link>
  )
}

export default function TopBj({
  topDonated,
  topViewer,
  topHourly,
  showLiveUpdate,
  categories,
  bjList,
  hiddenIDs,
}: {
  topDonated: any
  topViewer: any
  topHourly: any
  showLiveUpdate: boolean
  categories: any
  bjList: any
  hiddenIDs: any
}) {
  function getBjCategory(bjID: string): string {
    const foundBj = bjList?.find((bj: any) => bj.i === bjID)
    if (foundBj && foundBj.c && foundBj.c.length > 0) {
      return categories[String(foundBj.c[0] * 10000 + foundBj.c[1]).padStart(8, '0')]
    } else {
      return ''
    }
  }

  return (
    <div className={commStyles.rankingTopArea}>
      <ul className={commStyles.list}>
        <li className={cx({ [commStyles.half]: !topHourly })}>
          <div className="box-title broadcast">
            <h2 className="blue">별풍선 TOP</h2>
            {showLiveUpdate ? <span className="chip live">실시간 업데이트</span> : <></>}
          </div>
          {topDonated && !hiddenIDs.includes(topDonated.i) && (
            <BjProfile
              i={topDonated.i}
              n={topDonated.n}
              data={topDonated.b}
              category={getBjCategory(topDonated.i)}
              postfix={'개'}
            />
          )}
          {topDonated && hiddenIDs.includes(topDonated.i) && <HiddenProfile data={topDonated.b} postfix={'개'} />}
        </li>
        <li className={cx({ [commStyles.half]: !topHourly })}>
          <div className="box-title broadcast">
            <h2 className="green">시청자 TOP</h2>
            {showLiveUpdate ? <span className="chip live">실시간 업데이트</span> : <></>}
          </div>
          {topViewer && !hiddenIDs.includes(topViewer.i) && (
            <BjProfile
              i={topViewer.i}
              n={topViewer.n}
              data={topViewer.v}
              category={getBjCategory(topViewer.i)}
              postfix={'명'}
            />
          )}
          {topViewer && hiddenIDs.includes(topViewer.i) && <HiddenProfile data={topViewer.v} postfix={'명'} />}
        </li>
        {topHourly ? (
          <li>
            <div className="box-title broadcast">
              <h2 className="purple">시급 TOP</h2>
              {showLiveUpdate ? <span className="chip live">실시간 업데이트</span> : <></>}
            </div>
            {topHourly && !hiddenIDs.includes(topHourly.i) && (
              <BjProfile
                i={topHourly.i}
                n={topHourly.n}
                data={Math.round((topHourly.b * 60 * 60) / topHourly.t)}
                category={getBjCategory(topHourly.i)}
                postfix={'개'}
              />
            )}
            {topHourly && hiddenIDs.includes(topHourly.i) && (
              <HiddenProfile data={Math.round((topHourly.b * 60 * 60) / topHourly.t)} postfix={'개'} />
            )}
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  )
}
