import SearchResults from '@/components/pages/search/results'
import SearchMobile from '@/components/pages/search/Mobile'
import { useEffect, useState } from 'react'

export default function SearchResultsPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [pastSearches, setPastSearches] = useState([])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (windowWidth <= 1024) {
    return <SearchMobile pastSearches={pastSearches} setPastSearches={setPastSearches} />
  } else {
    return <SearchResults isMobile={false} />
  }
}
