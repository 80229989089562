import styles from './main.module.scss'
import { Link } from 'react-router-dom'
import useAfreecaStore from '@/stores/afreeca'
import useHiddenStore from '@/stores/hidden'
import { useQuery } from 'react-query'
import { getBjListDay } from '@/api/bjpt.ts'
import { useContext, useState } from 'react'
import { TOP_CATEGORY } from '../consts'
import { today } from '@/utils/date.ts'
import { generateRandomString } from '@/helpers/hidden'
import { ThemeContext } from '@/context/ThemeContext'

function ListProfileItem({ bj, index, value, isLive, hiddenBjs }: any) {
  return (
    <li className={styles.row} key={bj.i}>
      <div className={styles.num}>
        <span>{index + 1}</span>
      </div>
      {hiddenBjs.includes(bj.i) ? (
        <Link to={`https://www.afreecatv.com`} className={styles.txt}>
          <span>
            <img src={'/images/contents/ico_logo_afreecatv.svg'} alt="thumn" />
          </span>
          <span className={styles.nick}>{generateRandomString(10)}</span>
          <span className={`${styles.pf} ${styles.af}`}>
            <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
          </span>
        </Link>
      ) : (
        <Link to={`/broadcast/${bj.i}`} className={styles.txt}>
          <span>
            <img
              src={`https://profile.img.afreecatv.com/LOGO/${bj.i.substring(0, 2)}/${bj.i}/${bj.i}.jpg`}
              alt="아이콘"
            />
          </span>
          <span className={styles.nick}>{bj.n}</span>
          <span className={`${styles.pf} ${styles.af}`}>
            <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
          </span>
          {/* <span className={`${styles.pf} ${styles.ch}`}>
                    <img src="/images/contents/ico_logo_chzzk.svg" alt="chzzk" />
                  </span> */}
          {isLive ? <span className="chip red sm">Live</span> : <></>}
        </Link>
      )}
      <div className={styles.cnt}>{value.toLocaleString()}</div>
    </li>
  )
}

export default function TopRankingGroup() {
  const { theme } = useContext(ThemeContext)
  const currentDate = today()
  const [rankCategory, setRankCategory] = useState(TOP_CATEGORY.DONATION)
  const liveChannels = useAfreecaStore(state => state.liveChannels)
  const { hiddenBjs } = useHiddenStore()

  const {
    data: bjRanking,
    isLoading: bjRankingLoading,
    isError: bjRankingError,
  } = useQuery(['bjRanking'], () =>
    getBjListDay(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()),
  )

  if (bjRankingLoading || bjRankingError) {
    return
  }

  return (
    <div className={styles.mainRankingArea}>
      <div className={styles.moTitleArea}>
        <h2 className={styles.moMainTitle}>오늘의 랭킹</h2>
        <div className="tab-type-horizon">
          <ul>
            <li>
              <a
                className={rankCategory === TOP_CATEGORY.DONATION ? 'active' : ''}
                onClick={() => setRankCategory(TOP_CATEGORY.DONATION)}
              >
                별풍 랭킹
              </a>
            </li>
            <li>
              <a
                className={rankCategory === TOP_CATEGORY.VIEWER ? 'active' : ''}
                onClick={() => setRankCategory(TOP_CATEGORY.VIEWER)}
              >
                시청자수 랭킹
              </a>
            </li>
            <li>
              <a
                className={rankCategory === TOP_CATEGORY.HOURLY ? 'active' : ''}
                onClick={() => setRankCategory(TOP_CATEGORY.HOURLY)}
              >
                시급 랭킹
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* 별풍선 랭킹 >> mobile 에서는 클래스가 on 인 경우만 노출  */}
      <div
        className={rankCategory === TOP_CATEGORY.DONATION ? `${styles.contsBox} ${styles.on}` : `${styles.contsBox}`}
      >
        <div className={styles.rankHead}>
          <h2 className={styles.rankTitle}>오늘의 별풍 랭킹</h2>
          <div className={styles.row}>
            <span>순위</span>
            <span>별풍선</span>
          </div>
        </div>

        <div className={styles.rankBody}>
          <ul className={styles.body}>
            {bjRanking?.ranking
              .sort((bj1: any, bj2: any) => bj2.b - bj1.b)
              .slice(0, 5)
              .map((bj: any, index: number) => (
                <ListProfileItem
                  key={bj.i}
                  bj={bj}
                  index={index}
                  isLive={liveChannels.includes(bj.i)}
                  value={bj.b}
                  hiddenBjs={hiddenBjs}
                />
              ))}
          </ul>
        </div>

        <div className={styles.rankBtn}>
          <Link to="/rankings/broadcast" state={{ sortBy: TOP_CATEGORY.DONATION }}>
            전체 랭킹 보기
            <img src={`/images/contents/${theme}/ico_arrow_right_thick.svg`} alt="more" />
          </Link>
        </div>
      </div>

      {/* 시청자 랭킹 */}
      <div className={rankCategory === TOP_CATEGORY.VIEWER ? `${styles.contsBox} ${styles.on}` : `${styles.contsBox}`}>
        <div className={styles.rankHead}>
          <h2 className={styles.rankTitle}>오늘의 시청자수 랭킹</h2>
          <div className={styles.row}>
            <span>순위</span>
            <span>시청자</span>
          </div>
        </div>

        <div className={styles.rankBody}>
          <ul className={styles.body}>
            {bjRanking?.ranking
              .sort((bj1: any, bj2: any) => bj2.v - bj1.v)
              .slice(0, 5)
              .map((bj: any, index: number) => (
                <ListProfileItem
                  key={bj.i}
                  bj={bj}
                  index={index}
                  isLive={liveChannels.includes(bj.i)}
                  value={bj.v}
                  hiddenBjs={hiddenBjs}
                />
              ))}
          </ul>
        </div>

        <div className={styles.rankBtn}>
          <Link to="/rankings/broadcast" state={{ sortBy: TOP_CATEGORY.VIEWER }}>
            전체 랭킹 보기
            <img src={`/images/contents/${theme}/ico_arrow_right_thick.svg`} alt="more" />
          </Link>
        </div>
      </div>

      {/* 시급 랭킹 */}
      <div className={rankCategory === TOP_CATEGORY.HOURLY ? `${styles.contsBox} ${styles.on}` : `${styles.contsBox}`}>
        <div className={styles.rankHead}>
          <h2 className={styles.rankTitle}>오늘의 시급 랭킹</h2>
          <div className={styles.row}>
            <span>순위</span>
            <span>시급</span>
          </div>
        </div>

        <div className={styles.rankBody}>
          <ul className={styles.body}>
            {bjRanking?.ranking
              .sort((bj1: any, bj2: any) => {
                const h1 = bj1.t === 0 ? 0 : bj1.b / bj1.t
                const h2 = bj2.t === 0 ? 0 : bj2.b / bj2.t
                return h2 - h1
              })
              .slice(0, 5)
              .map((bj: any, index: number) => (
                <ListProfileItem
                  key={bj.i}
                  bj={bj}
                  index={index}
                  isLive={liveChannels.includes(bj.i)}
                  value={Math.round((bj.b * 60 * 60) / bj.t)}
                  hiddenBjs={hiddenBjs}
                />
              ))}
          </ul>
        </div>

        <div className={styles.rankBtn}>
          <Link to="/rankings/broadcast" state={{ sortBy: TOP_CATEGORY.HOURLY }}>
            전체 랭킹 보기
            <img src={`/images/contents/${theme}/ico_arrow_right_thick.svg`} alt="more" />
          </Link>
        </div>
      </div>

      <div className={styles.moMainMoreBtn}>
        <Link to="/rankings/broadcast" state={{ sortBy: rankCategory }}>
          전체 랭킹 보기
          <img src={`/images/contents/${theme}/ico_arrow_right_thick.svg`} alt="more" />
        </Link>
      </div>
    </div>
  )
}
