import styles from './platform.module.scss'
import useAfreecaStore from '@/stores/afreeca'
import { getAfreecaCount, get2DayBroadcastSummary } from '@/api/poongtoday'
import { useQuery } from 'react-query'
import { useState } from 'react'
import Statistics from '@components/pages/platform/Statistics.tsx'
import PlatformChart from '@components/pages/platform/PlatformChart.tsx'
import { today, yesterday } from '@/utils/date.ts'
import { DATE_TEMPLATE } from '../consts'

export default function Platform() {
  const [period, setPeriod] = useState<DATE_TEMPLATE>(DATE_TEMPLATE.TODAY)
  const categories = useAfreecaStore(state => state.categories)
  const dailyPoints = 24 * 10 // 10개의 6분 기간 24개 = 하루

  const {
    data: count,
    isLoading: countLoading,
    isError: countError,
  } = useQuery(['todaySummary'], () => get2DayBroadcastSummary(today(), yesterday()))

  const {
    data: afreeaCount,
    isLoading: afreeaCountLoading,
    isError: afreeaCountError,
  } = useQuery(['afreeaCount'], () => getAfreecaCount())

  if (countLoading || countError || afreeaCountLoading || afreeaCountError || !afreeaCount) {
    return
  }

  return (
    <div className="contents-area">
      <div className={styles.titleArea}>
        <h2>플랫폼 통계</h2>
        {/* <span>아프리카TV와 치지직을 다양한 기준으로 분석해 봤어요</span> */}
      </div>

      {/* 플랫폼 */}
      {/* <div className={styles.platformArea}>
        <div className={styles.contsBox}>
          <div className={styles.title}>
            <span className={`chip sm red ${styles.liveChip}`}>실시간 업데이트</span>
            <div className={styles.name}>
              <div className={styles.afreeca}>
                <span>
                  <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
                </span>
                아프리카TV
              </div>
            </div>
          </div>

          <div className={styles.inner}>
            <ul className={styles.list}>
              <li>
                <span>현재 별풍선</span>
                <span className={styles.number}>{afreeaCount.b.toLocaleString()}</span>
              </li>
              <li>
                <span>현재 시청자 수</span>
                <span className={styles.number}>{afreeaCount.v.toLocaleString()}</span>
              </li>
              <li>
                <span>현재 방송 수</span>
                <span className={styles.number}>{afreeaCount.c.toLocaleString()}</span>
              </li>
            </ul>
          </div>
          <div className={styles.chart}>차트 영역</div>
        </div>
        <div className={styles.contsBox}>
          <div className={styles.title}>
            <span className={`chip sm red ${styles.liveChip}`}>실시간 업데이트</span>
            <div className={styles.name}>
              <div className={styles.chzzk}>
                <span>
                  <img src="/images/contents/ico_logo_chzzk.svg" alt="chzzk" />
                </span>
                치지직
              </div>
            </div>
          </div>

          <div className={styles.inner}>
            <ul className={styles.list}>
              <li>
                <span>현재 별풍선</span>
                <span className={styles.number}>2,000,000</span>
              </li>
              <li>
                <span>현재 시청자 수</span>
                <span className={styles.number}>400,000</span>
              </li>
              <li>
                <span>현재 방송 수</span>
                <span className={styles.number}>2,000</span>
              </li>
            </ul>
          </div>

          <div className={styles.chart}>차트 영역</div>
        </div>
      </div> */}

      {/* 통계 차트 영역 */}
      <div className={styles.chartStatsArea}>
        {/* 통계 영역 */}
        <div className={styles.countArea}>
          <ul>
            <li>
              <span className={styles.titleB}>현재 별풍선</span>
              <span className={styles.cnt}>{count ? count.bList[count.bList.length - 1]?.toLocaleString() : 0}</span>
              <span className={styles.miniCnt}>
                오늘 누적 별풍선 <span className={styles.miniCntValue}>{afreeaCount.b.toLocaleString()}</span>
              </span>
            </li>
            <li>
              <span className={styles.titleV}>현재 시청자</span>
              <span className={styles.cnt}>{afreeaCount.v.toLocaleString()}</span>
              <span className={styles.miniCnt}>
                오늘 최고 시청자{' '}
                <span className={styles.miniCntValue}>
                  {Math.max(count?.vTodayMax!, afreeaCount.v).toLocaleString()}
                </span>
              </span>
            </li>
            <li>
              <span className={styles.titleC}>현재 방송수</span>
              <span className={styles.cnt}>{afreeaCount.c.toLocaleString()}</span>
              <span className={styles.miniCnt}>
                오늘 최고 방송수{' '}
                <span className={styles.miniCntValue}>
                  {Math.max(count?.cTodayMax!, afreeaCount.c).toLocaleString()}
                </span>
              </span>
            </li>
          </ul>
        </div>
        {/* 차트영역 */}
        <PlatformChart data={count} dailyPoints={dailyPoints} />
      </div>

      {/* 기간 선택 */}
      <div className="tab-select-area scroll">
        <div className="tab-type-round">
          <div>기간 선택</div>
          <ul>
            <li>
              <a
                onClick={() => setPeriod(DATE_TEMPLATE.TODAY)}
                className={period === DATE_TEMPLATE.TODAY ? 'active' : ''}
              >
                오늘
              </a>
            </li>
            <li>
              <a
                onClick={() => setPeriod(DATE_TEMPLATE.YESTERDAY)}
                className={period === DATE_TEMPLATE.YESTERDAY ? 'active' : ''}
              >
                어제
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* 누적 통계 */}
      <Statistics data={count} today={period === DATE_TEMPLATE.TODAY} now={afreeaCount} dailyPoints={dailyPoints} />

      {/* 카테고리 랭킹 */}
      <div className={styles.categoryRanking}>
        <div className="box-title">
          <h2>카테고리 랭킹</h2>
          <span className="chip live">실시간 업데이트</span>
        </div>
        <div className={styles.rankList}>
          {afreeaCount.l
            .sort((cate1: any, cate2: any) => cate2.b - cate1.b)
            .map((category: any, index: number) => (
              <div className={styles.contsBox} key={index}>
                <span className={styles.rank}>{index + 1}</span>
                <p className={styles.category}>
                  {categories[String(category.m * 10000 + category.s).padStart(8, '0')]}
                </p>

                {/* 별풍선 */}
                <div className={`${styles.box} ${styles.blue}`}>
                  <span className={styles.txt}>별풍선</span>
                  <span className={styles.cnt}>{category.b.toLocaleString()}</span>
                  <div className={styles.barGraph}>
                    <span className={styles.bar}>
                      <span
                        className={styles.graph}
                        style={{
                          width: (category.b / afreeaCount.b).toLocaleString(undefined, {
                            style: 'percent',
                            minimumFractionDigits: 2,
                          }),
                        }}
                      >
                        바그래프
                      </span>
                    </span>
                    <span className={styles.percent}>
                      {(category.b / afreeaCount.b).toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                </div>

                {/* 시청자 */}
                <div className={`${styles.box} ${styles.green}`}>
                  <span className={styles.txt}>시청자</span>
                  <span className={styles.cnt}>{category.v.toLocaleString()}</span>
                  <div className={styles.barGraph}>
                    <span className={styles.bar}>
                      <span
                        className={styles.graph}
                        style={{
                          width: (category.v / afreeaCount.v).toLocaleString(undefined, {
                            style: 'percent',
                            minimumFractionDigits: 2,
                          }),
                        }}
                      >
                        바그래프
                      </span>
                    </span>
                    <span className={styles.percent}>
                      {(category.v / afreeaCount.v).toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                </div>

                {/* 방송수 */}
                <div className={`${styles.box} ${styles.purple}`}>
                  <span className={styles.txt}>방송수</span>
                  <span className={styles.cnt}>{category.c.toLocaleString()}</span>
                  <div className={styles.barGraph}>
                    <span className={styles.bar}>
                      <span
                        className={styles.graph}
                        style={{
                          width: (category.c / afreeaCount.c).toLocaleString(undefined, {
                            style: 'percent',
                            minimumFractionDigits: 2,
                          }),
                        }}
                      >
                        바그래프
                      </span>
                    </span>
                    <span className={styles.percent}>
                      {(category.c / afreeaCount.c).toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
