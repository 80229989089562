import styles from '@components/pages/broadcast/BroadcastView/broadcastView.module.scss'
import DonorListItem from '@/components/elements/lists/DonorListItem'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { getMonthlyBjBroadStats } from '@/api/poongtoday.ts'
import { PERIOD_TYPE } from '../../consts'
import { today, startYear } from '@/utils/date.ts'
import MonthSelector from '@/components/elements/selectors/MonthSelector'

export default function BroadcastDonorRanking({ id, show, hiddenIDs }: { id: string; show: boolean; hiddenIDs: any }) {
  const [fanDate, setFanDate] = useState(today())
  const [selectedMonth, setSelectedMonth] = useState<{ key: string; value: string }[]>([
    { key: String(fanDate.getMonth()), value: String(fanDate.getMonth() + 1).padStart(2, '0') },
  ])
  const [selectedYear, setSelectedYear] = useState<{ key: string; value: string }[]>([
    { key: String(fanDate.getFullYear()), value: String(fanDate.getFullYear()) },
  ])

  const { data: monthlyBjBroadStats } = useQuery(['monthlyBjStats', fanDate], () =>
    getMonthlyBjBroadStats(id, fanDate.getFullYear(), fanDate.getMonth() + 1),
  )

  function fanDateButtonHandler(periodToAdd: PERIOD_TYPE, change: number) {
    const newDate = new Date(fanDate)
    if (periodToAdd === PERIOD_TYPE.MONTH) {
      newDate.setMonth(change)
    } else if (periodToAdd === PERIOD_TYPE.YEAR) {
      newDate.setFullYear(change)
    }
    if (startYear <= newDate.getFullYear() && newDate <= today()) {
      setFanDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
    } else if (newDate.getFullYear() === today().getFullYear() && newDate > today()) {
      newDate.setMonth(today().getMonth())
      setFanDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
    }
  }

  return (
    <div className={show ? `${styles.ranking} ${styles.on}` : `${styles.ranking}`}>
      <div className={styles.viewTitle}>팬 랭킹</div>
      <MonthSelector
        date={fanDate}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        dateButtonHandler={fanDateButtonHandler}
        smaller={false}
      />

      {/* 랭킹 게시판 */}
      <div className={styles.bbsWrap}>
        <div className="bbs-area">
          <div className="pt-bbs smaller">
            <div className={`bbs-head ${styles.bbsHead}`}>
              <span className="num">순위</span>
              <span></span>
              <span>선물 횟수</span>
              <span>별풍선</span>
            </div>
            {monthlyBjBroadStats?.f ? (
              <div className={`bbs-body`}>
                <ul className={`body ${styles.bbsBody}`}>
                  {/* list item */}
                  {monthlyBjBroadStats.f.map((fan: any, index: number) => (
                    <li className="row" key={fan.i}>
                      <div className="rank af">{index + 1}</div>
                      {/* <div className="rank ch">1</div> */}

                      <DonorListItem donorData={hiddenIDs.includes(fan.i) ? null : fan} />

                      <div className="col">{fan.c.toLocaleString()}</div>
                      <div className="col">
                        {fan.b.toLocaleString()}
                        <div className={styles.barGraph}>
                          <span className={styles.bar}>
                            <span
                              className={styles.graph}
                              style={{
                                width: (fan.b / monthlyBjBroadStats.b).toLocaleString(undefined, {
                                  style: 'percent',
                                  minimumFractionDigits: 2,
                                }),
                              }}
                            >
                              바그래프
                            </span>
                          </span>
                          <span className={styles.percent}>
                            {(fan.b / monthlyBjBroadStats.b).toLocaleString(undefined, {
                              style: 'percent',
                              minimumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
