import styles from './changeNickname.module.scss'
import { useContext, useState } from 'react'
import { ModalPopup } from '../../popups/ModalPortal'
import { AlertModal } from '../../popups/Alert'
import { checkNickDuplicate } from '@/api/user'
import { updateUserNickname } from '@/api/user'
import useUserStore from '@/stores/user'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from '@/context/ThemeContext'

export default function ChangeNickname() {
  const { theme } = useContext(ThemeContext)
  const navigate = useNavigate()
  const { user, fetch: fetchUser } = useUserStore()
  const [showModal, setShowModal] = useState(false)
  const [newNick, setNewNick] = useState('')
  const [duplicate, setDuplicate] = useState(true)

  function handleDuplicateCheck() {
    checkNickDuplicate({ nick: newNick.trim() }).then(data => {
      if (data.duplicate) {
        setShowModal(true)
      } else {
        setDuplicate(false)
      }
    })
  }

  async function handleUpdateNick() {
    if (newNick.trim().length >= 2 && newNick.trim().length <= 7 && !duplicate) {
      try {
        const result = await updateUserNickname(newNick.trim())
        if (result === 'ok') {
          await fetchUser()
          alert('변경되었습니다.')
          navigate('/my/mypage')
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      alert('변겅할수 없는 닉네임입니다 중복확인이나 2~7자 사이로 입력해 주세요')
    }
  }

  return (
    <div className="contents-area">
      <div className={styles.changeArea}>
        {user ? <h2>닉네임 변경하기</h2> : <h2>로그인 해주세요</h2>}
        {user ? (
          <div className={styles.box}>
            <ul>
              <li>
                <label htmlFor="changeNick">새 닉네임</label>
                <div className={styles.col}>
                  <input
                    type="text"
                    id="changeNick"
                    name="changeNick"
                    value={newNick}
                    onChange={e => {
                      setNewNick(e.target.value)
                      setDuplicate(true)
                    }}
                  />
                  {duplicate ? (
                    <button type="button" className={styles.btnDuplication} onClick={handleDuplicateCheck}>
                      중복확인
                    </button>
                  ) : (
                    <div className={styles.checkMark}>
                      <img src={`/images/contents/${theme}/check_thick.svg`} alt="체크" />
                    </div>
                  )}
                </div>
                <div className={styles.txt}>2~7자 사이로 입력해 주세요</div>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}

        {user ? (
          <div className={styles.btnArea}>
            <button type="button" className={styles.btnSubmit} onClick={handleUpdateNick}>
              변경하기
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>

      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="중복확인" onClose={setShowModal}>
            <p>
              사용중인 닉네임입니다.
              <br />
              다시 입력하여 주십시오.
            </p>
            {/* <p>사용가능한 닉네임입니다.</p> */}
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
