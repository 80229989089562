import styles from './bigDonor.module.scss'
import { useEffect, useState } from 'react'
import DonorListItem from '@/components/elements/lists/DonorListItem'
import ColumnTabItem from '@/components/elements/tabs/ColumnTabItem'
import { DATA_COLUMN, dropDownSize, PERIOD_TYPE } from '../consts'
import { generateRandomString } from '@/helpers/hidden'

export default function BigDonorTable({
  bigDonorList,
  categories,
  liveChannels,
  period,
  search,
  sortBy,
  setSortBy,
  selectedCategories,
  hiddenDonors,
  hiddenBjs,
}: any) {
  const [showAmount, setShowAmount] = useState(50)
  let donorList = []

  function getMostDonatedBjCategory(donor: any): string {
    return categories[String(donor.t.am * 10000 + donor.t.as).padStart(8, '0')]
  }

  function handleChangeColumn(column: DATA_COLUMN) {
    setSortBy(column)
  }

  useEffect(() => {
    setShowAmount(50)
  }, [period, search, selectedCategories])

  const selectedCates = selectedCategories.map((c: { key: string; value: string }) => c.value)
  if (sortBy === DATA_COLUMN.DONATION) {
    donorList = bigDonorList.sort((donor1: any, donor2: any) => donor2.b - donor1.b) ?? []
  } else if (sortBy === DATA_COLUMN.DONATION_COUNT) {
    donorList = bigDonorList.sort((donor1: any, donor2: any) => donor2.c - donor1.c) ?? []
  } else if (sortBy === DATA_COLUMN.STREAMER_COUNT) {
    donorList = bigDonorList.sort((donor1: any, donor2: any) => donor2.s - donor1.s) ?? []
  }

  donorList = donorList.filter((donor: any) => {
    if (selectedCates.length === 0) {
      return donor.n.includes(search)
    } else {
      return selectedCates.includes(getMostDonatedBjCategory(donor)) && donor.n.includes(search)
    }
  })

  return (
    <div className={styles.listArea}>
      <div className={styles.leftArea}>
        {/* 타이틀 */}
        {/* <div className={styles.title}>
            {period === 'T' ? <span className={`chip sm red ${styles.titleChip}`}>실시간 업데이트</span> : <></>}
            <div className={styles.name}>
              <div className={styles.afreeca}>
                <span>
                  <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
                </span>
                아프리카TV
              </div>
            </div>
          </div> */}

        {/* 리스트 */}
        <div className={styles.bbsWrap}>
          <div className="bbs-area">
            <div className={`pt-bbs smaller`}>
              <div className={`bbs-head ${styles.bbsHead}`}>
                <span className="num">순위</span>
                <span>닉네임</span>
                <ColumnTabItem
                  text={'총 선물 개수 •'}
                  selectedColumn={sortBy}
                  value={DATA_COLUMN.DONATION}
                  onChange={handleChangeColumn}
                />
                <ColumnTabItem
                  text={'선물 횟수 •'}
                  selectedColumn={sortBy}
                  value={DATA_COLUMN.DONATION_COUNT}
                  onChange={handleChangeColumn}
                />
                <ColumnTabItem
                  text={'선물한 방송 수 •'}
                  selectedColumn={sortBy}
                  value={DATA_COLUMN.STREAMER_COUNT}
                  onChange={handleChangeColumn}
                />
                {period === PERIOD_TYPE.DAY ? <></> : <span>최다 선물 BJ</span>}
              </div>
              <div className={`bbs-body`}>
                <ul className={`body ${styles.bbsBody}`}>
                  {/* list item */}
                  {donorList.slice(0, showAmount).map((donor: any, index: number) => (
                    <li className="row" key={donor.i}>
                      <div className="rank af">{index + 1}</div>
                      {/* <div className="rank ch">1</div> */}
                      <DonorListItem donorData={hiddenDonors.includes(donor.i) ? null : donor} />

                      <div className={sortBy === DATA_COLUMN.DONATION ? 'col c-white' : 'col'}>
                        {donor.b.toLocaleString()}
                      </div>
                      <div className={sortBy === DATA_COLUMN.DONATION_COUNT ? 'col c-white' : 'col'}>
                        {donor.c?.toLocaleString() ?? '-'}
                      </div>
                      <div className={sortBy === DATA_COLUMN.STREAMER_COUNT ? 'col c-white' : 'col'}>
                        {donor.s?.toLocaleString() ?? '-'}
                      </div>
                      {period !== PERIOD_TYPE.DAY && hiddenBjs.includes(donor.t.i) && (
                        <div className="subject">
                          <div className="post">
                            <span className="nickname">
                              <span className={`thumnail ${styles.bbsBodythum}`}>
                                <img src={'/images/contents/ico_logo_afreecatv.svg'} alt="thumn" />
                              </span>
                              <span className="nick">{generateRandomString(10)}</span>
                            </span>
                          </div>
                        </div>
                      )}
                      {period !== PERIOD_TYPE.DAY && !hiddenBjs.includes(donor.t.i) && (
                        <div className="subject">
                          <div className="post">
                            <span className="nickname">
                              <span className={`thumnail ${styles.bbsBodythum}`}>
                                <img
                                  src={`https://profile.img.afreecatv.com/LOGO/${donor.t.i.substring(0, 2)}/${donor.t.i}/${donor.t.i}.jpg`}
                                  alt="thumn"
                                />
                              </span>
                              <span className="nick">
                                {donor.t.n}
                                {liveChannels.includes(donor.t.i) ? (
                                  <span className={`chip sm red ${styles.liveChip}`}>LIVE</span>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAmount <= donorList.length && (
        <div className="bbs-btn-more">
          <button onClick={() => setShowAmount(showAmount + 50)} type="button">
            더보기 &#40;+50위&#41;
          </button>
        </div>
      )}
      {donorList.length <= dropDownSize && <div className="more-space"></div>}
    </div>
  )
}
