import { useContext } from 'react'
import styles from '../confirm.module.scss'
import { ThemeContext } from '@/context/ThemeContext'

export default function BuySuccess() {
  const { theme } = useContext(ThemeContext)
  return (
    <div className={styles.confirmArea}>
      <img className={styles.greenCheckmark} src={`/images/contents/${theme}/check_thick.svg`} alt="체크" />
      <div className={`${styles.title} ${styles.lower}`}>쿠폰 구매 완료</div>
      <ul className={styles.content}>
        <li>
          <div className={styles.subtitle}>쿠폰 구매가 완료되었습니다.</div>
          <div className={styles.subject}>
            구매하신 쿠폰은 커뮤니티 {'>'} 포인트 내역 {'>'} 쿠폰 보관함 에서 확인하실 수 있습니다.
          </div>
        </li>
      </ul>
    </div>
  )
}
