import v2 from '../v2'

export async function createLike(params: { boardID: number }) {
  return v2.post('/api/community/likes', params, { withCredentials: true }).then(resp => resp.data)
}

export async function deleteLike(boardID: number) {
  return v2
    .delete('/api/community/likes', {
      data: {
        boardID: boardID,
      },
      withCredentials: true,
    })
    .then(resp => resp.data)
}
