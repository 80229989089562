import { startYear, today } from '@/utils/date'
import DatePicker from 'react-datepicker'
import { PERIOD_TYPE } from '../../pages/consts'
import { ThemeContext } from '@/context/ThemeContext'
import { useContext } from 'react'

export default function DateSelector({
  date,
  setDate,
  setSearch,
  dateButtonHandler,
}: {
  date: Date
  setDate: any
  setSearch: any
  dateButtonHandler: any
}) {
  const { theme } = useContext(ThemeContext)
  return (
    <div className="datepicker-area">
      <div className={`datepicker`}>
        <span className="left-arrow" onClick={() => dateButtonHandler(PERIOD_TYPE.DAY, date.getDate() - 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_left_sm.svg`} alt="prev" />
        </span>
        <div className={`form-select`}>
          <DatePicker
            selected={date}
            onChange={(date: Date | null) => {
              if (date) {
                setDate(date)
              } else {
                setDate(today())
              }
              setSearch('')
            }}
            dateFormat="yyyy. MM. dd"
            minDate={new Date(startYear, 0, 1)}
            maxDate={new Date(today())}
            dateFormatCalendar="yyyy. MM"
          />
        </div>
        <span className="right-arrow" onClick={() => dateButtonHandler(PERIOD_TYPE.DAY, date.getDate() + 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_right_sm.svg`} alt="next" />
        </span>
      </div>
    </div>
  )
}
