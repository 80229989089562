import { PERIOD_TYPE } from '../../pages/consts'

export default function PeriodTabItem({
  value = PERIOD_TYPE.DAY,
  selectedPeriod = PERIOD_TYPE.DAY,
  text = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_v: PERIOD_TYPE) => {},
}) {
  return (
    <li>
      <a
        onClick={_ => {
          onChange(value)
        }}
        className={value === selectedPeriod ? 'active' : ''}
      >
        {text}
      </a>
    </li>
  )
}
