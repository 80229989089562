import styles from '../history.module.scss'
import BroadcastListItem from '@/components/elements/lists/BroadcastListItem'
import { useEffect, useState } from 'react'
import { DATA_COLUMN, dropDownSize, PERIOD_TYPE } from '../../consts'
import ColumnTabItem from '@/components/elements/tabs/ColumnTabItem'

function BroadcastItem({ bj, index, period, liveChannels, sortBy, mainCategory, hiddenIDs }: any) {
  return (
    <li className="row" key={bj.i}>
      <div className="rank af">{index + 1}</div>
      {/* <div className="rank ch">1</div> */}
      <BroadcastListItem
        bjData={
          hiddenIDs.includes(bj.i)
            ? null
            : {
                profile_image: `https://profile.img.afreecatv.com/LOGO/${bj.i.substring(0, 2)}/${bj.i}/${bj.i}.jpg`,
                i: bj.i,
                n: bj.n,
                //is_afreeca: true,
                live: liveChannels.includes(bj.i),
                mainCategory: mainCategory,
              }
        }
      />
      <div className={sortBy === DATA_COLUMN.DONATION ? 'col c-white' : 'col'}>
        {period === PERIOD_TYPE.YEAR ? bj.B.toLocaleString() : bj.b.toLocaleString()}
      </div>
      {period !== PERIOD_TYPE.YEAR && (
        <div className={sortBy === DATA_COLUMN.HOURLY ? 'col c-white' : 'col'}>
          {bj.t === 0 ? 0 : Math.round((bj.b * 60 * 60) / bj.t).toLocaleString()}
        </div>
      )}
      {period === PERIOD_TYPE.YEAR && (
        <div className={sortBy === DATA_COLUMN.HOURLY ? 'col c-white' : 'col'}>
          {bj.T === 0 ? 0 : Math.round((bj.B * 60 * 60) / bj.T).toLocaleString()}
        </div>
      )}
      {period === PERIOD_TYPE.DAY && (
        <div className={sortBy === DATA_COLUMN.CUMULATED_VIEWER ? 'col c-white' : 'col'}>{bj.v.toLocaleString()}</div>
      )}
      {period === PERIOD_TYPE.MONTH && (
        <div className={sortBy === DATA_COLUMN.CONCURRENT_VIEWER ? 'col c-white' : 'col'}>{bj.m.toLocaleString()}</div>
      )}
      {period === PERIOD_TYPE.YEAR && (
        <div className={sortBy === DATA_COLUMN.CONCURRENT_VIEWER ? 'col c-white' : 'col'}>{bj.M.toLocaleString()}</div>
      )}
      {period === PERIOD_TYPE.MONTH && (
        <div className={sortBy === DATA_COLUMN.CUMULATED_VIEWER ? 'col c-white' : 'col'}>{bj.v.toLocaleString()}</div>
      )}
      {period === PERIOD_TYPE.YEAR && (
        <div className={sortBy === DATA_COLUMN.CUMULATED_VIEWER ? 'col c-white' : 'col'}>{bj.V.toLocaleString()}</div>
      )}
      {period === PERIOD_TYPE.DAY && (
        <div className={sortBy === DATA_COLUMN.DONATION_COUNT ? 'col c-white' : 'col'}>{bj.c.toLocaleString()}</div>
      )}
      {period === PERIOD_TYPE.MONTH && (
        <div className={sortBy === DATA_COLUMN.BROADCAST_TIME ? 'col c-white' : 'col'}>
          {Math.floor(bj.t / 86400)}일 {Math.floor((bj.t % 86400) / 3600)} 시간
        </div>
      )}
      {period === PERIOD_TYPE.YEAR && (
        <div className={sortBy === DATA_COLUMN.BROADCAST_TIME ? 'col c-white' : 'col'}>
          {Math.floor(bj.T / 86400)}일 {Math.floor((bj.T % 86400) / 3600)} 시간
        </div>
      )}
    </li>
  )
}

export default function HistoryContent({
  bjRanking,
  liveChannels,
  categories,
  bjList,
  period,
  search,
  listDate,
  sortBy,
  setSortBy,
  selectedCategories,
  hiddenIDs,
}: any) {
  const [showAmount, setShowAmount] = useState(50)
  let rankingItems: any[] = []

  function getBjCategory(bjObj: any): string {
    if (period === PERIOD_TYPE.DAY) {
      const foundBj = bjList.find((bj: any) => bj.i === bjObj.i)
      if (foundBj && foundBj.c && foundBj.c.length > 0) {
        return categories[String(foundBj.c[0] * 10000 + foundBj.c[1]).padStart(8, '0')]
      } else {
        return ''
      }
    } else if (period === PERIOD_TYPE.MONTH) {
      return categories[String(bjObj.am * 10000 + bjObj.as).padStart(8, '0')]
    } else {
      return categories[String(bjObj.C * 10000 + bjObj.S).padStart(8, '0')]
    }
  }

  function handleChangeColumn(column: DATA_COLUMN) {
    setSortBy(column)
  }

  useEffect(() => {
    setShowAmount(50)
  }, [period, listDate, selectedCategories])

  const selectedCates = selectedCategories.map((c: { key: string; value: string }) => c.value)
  if (sortBy === DATA_COLUMN.DONATION) {
    rankingItems =
      period === PERIOD_TYPE.YEAR
        ? bjRanking.sort((bj1: any, bj2: any) => bj2.B - bj1.B) ?? []
        : bjRanking.sort((bj1: any, bj2: any) => bj2.b - bj1.b) ?? []
  } else if (sortBy === DATA_COLUMN.CUMULATED_VIEWER) {
    rankingItems =
      period === PERIOD_TYPE.YEAR
        ? bjRanking.sort((bj1: any, bj2: any) => bj2.V - bj1.V) ?? []
        : bjRanking.sort((bj1: any, bj2: any) => bj2.v - bj1.v) ?? []
  } else if (sortBy === DATA_COLUMN.CONCURRENT_VIEWER) {
    rankingItems =
      period === PERIOD_TYPE.YEAR
        ? bjRanking.sort((bj1: any, bj2: any) => bj2.M - bj1.M) ?? []
        : bjRanking.sort((bj1: any, bj2: any) => bj2.m - bj1.m) ?? []
  } else if (sortBy === DATA_COLUMN.BROADCAST_TIME) {
    rankingItems =
      period === PERIOD_TYPE.YEAR
        ? bjRanking.sort((bj1: any, bj2: any) => bj2.T - bj1.T) ?? []
        : bjRanking.sort((bj1: any, bj2: any) => bj2.t - bj1.t) ?? []
  } else if (sortBy === DATA_COLUMN.HOURLY) {
    rankingItems =
      period === PERIOD_TYPE.YEAR
        ? bjRanking.sort((bj1: any, bj2: any) => {
            const h1 = bj1.T === 0 ? 0 : bj1.B / bj1.T
            const h2 = bj2.T === 0 ? 0 : bj2.B / bj2.T
            return h2 - h1
          }) ?? []
        : bjRanking.sort((bj1: any, bj2: any) => {
            const h1 = bj1.t === 0 ? 0 : bj1.b / bj1.t
            const h2 = bj2.t === 0 ? 0 : bj2.b / bj2.t
            return h2 - h1
          }) ?? []
  } else if (sortBy === DATA_COLUMN.DONATION_COUNT) {
    rankingItems = bjRanking.sort((bj1: any, bj2: any) => bj2.c - bj1.c) ?? []
  }
  rankingItems = rankingItems.filter((bj: any) => {
    if (selectedCates.length === 0) {
      return bj.n.includes(search)
    } else {
      return selectedCates.includes(getBjCategory(bj)) && bj.n.includes(search)
    }
  })

  return (
    <div className={period === PERIOD_TYPE.DAY ? styles.bbsWrapSmall : styles.bbsWrap}>
      <div className="bbs-area">
        <div className="pt-bbs">
          <div className={`bbs-head ${styles.bbsHead}`}>
            <span className="num">순위</span>
            <span></span>
            <ColumnTabItem
              text={'별풍선 총합 •'}
              selectedColumn={sortBy}
              value={DATA_COLUMN.DONATION}
              onChange={handleChangeColumn}
            />
            <ColumnTabItem
              text={'시급(풍) •'}
              selectedColumn={sortBy}
              value={DATA_COLUMN.HOURLY}
              onChange={handleChangeColumn}
            />
            {period === PERIOD_TYPE.DAY ? (
              <ColumnTabItem
                text={'시청자 •'}
                selectedColumn={sortBy}
                value={DATA_COLUMN.CUMULATED_VIEWER}
                onChange={handleChangeColumn}
              />
            ) : (
              <ColumnTabItem
                text={'시청자 •'}
                selectedColumn={sortBy}
                value={DATA_COLUMN.CONCURRENT_VIEWER}
                onChange={handleChangeColumn}
              />
            )}
            {period === PERIOD_TYPE.DAY ? (
              <></>
            ) : (
              <ColumnTabItem
                text={'누적 시청자 •'}
                selectedColumn={sortBy}
                value={DATA_COLUMN.CUMULATED_VIEWER}
                onChange={handleChangeColumn}
              />
            )}
            {period === PERIOD_TYPE.DAY ? (
              <ColumnTabItem
                text={'선물 횟수 •'}
                selectedColumn={sortBy}
                value={DATA_COLUMN.DONATION_COUNT}
                onChange={handleChangeColumn}
              />
            ) : (
              <ColumnTabItem
                text={'방송 시간 •'}
                selectedColumn={sortBy}
                value={DATA_COLUMN.BROADCAST_TIME}
                onChange={handleChangeColumn}
              />
            )}
          </div>
          <div className="bbs-body">
            <ul className={`body ${styles.bbsBody}`}>
              {/* list item */}
              {rankingItems.slice(0, showAmount).map((bj: any, index: number) => (
                <BroadcastItem
                  key={bj.i}
                  {...{
                    bj,
                    index,
                    period,
                    liveChannels,
                    sortBy,
                    mainCategory: getBjCategory(bj),
                    hiddenIDs,
                  }}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>

      {showAmount <= rankingItems.length && (
        <div className="bbs-btn-more">
          <button onClick={() => setShowAmount(showAmount + 50)} type="button">
            더보기 &#40;+50위&#41;
          </button>
        </div>
      )}
      {rankingItems.length <= dropDownSize && <div className="more-space"></div>}
    </div>
  )
}
