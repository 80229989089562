export type Theme = 'light' | 'dark'

export function calculateSettingAsThemeString(
  localStorageTheme: string | null,
  systemSettingDark: MediaQueryList,
): Theme {
  if (localStorageTheme !== null) {
    return localStorageTheme as Theme
  }
  if (systemSettingDark.matches) {
    return 'dark'
  }
  return 'light'
}
