import useAfreecaStore from '@/stores/afreeca.ts'
import useHiddenStore from '@/stores/hidden'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { getBjListDay, getBjListMonth } from '@/api/bjpt.ts'
import { lastMonth, today, yesterday } from '@/utils/date.ts'
import TopBj from '@components/elements/TopBj.tsx'
import BroadcastTable from './BroadcastTable.tsx'
import { DATA_COLUMN, DATE_TEMPLATE, PERIOD_TYPE } from '../../consts.ts'
import DateTabItem from '@/components/elements/tabs/DateTabItem.tsx'
import { useLocation } from 'react-router-dom'
import CategorySelector from '@/components/elements/selectors/CategorySelector.tsx'
import BroadcastHeader from '../BroadcastHeader.tsx'

export default function BroadcastRanking() {
  const location = useLocation()
  const { hiddenBjs } = useHiddenStore()
  const { categories, liveChannels, bjList } = useAfreecaStore()
  const [period, setPeriod] = useState<PERIOD_TYPE>(location.state ? PERIOD_TYPE.DAY : PERIOD_TYPE.MONTH)
  const [date, setDate] = useState<DATE_TEMPLATE>(location.state ? DATE_TEMPLATE.TODAY : DATE_TEMPLATE.THIS_MONTH)
  const [listDate, setListDate] = useState(today())
  const [sortBy, setSortBy] = useState<DATA_COLUMN>(
    location.state ? (location.state.sortBy as DATA_COLUMN) : DATA_COLUMN.DONATION,
  )
  const [search, setSearch] = useState('')
  const [selectedCategories, setSelectedCategories] = useState<{ key: string; value: string }[]>([])

  const {
    data: bjRanking,
    isLoading: bjRankingLoading,
    isError: bjRankingError,
  } = useQuery(['bjRanking', period, date], () => {
    if (period === PERIOD_TYPE.DAY) {
      return getBjListDay(listDate.getFullYear(), listDate.getMonth() + 1, listDate.getDate())
    } else {
      return getBjListMonth(listDate.getFullYear(), listDate.getMonth() + 1)
    }
  })

  if (bjRankingLoading || bjRankingError) {
    return
  }
  const isLoading = bjRankingLoading

  function handleChangeDate(date: DATE_TEMPLATE) {
    if ([DATE_TEMPLATE.TODAY, DATE_TEMPLATE.YESTERDAY].includes(date)) {
      setPeriod(PERIOD_TYPE.DAY)
    } else {
      setPeriod(PERIOD_TYPE.MONTH)
    }
    setDate(date)

    if ([DATE_TEMPLATE.TODAY, DATE_TEMPLATE.THIS_MONTH].includes(date)) {
      setListDate(today())
    } else if (date === DATE_TEMPLATE.YESTERDAY) {
      setListDate(yesterday())
    } else if (date === DATE_TEMPLATE.LAST_MONTH) {
      setListDate(lastMonth())
    }

    setSortBy(DATA_COLUMN.DONATION)
    setSearch('')
  }

  return (
    <div className="contents-area">
      <BroadcastHeader />
      <div className="tab-select-area scroll">
        <div className="tab-type-round">
          <div>기간 선택</div>
          <ul>
            <DateTabItem text={'오늘'} selectedDate={date} value={DATE_TEMPLATE.TODAY} onChange={handleChangeDate} />
            <DateTabItem
              text={'어제'}
              selectedDate={date}
              value={DATE_TEMPLATE.YESTERDAY}
              onChange={handleChangeDate}
            />
            <DateTabItem
              text={'이번 달'}
              selectedDate={date}
              value={DATE_TEMPLATE.THIS_MONTH}
              onChange={handleChangeDate}
            />
            <DateTabItem
              text={'지난 달'}
              selectedDate={date}
              value={DATE_TEMPLATE.LAST_MONTH}
              onChange={handleChangeDate}
            />
          </ul>
        </div>
      </div>

      <TopBj
        topDonated={bjRanking?.topDonated}
        topViewer={bjRanking?.topViewer}
        topHourly={bjRanking?.topHourly}
        showLiveUpdate={date === DATE_TEMPLATE.TODAY}
        categories={categories}
        bjList={bjList}
        hiddenIDs={hiddenBjs}
      />

      {/* 플랫폼 */}
      {/* <BroadcastPlatform /> */}

      {/* 정렬 선택 */}
      <div className="tab-select-area bg">
        <div className="select-tab">
          <h2>카테고리 선택</h2>
          <CategorySelector
            categories={categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>

        <div className="search-box">
          <div className="search-area">
            <input
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
              type="search"
              id="broadcastSearch"
              name="broadcastSearch"
              placeholder="방송검색"
            />
            <span className="ico-search"></span>
          </div>
        </div>
      </div>

      {/* 랭킹 게시판 */}
      {!isLoading && bjRanking ? (
        <BroadcastTable
          {...{
            bjRanking: bjRanking.ranking,
            liveChannels,
            categories,
            bjList,
            period,
            search,
            sortBy,
            setSortBy,
            selectedCategories,
            hiddenIDs: hiddenBjs,
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
