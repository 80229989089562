import { useState } from 'react'
import { addComment, updateComment } from '@/api/community/comment.ts'
import { checkAuthor, getHowLongAgo } from '@/helpers/community/BoardFunctions.ts'
import { whitespaceOnly } from '@/helpers/community/BoardFunctions.ts'
import commStyles from '../commBoard.module.scss'
import styles from '../board.module.scss'
import { ModalPopup } from '@/components/popups/ModalPortal'
import { ConfirmModal } from '@/components/popups/Confirm'
import DeleteConfirm from '@/components/popups/DeleteConfirm'

export default function Comments(data: any) {
  const [showReply, setShowReply] = useState(-1)
  const [showEdit, setShowEdit] = useState(-1)
  const [comment, setComment] = useState('')
  const [replyInput, setReplyInput] = useState('')
  const [toDeleteCommentID, setToDeleteCommentID] = useState(-1)

  function handleUploadComment(parentID: number) {
    if (data.userID === 0) {
      alert('로그인 해주세요')
    } else {
      if (parentID == 0) {
        // posting a new comment
        if (whitespaceOnly(comment, false)) {
          alert('댓글을 입력해주세요')
        } else {
          addComment({
            boardID: data.boardData.id,
            parentID: null,
            content: comment,
          }).catch(error => console.log(error))
          setComment('')
          data.refresh()
          if (data.fetch) {
            data.fetch()
          }
        }
      } else {
        // posting a new reply
        if (whitespaceOnly(replyInput, false)) {
          alert('댓글을 입력해주세요')
        } else {
          addComment({
            boardID: data.boardData.id,
            parentID: parentID,
            content: replyInput,
          }).catch(error => console.log(error))
          setShowReply(-1)
          setReplyInput('')
          data.refresh()
          if (data.fetch) {
            data.fetch()
          }
        }
      }
    }
  }

  function handleEditComment(commentID: number, orginalContent: string) {
    if (whitespaceOnly(replyInput, false)) {
      alert('댓글을 입력해주세요')
    } else {
      if (orginalContent !== replyInput) {
        updateComment(String(commentID), {
          boardID: data.boardData.id,
          content: replyInput,
          edited: true,
          isDeleted: false,
        })
      }
      setShowEdit(-1)
      setReplyInput('')
      data.refresh()
    }
  }

  function handleDeleteComment(commentID: number) {
    updateComment(String(commentID), { boardID: data.boardData.id, content: '', edited: true, isDeleted: true }).catch(
      error => console.log(error),
    )
    data.refresh()
    if (data.fetch) {
      data.fetch()
    }
  }

  return (
    <div className={styles.bbsViewComment}>
      <div className={styles.commentHeadArea}>
        <div className={styles.commentHead}>
          <div className={styles.title}>
            댓글 {data.boardData.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
          </div>
          {/* <div className={styles.sortBox}>
            <button
              type="button"
              onClick={() => {
                searchParams.set('sortBy', 'createdAt')
                setSearchParams(searchParams)
              }}
              className={
                searchParams.get('sortBy') === 'createdAt'
                  ? `${commStyles.pipeLine} ${styles.active}`
                  : `${commStyles.pipeLine}`
              }
            >
              등록순
            </button>
            <button
              type="button"
              onClick={() => {
                searchParams.set('sortBy', 'numLikes')
                setSearchParams(searchParams)
              }}
              className={searchParams.get('sortBy') === 'numLikes' ? `${styles.active}` : undefined}
            >
              인기순
            </button>
          </div> */}
        </div>

        <div className={styles.commentInputBox}>
          <div className={styles.commentInput}>
            <textarea
              onChange={e => setComment(e.target.value)}
              value={comment}
              placeholder="댓글을 남겨주세요"
            ></textarea>
          </div>
          <div className={styles.btnCommentWrite}>
            <button type="button" onClick={() => handleUploadComment(0)}>
              등록하기
            </button>
          </div>
        </div>
      </div>

      {data.commentData.length !== 0 && (
        <div className={styles.commentBody}>
          <ul className={styles.commentList}>
            {data.commentData.map((comment: any) => (
              <li key={comment.id}>
                {comment.isDeleted ? (
                  <div className={styles.deleted}>
                    <p>삭제된 댓글입니다.</p>
                  </div>
                ) : (
                  <div className={styles.commentArea}>
                    <div className={styles.nicknameBox}>
                      <span className={styles.nick}>{comment.user.nick}</span>
                      <div className={styles.closeBtn}>
                        {checkAuthor(comment.authorID, data.boardData, data.userID) ? (
                          <button
                            type="button"
                            onClick={() => {
                              setToDeleteCommentID(comment.id)
                            }}
                          >
                            &times;
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className={styles.textBox}>
                      <div className={styles.comment}>{comment.content}</div>
                    </div>

                    <div className={styles.bottomMenu}>
                      <div className={styles.infoText}>
                        <span className={styles.date}>{getHowLongAgo(new Date(comment.createdAt))}</span>
                        {comment.edited ? (
                          <>
                            <span className={commStyles.pipeLine}></span>
                            <span className={styles.modify}>수정됨</span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={styles.btnBox}>
                        {checkAuthor(comment.authorID, data.boardData, data.userID) ? (
                          <button
                            type="button"
                            onClick={() => {
                              setReplyInput(comment.content)
                              setShowReply(-1)
                              setShowEdit(comment.id)
                            }}
                            className={styles.btnEdit}
                          >
                            수정하기
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            setReplyInput('')
                            setShowReply(comment.id)
                            setShowEdit(-1)
                          }}
                          className={styles.btnReply}
                        >
                          답글달기
                        </button>
                      </div>
                    </div>

                    {showReply === comment.id || showEdit === comment.id ? (
                      <div className={styles.replyInputBox}>
                        <div className={styles.closeBtn}>
                          <button
                            type="button"
                            onClick={() => {
                              setReplyInput('')
                              setShowReply(-1)
                              setShowEdit(-1)
                            }}
                          >
                            &times;
                          </button>
                        </div>

                        <div className={styles.commentInput}>
                          <textarea
                            onChange={e => setReplyInput(e.target.value)}
                            value={replyInput}
                            placeholder="답글을 남겨주세요"
                          ></textarea>
                        </div>
                        <div className={styles.btnCommentWrite}>
                          <button
                            type="button"
                            onClick={() =>
                              showReply === comment.id
                                ? handleUploadComment(comment.id)
                                : handleEditComment(comment.id, comment.content)
                            }
                          >
                            등록하기
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
                {comment.replies.map((reply: any) => (
                  <li key={reply.id} className={styles.reply}>
                    {reply.isDeleted ? (
                      <div className={styles.deleted}>
                        <p>삭제된 댓글입니다.</p>
                      </div>
                    ) : (
                      <div className={styles.commentArea}>
                        <div className={styles.nicknameBox}>
                          <span className={styles.nick}>{reply.user.nick}</span>
                          <div className={styles.closeBtn}>
                            {checkAuthor(reply.authorID, data.boardData, data.userID) ? (
                              <button
                                type="button"
                                onClick={() => {
                                  setToDeleteCommentID(reply.id)
                                }}
                              >
                                &times;
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className={styles.textBox}>
                          <div className={styles.comment}>{reply.content}</div>
                        </div>
                        <div className={styles.bottomMenu}>
                          <div className={styles.infoText}>
                            <span className={styles.date}>{getHowLongAgo(new Date(reply.createdAt))}</span>
                            {reply.edited ? (
                              <>
                                <span className={commStyles.pipeLine}></span>
                                <span className={styles.modify}>수정됨</span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className={styles.btnBox}>
                            {checkAuthor(reply.authorID, data.boardData, data.userID) ? (
                              <button
                                type="button"
                                onClick={() => {
                                  setReplyInput(reply.content)
                                  setShowReply(-1)
                                  setShowEdit(reply.id)
                                }}
                                className={styles.btnEdit}
                              >
                                수정하기
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {showEdit === reply.id ? (
                          <div className={styles.replyInputBox}>
                            <div className={styles.closeBtn}>
                              <button
                                type="button"
                                onClick={() => {
                                  setReplyInput('')
                                  setShowReply(-1)
                                  setShowEdit(-1)
                                }}
                              >
                                &times;
                              </button>
                            </div>

                            <div className={styles.commentInput}>
                              <textarea
                                onChange={e => setReplyInput(e.target.value)}
                                value={replyInput}
                                placeholder="답글을 남겨주세요"
                              ></textarea>
                            </div>
                            <div className={styles.btnCommentWrite}>
                              <button type="button" onClick={() => handleEditComment(reply.id, reply.content)}>
                                등록하기
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </li>
                ))}
              </li>
            ))}
          </ul>
        </div>
      )}

      <ModalPopup>
        {toDeleteCommentID !== -1 && (
          <ConfirmModal
            type="confirm"
            title=""
            onSubmit={() => {
              handleDeleteComment(toDeleteCommentID)
              setToDeleteCommentID(-1)
            }}
            onClose={() => {
              setToDeleteCommentID(-1)
            }}
          >
            <DeleteConfirm deleting="댓글" />
          </ConfirmModal>
        )}
      </ModalPopup>
    </div>
  )
}
