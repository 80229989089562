import styles from './confirm.module.scss'

export default function DeleteConfirm({ deleting }: { deleting: string }) {
  return (
    <div className={styles.confirmArea}>
      <div className={styles.title}>{deleting} 삭제</div>
      <ul className={styles.content}>
        <li>
          <div className={styles.subject}>해당 {deleting}을 삭제 하시겠습니까?</div>
        </li>
      </ul>
    </div>
  )
}
