import { subHours, subDays, subMonths } from 'date-fns'

export const HOUR_BUFFER = 8

export function today() {
  const date = new Date()
  return subHours(date, HOUR_BUFFER)
}

export function yesterday() {
  return subDays(today(), 1)
}

export function lastMonth() {
  return subMonths(today(), 1)
}

export const startYear = 2019
