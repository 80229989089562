import styles from './pointshop.module.scss'
import { useState } from 'react'
import { ModalPopup } from '../../popups/ModalPortal'
import { AlertModal } from '../../popups/Alert'
import BoardSignin from '@components/pages/community/shared/BoardSignin.tsx'
import PointGuide from '@components/popups/PointGuide'
import { useQuery } from 'react-query'
import { fetchAvailableRewards } from '@/api/community/reward'
import MorePoints from './MorePoints'
import { getImgByCompany } from '@/helpers/community/Point'
import { ConfirmModal } from '@/components/popups/Confirm'
import BuyConfirm from '@/components/popups/rewards/BuyConfirm'
import BuySuccess from '@/components/popups/rewards/BuySuccess'
import BuyFail from '@/components/popups/rewards/BuyFail'

export default function PointDetails() {
  const {
    data: rewardData,
    isLoading: rewardLoad,
    error: rewardError,
  } = useQuery('rewards', () => fetchAvailableRewards())
  const [showModal, setShowModal] = useState(false)
  const [showBuy, setShowBuy] = useState(false)
  const [showBuyFail, setShowBuyFail] = useState(false)
  const [showBuySuccess, setShowBuySuccess] = useState(false)

  if (rewardLoad || rewardError) {
    return
  }

  return (
    <div className="contents-area">
      <div className={styles.titleArea}>
        <h2>POINT SHOP</h2>
      </div>
      <div className={styles.pointContsArea}>
        <div className={styles.contsArea}>
          <ul className={styles.rewardList}>
            {rewardData.length === 0 ? (
              <li className={`${styles.ptNull}`}>Coming Soon</li>
            ) : (
              rewardData.map((reward: any) => (
                <li className={styles.reward} key={reward.id}>
                  <img src={getImgByCompany(reward.company)} alt={'logo'} className={styles.logo}></img>
                  <span className={styles.rewardInfo}>{reward.name}</span>
                  <span className={styles.rewardPoints}>{reward.cost.toLocaleString()} P</span>
                  <button
                    className={styles.buyBtn}
                    onClick={() => {
                      setShowBuy(true)
                    }}
                  >
                    구매하기
                  </button>
                </li>
              ))
            )}
          </ul>
        </div>

        <div className={styles.aside}>
          <BoardSignin showShop={false} showWrite={false} notLoginText="포인트 샵은 로그인 후 이용 가능합니다" />
          <button type="button" className={styles.btnGuide} onClick={() => setShowModal(true)}>
            포인트획득 가이드
          </button>
          <MorePoints />
        </div>
      </div>

      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="" onClose={setShowModal}>
            <PointGuide />
          </AlertModal>
        )}
        {showBuy && (
          <ConfirmModal
            type="confirm"
            title=""
            onSubmit={() => {
              //handleBuy()
              setShowBuyFail(true)
              setShowBuy(false)
            }}
            onClose={() => {
              setShowBuy(false)
            }}
          >
            <BuyConfirm />
          </ConfirmModal>
        )}
        {showBuyFail && (
          <AlertModal type="alert" title="" onClose={setShowBuyFail}>
            <BuyFail />
          </AlertModal>
        )}
        {showBuySuccess && (
          <AlertModal type="alert" title="" onClose={setShowBuySuccess}>
            <BuySuccess />
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
