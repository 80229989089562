import styles from './main.module.scss'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { fetchBoardListByCategory } from '@/api/community/board.ts'
import { useContext, useRef, useState } from 'react'
import { ModalPopup } from '../../popups/ModalPortal'
import { AlertModal } from '../../popups/Alert'
import PointGuide from '@components/popups/PointGuide'
import { pointsRankingMax } from '../community/shared/consts'
import { fetchTopPointUser } from '@/api/community/point'
import { ThemeContext } from '@/context/ThemeContext'

interface userPoint {
  name: string
  point: number
}

export default function RecentBoard() {
  const { theme } = useContext(ThemeContext)
  const [isPoint, setIsPoint] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const pointRanking = useRef<userPoint[]>([])

  const {
    data: boardData,
    isLoading: boardLoad,
    error: boardError,
  } = useQuery('board-list', () => fetchBoardListByCategory('0', 6, 1, 'createdAt', 'desc', ''))

  const {} = useQuery('user-list', () => fetchTopPointUser(pointsRankingMax), {
    onSuccess: (data: any) => {
      pointRanking.current = []
      for (let user of data) {
        pointRanking.current.push({ name: user.user.nick, point: parseInt(user.points) })
      }
    },
  })

  if (boardLoad || boardError) {
    return
  }

  return (
    <div className={styles.mainCommunityArea}>
      <div className={styles.moTitleArea}>
        <h2 className={styles.moMainTitle}>실시간 커뮤니티</h2>
        <div className="tab-type-horizon">
          <ul>
            <li>
              <a className={!isPoint ? 'active' : ''} onClick={() => setIsPoint(false)}>
                전체글
              </a>
            </li>
            <li>
              <a className={isPoint ? 'active' : ''} onClick={() => setIsPoint(true)}>
                포인트 랭킹
              </a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className={styles.communityTitle}>실시간 커뮤니티</h2>

      {/* 커뮤니티 >> mobile 에서는 클래스가 on 인 경우만 노출 */}
      <div className={!isPoint ? `${styles.community} ${styles.on}` : `${styles.community}`}>
        <div className={styles.contsBox}>
          <div className={styles.commHead}>
            <span>제목</span>
            <span>닉네임</span>
            <span>작성일</span>
            <span>댓글수</span>
            <span>추천수</span>
          </div>
          <div className={styles.commBody}>
            <ul className={styles.body}>
              {/* list item */}
              {boardData.boards?.map((board: any) => (
                <li className={styles.row} key={board.id}>
                  <div className={styles.subject}>
                    <div className={`chip gray sm natural ${styles.pf}`}>{board.category.name}</div>
                    <div className={styles.post}>
                      <Link to={`community/boards/view/${board.id}`}>
                        <span className={styles.txt}>{board.title}</span>
                        <span className={styles.img}>
                          {board.content.includes('<img') ? (
                            <img src="/images/contents/ico_camera.svg" alt="images" />
                          ) : (
                            <></>
                          )}
                        </span>
                        <span className={styles.replyCnt}>
                          &#91;{board.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
                          &#93;
                        </span>
                      </Link>
                    </div>
                  </div>
                  <span className={styles.nickname}>{board.user.nick}</span>
                  <span className={styles.date}>{format(new Date(board.createdAt), 'yyyy.MM.dd HH:mm')}</span>
                  <span className={styles.reply}>
                    {board.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
                  </span>
                  <span className={styles.recommend}>
                    <img src="/images/contents/ico_main_like.svg" alt="like" />
                    {board.likes.length.toLocaleString()}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.rankBtn}>
          <Link to="/community/boards">
            전체글 보기
            <img src={`/images/contents/${theme}/ico_arrow_right_thick.svg`} alt="more" />
          </Link>
        </div>
      </div>

      {/* 포인트랭킹 >> mobile 에서는 클래스가 on 인 경우만 노출 */}
      <div className={isPoint ? `${styles.mainPointRankingArea} ${styles.on}` : `${styles.mainPointRankingArea}`}>
        <div className={styles.contsBox}>
          <h2>
            <span>커뮤니티</span>포인트 랭킹
          </h2>
          <div className={styles.pointRanking}>
            <ul>
              {pointRanking.current.map((user: userPoint, index: number) => (
                <li key={index}>
                  <div className={styles.nickname}>
                    <span>{index + 1}</span>
                    <span>{user.name}</span>
                  </div>
                  <div className={styles.point}>
                    {user.point.toLocaleString()}
                    <span>p</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.pointBtn}>
          <button type="button" onClick={() => setShowModal(true)}>
            포인트 획득 가이드
          </button>
        </div>
      </div>

      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="" onClose={setShowModal}>
            <PointGuide />
          </AlertModal>
        )}
      </ModalPopup>
    </div>
  )
}
