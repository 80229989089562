import { Link } from 'react-router-dom'
import styles from './morePoints.module.scss'
export default function MorePoints() {
  return (
    <div className={styles.pointTip}>
      <div className={styles.tipText}>
        <span>더 많은 포인트</span>를 획득하고 싶다면?
      </div>
      <div className={styles.writeButtonArea}>
        <Link className={styles.writeButton} to="/community/boards/add">
          게시글 작성하기<span>{'>'}</span>
        </Link>
        <Link className={styles.writeButton} to="/community/boards">
          댓글 작성하기<span>{'>'}</span>
        </Link>
      </div>
    </div>
  )
}
