import v2 from './v2'

export async function kakaoSignin(params: { token: string }) {
  const origin = import.meta.env.VITE_APP_HOSTNAME || 'http://localhost:5173'
  return v2.post(
    '/api/auth/signin/kakao',
    { ...params, origin },
    {
      withCredentials: true,
    },
  )
}

export async function googleSignin(params: { code: string }) {
  const origin = import.meta.env.VITE_APP_HOSTNAME || 'http://localhost:5173'
  return v2.post(
    '/api/auth/signin/google',
    { ...params, origin },
    {
      withCredentials: true,
    },
  )
}

export async function fetchUser() {
  return v2
    .get('/api/user', {
      withCredentials: true,
    })
    .then(resp => resp.data)
}

export async function signout() {
  const origin = import.meta.env.VITE_APP_HOSTNAME || 'http://localhost:5173'
  return v2.post('/api/auth/signout', { origin }, { withCredentials: true })
}
