import styles from './popup.module.scss'
// import { useState } from 'react'

interface iConfirmModalProps {
  type?: string
  title?: string
  children: any
  onSubmit: () => void
  onClose: () => void
}

export const ConfirmModal = (props: iConfirmModalProps) => {
  const { type = 'confirm', title = '알림', children } = props

  return (
    <div className={styles.modalWrap}>
      <div className={`${styles.modalContent} ${styles[type]}`}>
        <div className={styles.header}>
          <p className={styles.title}> {title} </p>
          <span onClick={() => props.onClose()} className={styles.close}>
            &times;
          </span>
        </div>
        <div className={styles.contsBox}>{children}</div>
        <div className={styles.footer}>
          <button type="button" onClick={() => props.onClose()} className={styles.btnCancel}>
            취소
          </button>
          <button type="button" onClick={() => props.onSubmit()} className={styles.btnSubmit}>
            확인
          </button>
        </div>
      </div>
    </div>
  )
}
