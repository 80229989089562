import v2 from './v2'

function isAlreadySent(id: string, isChannel: boolean) {
  const key = isChannel ? `CHANNEL_VIEW_${id}` : `BOARD_VIEW_${id}`
  if (window.sessionStorage.getItem(key)) {
    return true
  }
  window.sessionStorage.setItem(key, 'true')
  return false
}

export async function addChannelViewLog(channelId: string) {
  if (isAlreadySent(channelId, true)) return

  return v2.post('/api/statistics/channel-view', { channelId }, { withCredentials: true }).then(res => res.data)
}

export async function getTopViewChannels() {
  return v2.get('/api/statistics/channel-view/rankings').then(res => res.data)
}
