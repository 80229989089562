import { useState } from 'react'
import { useQuery } from 'react-query'
import useAfreecaStore from '@/stores/afreeca'
import useHiddenStore from '@/stores/hidden'
import { getBjListLive } from '@/api/bjpt.ts'
import TopBj from '@components/elements/TopBj.tsx'
import RankingTable from '@components/pages/broadcast/Live/RankingTable.tsx'
import { DATA_COLUMN } from '../../consts'
import CategorySelector from '@/components/elements/selectors/CategorySelector'
import BroadcastHeader from '../BroadcastHeader'

export default function BroadcastLive() {
  const { categories, liveChannels, bjList } = useAfreecaStore()
  const { hiddenBjs } = useHiddenStore()
  const [sortBy, setSortBy] = useState<DATA_COLUMN>(DATA_COLUMN.DONATION)
  const [search, setSearch] = useState('')
  const [selectedCategories, setSelectedCategories] = useState<{ key: string; value: string }[]>([])

  const {
    data: bjRanking,
    isLoading: bjRankingLoading,
    isError: bjRankingError,
  } = useQuery(['bjRanking'], () => {
    return getBjListLive(liveChannels)
  })

  if (bjRankingError) {
    return
  }

  const isLoading = bjRankingLoading

  return (
    <div className="contents-area">
      <BroadcastHeader />
      <TopBj
        topDonated={bjRanking?.topDonated}
        topViewer={bjRanking?.topViewer}
        topHourly={bjRanking?.topHourly}
        showLiveUpdate={true}
        categories={categories}
        bjList={bjList}
        hiddenIDs={hiddenBjs}
      />

      {/* 정렬 선택 */}
      <div className="tab-select-area bg">
        <div className="select-tab">
          <h2>카테고리 선택</h2>
          <CategorySelector
            categories={categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>

        <div className="search-box">
          <div className="search-area">
            <input
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
              type="search"
              id="broadcastSearch"
              name="broadcastSearch"
              placeholder="방송검색"
            />
            <span className="ico-search"></span>
          </div>
        </div>
      </div>

      {/* 랭킹 게시판 */}
      {!isLoading && bjRanking ? (
        <RankingTable
          {...{
            bjRanking: bjRanking.ranking,
            categories,
            bjList,
            sortBy,
            search,
            setSortBy,
            selectedCategories,
            hiddenIDs: hiddenBjs,
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
