import styles from './guide.module.scss'
import Button from '../button/Button'

export default function Main() {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Title</h4>
      <div className={`${styles.border} ${styles.p5}`}>
        <h1>h1 텍스트 h1 텍스트</h1>
        <h2>h2 텍스트 h2 텍스트</h2>
        <h3>h3 텍스트 h3 텍스트</h3>
        <h4>h4 텍스트 h4 텍스트</h4>
        <p className="sub-title-1">sub-title-1</p>
        <p className="sub-title-2">sub-title-2</p>
      </div>

      <h4 className={`${styles.title} ${styles.mt2}`}>Form</h4>
      <form className={styles.box}>
        <div className="form-box">
          <label htmlFor="inText1">라벨</label>
          <input type="text" name="inText1" id="inText1" placeholder="기본인풋" />
          <span className="clear-input">
            <button type="button">&times;</button>
          </span>
          <span className="support-text">supporting text</span>
        </div>

        <div className={`form-box ${styles.mt2}`}>
          <label htmlFor="inText2" className={styles.mt2}>
            라벨
          </label>
          <input type="text" name="inText2" id="inText2" placeholder="오류인풋" className="error" />
          <span className="clear-input">
            <button type="button">&times;</button>
          </span>
          <span className="support-text">supporting text</span>
        </div>

        <input
          type="email"
          name="inText3"
          id="inText3"
          placeholder="readonly"
          className={`${styles.block} ${styles.mt2}`}
          readOnly
        />
        <input
          type="email"
          name="inText4"
          id="inText4"
          placeholder="disabled"
          className={`${styles.block} ${styles.mt2}`}
          disabled
        />

        <span className={`${styles.block} ${styles.mt2}`}>
          <input type="checkbox" id="chk1" name="chk1" defaultChecked={true} />
          <label htmlFor="chk1">체크박스</label>

          <input type="checkbox" id="chk2" name="chk2" disabled />
          <label htmlFor="chk2">readonly/disabled</label>

          <input type="checkbox" id="chk3" name="chk3" checked disabled />
          <label htmlFor="chk2">checked - readonly/disabled</label>
        </span>

        <span className="block">
          <input type="radio" id="rdo1" name="rdo" value="1" defaultChecked={true} />
          <label htmlFor="rdo1">라디오</label>

          <input type="radio" id="rdo2" name="rdo" value="2" />
          <label htmlFor="rdo2">라디오</label>

          <input type="radio" id="rdo3" name="rdo" value="3" disabled />
          <label htmlFor="rdo3">disabled</label>

          <input type="radio" id="rdo4" name="rdo2" value="4" checked disabled />
          <label htmlFor="rdo4">checked - readonly/disabled</label>
        </span>

        <div className="form-select">
          <select className={`${styles.block} ${styles.mt2}`}>
            <option value="someOption">Some option</option>
            <option value="otherOption">Other option</option>
          </select>
        </div>

        <textarea name="tx" placeholder="내용을 입력하여 주세요." className={styles.mt2}></textarea>
      </form>

      <h4 className={`${styles.title} ${styles.mt2}`}>Button</h4>
      <div className={`${styles.border} ${styles.p5} ${styles.box}`}>
        <div className="btn-area center">
          <Button color="secondary" type="outline" text="취소" />
          <Button text="확인" />
        </div>

        <div className="btn-area right my-50">
          <Button color="ghost" size="large" text="고스트 라지" />
          <Button color="accent" size="large" text="엑센트 라지" />
          <Button color="accent" size="large" type="outline" text="엑센트 아웃라인 라지" />
          {/* size='medium',
              color='primary',
              type='normal',
              width='inline',
              text = '확인' */}
        </div>
      </div>

      <h4 className={`${styles.title} ${styles.mt2}`}>Button</h4>
      <div className={`${styles.border} ${styles.p5} ${styles.box}`}>
        <span className="chip blue">label</span>
        <span className="chip red lg">label</span>
        <span className="chip orange">label</span>
        <span className="chip green">label</span>
        <span className="chip gray">label</span>
        <span className="chip orange natural">label</span>
      </div>

      {/* style={{color: "red"}} */}

      {/* <div className="select-dropdown">
          <div className="dropdown-btn">드롭다운 버튼</div>

          <ul className="dropdown-menu">
            <li className="dropdown-item"><button type="button" value="1">드롭다운 메뉴 1</button></li>
            <li className="dropdown-item"><button type="button" value="2">드롭다운 메뉴 2</button></li>
            <li className="dropdown-item"><button type="button" value="3">드롭다운 메뉴 3</button></li>
            <li className="dropdown-item"><button type="button" value="4">드롭다운 메뉴 4</button></li>
            <li className="dropdown-item"><button type="button" value="5">드롭다운 메뉴 5</button></li>
            <li className="dropdown-item"><button type="button" value="5">드롭다운 메뉴 6</button></li>
          </ul>
        </div> */}
    </div>
  )
}
