import styles from './footer.module.scss'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div id="footer" className={styles.footer}>
      <div className={styles.footArea}>
        <div className={styles.logo}>
          <span>ARACEL</span>
          <span> &copy; 2024 ARACEL</span>
        </div>
        <div className={styles.btn}>
          <Link to="/my/inquiries/add">광고/제휴문의</Link>
          {/* <Link to="/">1:1문의</Link> */}
        </div>

        <div className={styles.text}>
          <p>
            풍투데이에 표시된 모든 브랜드, 상표는 제3자의 소유입니다. 풍투데이는 정보 제공의 목적으로만 데이터를
            사용하며, 누구나 자유로이 접근이 가능한 데이터만을 수집합니다.
          </p>
          <p className={styles.borderTop}>
            주식회사 아라셀 | 서울특별시 강남구 봉은사로 129, 1518호 | 대표  박주수 | 사업자등록번호 650-81-01894 |
            Email -{' '}
            <u>
              <a>jwky0526@gmail.com</a>
            </u>
          </p>
        </div>
      </div>
    </div>
  )
}
