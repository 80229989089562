import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

export const enum TAB {
  POINT = 'POINT',
  COUPON = 'COUPON',
}

function getActiveTab(pathname: string) {
  if (pathname.includes('points')) return TAB.POINT
  if (pathname.includes('coupons')) return TAB.COUPON
  return TAB.POINT
}

export default function PointAside() {
  const location = useLocation()

  function getLink() {
    return (
      <ul>
        <li>
          <Link to="/my/points" className={cx({ active: getActiveTab(location.pathname) === TAB.POINT })}>
            포인트 내역
          </Link>
        </li>
        <li>
          <Link to="/my/coupons" className={cx({ active: getActiveTab(location.pathname) === TAB.COUPON })}>
            쿠폰 보관함
          </Link>
        </li>
      </ul>
    )
  }
  return <div className="tab-type-horizon">{getLink()}</div>
}
