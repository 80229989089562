import styles from './myCommunity.module.scss'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

export const enum TAB {
  BOARD = 'BOARD',
  COMMENT = 'COMMENT',
}

function getActiveTab(pathname: string) {
  if (pathname.includes('board')) return TAB.BOARD
  if (pathname.includes('comment')) return TAB.COMMENT
  return TAB.BOARD
}
export default function MyCommunityAside() {
  const location = useLocation()

  function getLink() {
    return (
      <ul>
        <li>
          <Link to="/community/my/boards" className={cx({ active: getActiveTab(location.pathname) === TAB.BOARD })}>
            내 게시글
          </Link>
        </li>
        <li>
          <Link to="/community/my/comments" className={cx({ active: getActiveTab(location.pathname) === TAB.COMMENT })}>
            내 댓글
          </Link>
        </li>
      </ul>
    )
  }
  return (
    <>
      <div className={`history-aside ${styles.mypageAside}`}>
        {/* 메뉴 선택 */}
        <div className="tab-select-area">
          <div className="tab-type-round w-100">
            {/* <div>메뉴 선택</div> */}
            {getLink()}
          </div>
        </div>
      </div>
      <div className={styles.moTabArea}>
        <div className="tab-type-horizon">{getLink()}</div>
      </div>
    </>
  )
}
