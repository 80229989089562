import BroadcastProfile from './BroadcastProfile'
import styles from './results.module.scss'

export default function BroadcastResult({
  bjData,
  total,
  monthlyStat,
  hiddenIDs,
}: {
  bjData: any
  total: number
  monthlyStat: any
  hiddenIDs: any
}) {
  return (
    <div className={styles.contsBox}>
      <h4>방송</h4>
      <div className={styles.resultCnt}>
        <span>{total.toLocaleString()}</span>
        <p>개의 검색 결과가 있습니다.</p>
      </div>

      {/* 검색 결과 > 방송 리스트 */}
      <div className={styles.listBroad}>
        <ul className={styles.box}>
          {bjData.map((bj: any) => (
            <BroadcastProfile key={bj.i} bj={bj} monthlyStat={monthlyStat} hiddenIDs={hiddenIDs} />
          ))}
        </ul>
      </div>
    </div>
  )
}
