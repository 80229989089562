import { ThemeContext } from '@/context/ThemeContext'
import styles from '@components/pages/main/main.module.scss'
import TopViewRankings from '@components/pages/main/TopViewRankings.tsx'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Search() {
  const { theme } = useContext(ThemeContext)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  function handleSubmit(e: any) {
    e.preventDefault()
    if (search.replace(/ /g, '') !== '') {
      navigate(`/search/results?search=${search.trim()}`)
    }
    return false
  }

  return (
    <div className={styles.mainSearchArea}>
      <div className={styles.logo}>
        <img src={`/images/common/${theme}/logo.svg`} alt="logo" />
      </div>
      <form
        className={`search-area ${styles.search}`}
        onClick={() => {
          if (window.innerWidth <= 1024) {
            navigate(`/search/results?search=${search}`)
          }
        }}
        onSubmit={handleSubmit}
      >
        <input
          type="search"
          id="headSearch"
          value={search}
          name="headSearch"
          placeholder="랭킹이 궁금한 방송을 검색해 보세요"
          onChange={e => setSearch(e.target.value)}
        />
        <button
          type="submit"
          onClick={handleSubmit}
          className={`ico-search ${theme === 'light' ? '' : theme} ${styles.icoSearch}`}
        ></button>
      </form>

      <TopViewRankings />
    </div>
  )
}
