import { useNavigate, useParams } from 'react-router-dom'
import { useState, useMemo, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import ReactQuill from 'react-quill'
import { updateBoard, fetchBoardByID } from '@/api/community/board'
import { returnQuillModule } from '@/helpers/community/QuillModule'
import { whitespaceOnly } from '@/helpers/community/BoardFunctions'
import useUserStore from '@/stores/user'
// import BoardPolicy from './shared/BoardPolicy';

import commStyles from './commBoard.module.scss'
import styles from './boardWrite.module.scss'

export default function BoardEdit() {
  const { user } = useUserStore()
  const { id } = useParams() as { id: string }
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const { data: boardData, isLoading: boardLoad, error: boardError } = useQuery('board', () => fetchBoardByID(id))
  const quillRef = useRef(null)
  const navigate = useNavigate()
  const modules = useMemo(() => {
    return returnQuillModule(quillRef)
  }, [])

  useEffect(() => {
    if (boardData) {
      setTitle(boardData.title)
      setContent(boardData.content)
    }
  }, [])

  if (boardLoad || boardError || !user || user.id !== boardData.authorID) {
    return
  }

  function handleEditBoard() {
    if (whitespaceOnly(content, true) || whitespaceOnly(title, false)) {
      alert('게시판 글을 작성하여 주십시요.')
    } else {
      if (boardData.title !== title || boardData.content !== content) {
        try {
          updateBoard(id, { title: title, content: content, edited: true, isDeleted: false })
        } catch (error) {
          console.log(error)
        }
      }
      navigate(`/community/boards/view/${id}`)
    }
  }

  return (
    <div className="contents-area" style={{ maxWidth: '800px' }}>
      <div className={commStyles.titleArea}>
        <h2>글 수정</h2>
      </div>

      <div className={styles.boardWriteWrap}>
        <div className={styles.writeArea}>
          <div>
            <label className="blind">제목</label>
            <input
              type="text"
              onChange={e => setTitle(e.target.value)}
              value={title}
              className="w-100"
              placeholder="제목을 입력하여 주세요."
            ></input>
          </div>

          <div className={styles.writeEditor}>
            <ReactQuill
              ref={quillRef}
              modules={modules}
              placeholder="내용을 입력하여 주세요."
              theme="snow"
              onChange={setContent}
              value={content}
            ></ReactQuill>
          </div>

          <div className={styles.txt}>※ 주의 : 게시물은 텍스트, 이미지를 포함해 10MB 이하입니다.</div>
        </div>

        <div className={`btn-area center ${commStyles.btnArea}`}>
          <button type="button" onClick={() => navigate(`/community/boards/view/${id}`)} className={commStyles.cancel}>
            취소
          </button>
          <button type="button" onClick={handleEditBoard} className={commStyles.ok}>
            등록
          </button>
        </div>

        {/* <BoardPolicy /> */}
      </div>
    </div>
  )
}
