import { useQuery } from 'react-query'
import styles from './results.module.scss'
import { Link } from 'react-router-dom'
import { getBjHistory } from '@/api/afreeca'
import { getTier, getTopPct } from '@/helpers/bj/helper'
import { Tooltip } from 'react-tooltip'
import { generateRandomString } from '@/helpers/hidden'
import { useContext } from 'react'
import { ThemeContext } from '@/context/ThemeContext'

export default function BroadcastProfile({
  bj,
  monthlyStat,
  hiddenIDs,
}: {
  bj: any
  monthlyStat: any
  hiddenIDs: any
}) {
  const { theme } = useContext(ThemeContext)
  const {
    data: bjHistory,
    isLoading: bjHistoryLoading,
    isError: bjHistoryError,
  } = useQuery(`bjHistory/${bj.i}`, () => getBjHistory(bj.i))

  const ranking = monthlyStat.ranking ? monthlyStat.ranking.findIndex((b: any) => b.i === bj.i) + 1 : 0
  const tier = ranking === 0 ? getTier(1) : getTier(ranking / monthlyStat.ranking.length)

  if (bjHistoryLoading || bjHistoryError) {
    return
  }
  if (hiddenIDs.includes(bj.i)) {
    return (
      <li>
        <div className={styles.name}>
          <div className={`${styles.img} ${styles.af}`}>
            <img src={'/images/contents/ico_logo_afreecatv.svg'} alt="thumn" />
          </div>
          <div className={styles.nickname}>
            <Link to={`https://www.afreecatv.com`} className={styles.nick}>
              {generateRandomString(10)}
              <span className={styles.af}>
                <img src="/images/contents/ico_logo_afreecatv_lg.svg" alt="afreecatv" />
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.state}>
          <div className={styles.stateBox}>
            <span>별풍선</span>
            <span>0</span>
          </div>
          <div className={styles.stateBox}>
            <span>팔로워</span>
            <span>0</span>
          </div>
        </div>
      </li>
    )
  }
  return (
    <li>
      <div className={styles.name}>
        <div className={`${styles.img} ${styles.af}`}>
          <img src={`https://profile.img.afreecatv.com/LOGO/${bj.i.substring(0, 2)}/${bj.i}/${bj.i}.jpg`} alt="thumn" />
        </div>
        <div className={styles.nickname}>
          <Link to={`/broadcast/${bj.i}`} className={styles.nick}>
            {bj.n}
            <span className={styles.af}>
              <img src="/images/contents/ico_logo_afreecatv_lg.svg" alt="afreecatv" />
            </span>
          </Link>
          <div className={styles.chip}>
            {bjHistory !== undefined && bjHistory.is_partner_bj ? (
              <span className={`chip blue`}>파트너 BJ</span>
            ) : (
              <></>
            )}
            {bjHistory !== undefined && bjHistory.is_best_bj ? <span className={`chip green`}>베스트 BJ</span> : <></>}
            {bjHistory !== undefined &&
            bjHistory.station.groups.filter((group: any) => group.info.group_name === '프로게이머BJ').length !== 0 ? (
              <span className={`chip gray`}>프로게이머 BJ</span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className={styles.state}>
        <div className={styles.stateBox}>
          <span>별풍선</span>
          <span>
            Tier {tier + ' '}
            <img
              data-tooltip-id="tier-info"
              data-tooltip-content={`Tier ${tier}: 상위 ${getTopPct(tier)}%`}
              src={`/images/contents/${theme}/ico_info.svg`}
              alt="information"
            />
            <Tooltip className={styles.tierTooltip} place="bottom" id="tier-info" />
          </span>
        </div>
        <div className={styles.stateBox}>
          <span>팔로워</span>
          <span>{bjHistory !== undefined ? bjHistory.station.upd.fan_cnt.toLocaleString() : 0}</span>
        </div>
      </div>
    </li>
  )
}
