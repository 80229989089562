export const enum PERIOD_TYPE {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const enum DATE_TEMPLATE {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
}

export const enum DATA_COLUMN {
  DONATION = 'b', // 별풍선
  DONATION_COUNT = 'c', // 선물 횟수
  STREAMER_COUNT = 's', // 선물한 방송 수
  HOURLY = 'p', // 시급
  CUMULATED_VIEWER = 'v', // 최고 시청자 (일별)/누적 시청자 (월별)
  CONCURRENT_VIEWER = 'm', // 최고 시청자
  BROADCAST_TIME = 't', // 방송 시간
}

export const enum CHART_CATEGORY {
  DONATION = 'b', // 별풍선
  CONCURRENT_VIEWER = 'm', // 동시 시청자
  FAVOURITE = 'f', // 즐겨찾기
  UP = 'u', // up
  SUBSCRIBERS = 's', // 구독자
}

export const enum BJ_CATEGORY {
  BJ_INFO = 'b', // 방송 정보
  HISTORY = 'h', // 히스토리
  FAN_RANKING = 'f', // 팬 랭킹
}

export const enum RESULT_CATEGORY {
  ALL = 'a',
  BJ = 'b',
  COMMUNITY = 'c',
}

export const enum TOP_CATEGORY {
  DONATION = 'b',
  VIEWER = 'v',
  HOURLY = 'p',
}

export const dropDownSize = 6
export const notShowSeconds = 86400000 // miliseconds to not show popup (default 1 day)
export const increasingList = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i)
