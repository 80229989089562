import { Link } from 'react-router-dom'
import styles from './results.module.scss'
import { format } from 'date-fns'

export default function CommunityResult({ boardData, total }: { boardData: any; total: number }) {
  return (
    <div className={styles.contsBox}>
      <h4>커뮤니티</h4>
      <div className={styles.resultCnt}>
        <span>{total.toLocaleString()}</span>
        <p>개의 검색 결과가 있습니다.</p>
      </div>

      <div className="community-area">
        <div className="community-box">
          <div className="community-head">
            <span>제목</span>
            <span>닉네임</span>
            <span>작성일</span>
            <span>댓글수</span>
            <span>추천수</span>
          </div>
          <div className="community-body">
            <ul className="body">
              {boardData.map((board: any) => (
                <li className="row" key={board.id}>
                  <div className="subject">
                    <div className="chip gray sm natural af">{board.category.name}</div>
                    <div className="post">
                      <Link to={`/community/boards/view/${board.id}`}>
                        <span className="txt">{board.title}</span>
                        <span className="img">
                          {board.content.includes('<img') ? (
                            <img src="/images/contents/ico_camera.svg" alt="images" />
                          ) : (
                            <></>
                          )}
                        </span>
                        <span className="reply-cnt">
                          &#91;{board.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
                          &#93;
                        </span>
                      </Link>
                    </div>
                  </div>
                  <span className="nickname">{board.user.nick}</span>
                  <span className="date">{format(new Date(board.createdAt), 'yyyy.MM.dd HH:mm')}</span>
                  <span className="reply">
                    {board.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
                  </span>
                  <span className="recommend">
                    <img src="/images/contents/ico_main_like.svg" alt="like" />
                    {board.likes.length.toLocaleString()}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
