import styles from './inquiry.module.scss'
import { Link } from 'react-router-dom'
import useUserStore from '@/stores/user'
import MyPageHeader from '../MyPageHeader'
import MyPageAside from '../MyPageAside'
import { useState } from 'react'
import { format } from 'date-fns'
import Paging from '@/components/elements/Paging'
import { LIMIT } from '../../community/shared/consts'

export default function InquiryList() {
  const [page, setPage] = useState(1)
  const { user, fetch } = useUserStore()
  const lastPage = !user || user.inquiries.length === 0 ? 1 : Math.ceil(user.inquiries.length / LIMIT)

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <MyPageHeader user={user} fetch={fetch} />

      {user ? (
        <div className="history-wrap">
          <MyPageAside />
          <div className="history-conts">
            <div className={styles.inquiryHeader}>
              <span className={styles.txt}>1:1 문의내역</span>
              <Link to={`/my/inquiries/add`} className={styles.writeBtn}>
                1:1 문의하기
              </Link>
            </div>
            <div className="community-area" style={{ marginTop: '0' }}>
              <div className="community-box">
                <div className="community-head">
                  <div>문의유형</div>
                  <div>제목</div>
                  <div>상태</div>
                  <div>문의날짜</div>
                </div>
                <div className="community-body">
                  <ul className="body">
                    {user.inquiries.length === 0 ? (
                      <li className={`row list-null `}>문의가 없습니다.</li>
                    ) : (
                      user.inquiries
                        .sort((i1: any, i2: any) => i2.id - i1.id)
                        .slice((page - 1) * LIMIT, page * LIMIT)
                        .map((inquiry: any) => (
                          <li className="row" key={inquiry.id}>
                            <span className="nickname-noline">{inquiry.category}</span>
                            <div className="subject">
                              <div className="post">
                                <Link to={`/my/inquiries/view/${inquiry.id}`} style={{ marginLeft: '0' }}>
                                  <span className="txt">{inquiry.title}</span>
                                </Link>
                              </div>
                            </div>
                            <div className="nickname">
                              {inquiry.status === '답변 완료' && <div className="chip blue natural">답변 완료</div>}
                              {inquiry.status === '답변 불가' && <div className="chip red natural">답변 불가</div>}
                              {inquiry.status === '접수 완료' && <div className="chip green natural">접수 완료</div>}
                            </div>
                            <span className="date-noline">
                              {format(new Date(inquiry.createdAt), 'yyyy.MM.dd HH:mm')}
                            </span>
                          </li>
                        ))
                    )}
                  </ul>
                </div>
              </div>
              <Paging page={page} setPage={setPage} lastPage={lastPage} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
