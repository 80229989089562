import { PAGES } from '../pages/community/shared/consts'

export default function Paging({ page, setPage, lastPage }: { page: number; setPage: any; lastPage: number }) {
  function getPageArray() {
    let pageArray = []
    for (let i = PAGES * (Math.ceil(page / PAGES) - 1) + 1; i <= PAGES * Math.ceil(page / PAGES); i++) {
      if (i <= lastPage && i >= 1) {
        pageArray.push(i)
      }
    }
    return pageArray
  }

  return (
    <div className="paging">
      <a
        onClick={() => {
          setPage(1)
        }}
      >
        <img src="/images/contents/ico_arrow_left_double_thick.svg" alt="start" />
      </a>
      {page - PAGES < 1 ? (
        <></>
      ) : (
        <a
          onClick={() => {
            setPage(page - PAGES)
          }}
        >
          &lt;
        </a>
      )}
      {getPageArray().map((p: number) => (
        <a
          onClick={() => {
            setPage(p)
          }}
          className={p === page ? 'active' : ''}
          key={p}
        >
          {p}
        </a>
      ))}

      {page + PAGES > lastPage ? (
        <></>
      ) : (
        <a
          onClick={() => {
            setPage(page + PAGES)
          }}
        >
          &gt;
        </a>
      )}
      <a
        onClick={() => {
          setPage(lastPage)
        }}
      >
        <img src="/images/contents/ico_arrow_right_double_thick.svg" alt="end" />
      </a>
    </div>
  )
}
