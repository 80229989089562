import { create } from 'zustand'
import { fetchBoardAnnouncements, fetchPopups } from '@/api/community/announcement'

export default create<{
  announcements: any
  popups: any
  fetch: any
}>(set => ({
  announcements: [],
  popups: [],
  fetch: async () => {
    const [announcements, popups] = await Promise.all([fetchBoardAnnouncements(), fetchPopups()])
    set({ announcements, popups })
  },
}))
