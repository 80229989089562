import { useState } from 'react'
import styles from './popup.module.scss'

export default function Popup({
  popupData,
  dontShowToday,
  setDontShowToday,
}: {
  popupData: any
  dontShowToday: boolean
  setDontShowToday: (checked: boolean) => void
}) {
  const [index, setIndex] = useState(0)
  return (
    <div>
      <div className={styles.contsTitle}>{popupData.title}</div>
      <div className={`${styles.contsContents} ql-editor`}>
        <div dangerouslySetInnerHTML={{ __html: popupData[index].content }} />
      </div>
      <div className={styles.arrowButtons}>
        <button
          onClick={() => {
            if (index <= 0) {
              setIndex(popupData.length - 1)
            } else {
              setIndex(index - 1)
            }
          }}
        >
          &lt;
        </button>
        <span>
          {index + 1}/{popupData.length}
        </span>
        <button
          onClick={() => {
            if (index >= popupData.length - 1) {
              setIndex(0)
            } else {
              setIndex(index + 1)
            }
          }}
        >
          &gt;
        </button>
      </div>
      <div className={styles.checkBox}>
        <input
          type="checkbox"
          id="dontShowToday"
          name="dontShowToday"
          disabled={false}
          checked={dontShowToday}
          onChange={e => setDontShowToday(e.target.checked)}
        />
        <label htmlFor="dontShowToday">하루 동안 보지않기</label>
      </div>
    </div>
  )
}
