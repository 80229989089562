import styles from './platform.module.scss'
import { average } from '@/utils/math'
import { today } from '@/utils/date.ts'

function BalloonStatistics({
  donation,
  averageViewer,
  averageChannel,
  live,
}: {
  donation: number
  averageViewer: number
  averageChannel: number
  live: boolean
}) {
  return (
    <div className={styles.contsBox}>
      <div className="box-title">
        <h2>별풍선 통계</h2>
        {live ? <span className="chip live">실시간 업데이트</span> : <></>}
      </div>
      <div className={styles.statsArea}>
        <div className={styles.box}>
          <span className={styles.txt}>누적 별풍선</span>
          <span className={styles.number}>{donation.toLocaleString()}</span>
        </div>
        <ul className={styles.listBox}>
          <li>
            <span className={styles.txt}>시간 당 평균 별풍선</span>
            <span className={styles.number}>{Math.ceil(donation / today().getHours()).toLocaleString()}</span>
          </li>
          <li>
            <span className={styles.txt}>방송 당 평균 별풍선</span>
            <span className={styles.number}>{Math.ceil(donation / averageChannel).toLocaleString()}</span>
          </li>
          <li>
            <span className={styles.txt}>시청자 당 평균 별풍선</span>
            <span className={styles.number}>{Math.ceil(donation / averageViewer).toLocaleString()}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

function ViewerStatistics({
  now,
  vMax,
  averageViewer,
  live,
}: {
  now: number
  vMax: number
  averageViewer: number
  live: boolean
}) {
  return (
    <div className={styles.contsBox}>
      <div className="box-title column">
        <h2>시청자 통계</h2>
        {live ? <span className="chip live">실시간 업데이트</span> : <></>}
      </div>
      <div className={styles.statsArea}>
        <div className={styles.box}>
          <span className={styles.txt}>최고 시청자</span>
          <span className={styles.number}>{Math.max(now, vMax).toLocaleString()}</span>
        </div>
        <div className={styles.box}>
          <span className={styles.txt}>평균 시청자</span>
          <span className={styles.number}>{averageViewer.toLocaleString()}</span>
        </div>
      </div>
    </div>
  )
}

function ChannelStatistics({
  now,
  cMax,
  averageChannel,
  live,
}: {
  now: number
  cMax: number
  averageChannel: number
  live: boolean
}) {
  return (
    <div className={styles.contsBox}>
      <div className="box-title column">
        <h2>방송 수 통계</h2>
        {live ? <span className="chip live">실시간 업데이트</span> : <></>}
      </div>
      <div className={styles.statsArea}>
        <div className={styles.box}>
          <span className={styles.txt}>최고 방송 수</span>
          <span className={styles.number}>{Math.max(now, cMax).toLocaleString()}</span>
        </div>
        <div className={styles.box}>
          <span className={styles.txt}>평균 방송 수</span>
          <span className={styles.number}>{averageChannel.toLocaleString()}</span>
        </div>
      </div>
    </div>
  )
}

export default function Statistics({
  data,
  today,
  now,
  dailyPoints,
}: {
  data: any
  today: boolean
  now: any
  dailyPoints: number
}) {
  const avgViewer = today ? average(data.vList.slice(dailyPoints)) : average(data.vList.slice(0, dailyPoints))
  const avgChannel = today ? average(data.cList.slice(dailyPoints)) : average(data.cList.slice(0, dailyPoints))
  let yesterdaySum = 0
  if (!today) {
    data.bList.slice(0, dailyPoints).forEach((b: number) => {
      yesterdaySum += b
    })
  }

  return (
    <div className={styles.totalStatsArea}>
      {/* 별풍선 통계 */}
      <BalloonStatistics
        donation={today ? now.b : yesterdaySum}
        averageChannel={avgChannel}
        averageViewer={avgViewer}
        live={today}
      />

      {/* 시청자 통계 */}
      <ViewerStatistics
        now={now.v}
        vMax={today ? data.vTodayMax : data.vYdayMax}
        averageViewer={avgViewer}
        live={today}
      />

      {/* 방송 수 통계 */}
      <ChannelStatistics
        now={now.c}
        cMax={today ? data.cTodayMax : data.cYdayMax}
        averageChannel={avgChannel}
        live={today}
      />
    </div>
  )
}
