import styles from './main.module.scss'
import { getAfreecaCount } from '@/api/poongtoday'
import { useQuery } from 'react-query'

export default function Summary() {
  const {
    data: afreeaCount,
    isLoading: afreeaCountLoading,
    isError: afreeaCountError,
  } = useQuery('afreeaCount', () => getAfreecaCount())

  if (afreeaCountLoading || afreeaCountError || !afreeaCount) {
    return
  }

  return (
    <div className={styles.mainCountArea}>
      <ul>
        <li>
          <span className={styles.title}>오늘 누적 별풍선</span>
          <span className={styles.cnt}>{afreeaCount.b.toLocaleString()}</span>
        </li>
        <li>
          <span className={styles.title}>이번달 누적 별풍선</span>
          <span className={styles.cnt}>{afreeaCount.m.toLocaleString()}</span>
        </li>
        <li>
          <span className={styles.title}>현재 시청자 수</span>
          <span className={styles.cnt}>{afreeaCount.v.toLocaleString()}</span>
        </li>
        <li>
          <span className={styles.title}>현재 방송 수</span>
          <span className={styles.cnt}>{afreeaCount.c.toLocaleString()}</span>
        </li>
      </ul>
    </div>
  )
}
