import { useContext } from 'react'
import styles from '../confirm.module.scss'
import { ThemeContext } from '@/context/ThemeContext'

export default function BuyFail() {
  const { theme } = useContext(ThemeContext)
  return (
    <div className={styles.confirmArea}>
      <img className={styles.redAlert} src={`/images/contents/${theme}/caution_thick.svg`} alt="체크" />
      <div className={`${styles.title} ${styles.lower}`}>쿠폰 구매 실패</div>
      <ul className={styles.content}>
        <li>
          <div className={styles.subtitle}>현재 쿠폰이 없거나 오류가 났습니다</div>
        </li>
      </ul>
    </div>
  )
}
