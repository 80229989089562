import styles from '../confirm.module.scss'

export default function BuyConfirm() {
  return (
    <div className={styles.confirmArea}>
      <div className={styles.title}>쿠폰 구매하기</div>
      <ul className={styles.content}>
        <li>
          <div className={styles.subject}>
            구매하신 쿠폰은 커뮤니티 {'>'} 포인트 내역 {'>'} 쿠폰 보관함 에서 확인하실 수 있습니다.
          </div>
        </li>
      </ul>
    </div>
  )
}
