import styles from '../live.module.scss'
import { useState, useEffect } from 'react'
import BroadcastListItem from '@/components/elements/lists/BroadcastListItem'
import { DATA_COLUMN, dropDownSize } from '../../consts'
import ColumnTabItem from '@/components/elements/tabs/ColumnTabItem'
import { useQuery } from 'react-query'
import { getBjHistory } from '@/api/afreeca'

function BroadcastItem({ bj, index, sortBy, mainCategory, hiddenIDs }: any) {
  const {
    data: bjHistory,
    isLoading: bjHistoryLoading,
    isError: bjHistoryError,
  } = useQuery(`bjHistory/${bj.i}`, () => getBjHistory(bj.i))

  if (bjHistoryLoading || bjHistoryError) {
    return
  }

  return (
    <li className="row" key={bj.i}>
      <div className="rank af">{index + 1}</div>
      {/* <div className="rank ch">1</div> */}
      <BroadcastListItem
        bjData={
          hiddenIDs.includes(bj.i)
            ? null
            : {
                profile_image: `https://profile.img.afreecatv.com/LOGO/${bj.i.substring(0, 2)}/${bj.i}/${bj.i}.jpg`,
                i: bj.i,
                n: bj.n,
                //is_afreeca: true,
                live: true,
                mainCategory: mainCategory,
                title: bjHistory.broad ? bjHistory.broad.broad_title : '',
              }
        }
      ></BroadcastListItem>

      <div className={sortBy === DATA_COLUMN.DONATION ? 'col c-white' : 'col'}>{bj.b.toLocaleString()}</div>
      <div className={sortBy === DATA_COLUMN.HOURLY ? 'col c-white' : 'col'}>
        {Math.ceil((bj.b * 60 * 60) / bj.t).toLocaleString()}
      </div>
      <div className={sortBy === DATA_COLUMN.CUMULATED_VIEWER ? 'col c-white' : 'col'}>{bj.v.toLocaleString()}</div>
      <div className={sortBy === DATA_COLUMN.DONATION_COUNT ? 'col c-white' : 'col'}>{bj.c.toLocaleString()}</div>
      {/* <div className="col">8,000</div> */}
    </li>
  )
}

export default function RankingTable({
  bjRanking,
  categories,
  bjList,
  sortBy,
  search,
  setSortBy,
  selectedCategories,
  hiddenIDs,
}: {
  bjRanking: any
  categories: any
  bjList: any
  sortBy: DATA_COLUMN
  search: string
  setSortBy: any
  selectedCategories: { key: string; value: string }[]
  hiddenIDs: any
}) {
  const [showAmount, setShowAmount] = useState(50)
  let rankingItems: any[] = []

  function getBjCategory(bjID: string): string {
    const foundBj = bjList?.find((bj: any) => bj.i === bjID)
    if (foundBj && foundBj.c && foundBj.c.length > 0) {
      return categories[String(foundBj.c[0] * 10000 + foundBj.c[1]).padStart(8, '0')]
    } else {
      return ''
    }
  }

  function handleChangeColumn(column: DATA_COLUMN) {
    setSortBy(column)
  }

  useEffect(() => {
    setShowAmount(50)
  }, [search, selectedCategories])

  const selectedCates = selectedCategories.map((c: { key: string; value: string }) => c.value)
  if (sortBy === DATA_COLUMN.DONATION) {
    rankingItems = bjRanking.sort((bj1: any, bj2: any) => bj2.b - bj1.b) ?? []
  } else if (sortBy === DATA_COLUMN.CUMULATED_VIEWER) {
    rankingItems = bjRanking.sort((bj1: any, bj2: any) => bj2.v - bj1.v) ?? []
  } else if (sortBy === DATA_COLUMN.HOURLY) {
    rankingItems =
      bjRanking.sort((bj1: any, bj2: any) => {
        const h1 = bj1.t === 0 ? 0 : bj1.b / bj1.t
        const h2 = bj2.t === 0 ? 0 : bj2.b / bj2.t
        return h2 - h1
      }) ?? []
  } else if (sortBy === DATA_COLUMN.DONATION_COUNT) {
    rankingItems = bjRanking.sort((bj1: any, bj2: any) => bj2.c - bj1.c) ?? []
  }

  rankingItems = rankingItems.filter((bj: any) => {
    if (selectedCates.length === 0) {
      return bj.n.includes(search)
    } else {
      return selectedCates.includes(getBjCategory(bj.i)) && bj.n.includes(search)
    }
  })

  return (
    <div className={styles.bbsWrap}>
      <div className="bbs-area">
        <div className="pt-bbs bigger">
          <div className={`bbs-head ${styles.bbsHead}`}>
            <span className="num">순위</span>
            <span></span>
            <ColumnTabItem
              text={'별풍선 총합 •'}
              selectedColumn={sortBy}
              value={DATA_COLUMN.DONATION}
              onChange={handleChangeColumn}
            />
            <ColumnTabItem
              text={'시급(풍) •'}
              selectedColumn={sortBy}
              value={DATA_COLUMN.HOURLY}
              onChange={handleChangeColumn}
            />
            <ColumnTabItem
              text={'시청자 •'}
              selectedColumn={sortBy}
              value={DATA_COLUMN.CUMULATED_VIEWER}
              onChange={handleChangeColumn}
            />
            <ColumnTabItem
              text={'선물 횟수 •'}
              selectedColumn={sortBy}
              value={DATA_COLUMN.DONATION_COUNT}
              onChange={handleChangeColumn}
            />
          </div>
          <div className="bbs-body">
            <ul className={`body ${styles.bbsBody}`}>
              {/* list item */}
              {rankingItems.slice(0, showAmount).map((bj: any, index: number) => (
                <BroadcastItem
                  key={bj.i}
                  {...{
                    bj,
                    index,
                    sortBy,
                    mainCategory: getBjCategory(bj.i),
                    hiddenIDs,
                  }}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>

      {showAmount <= rankingItems.length && (
        <div className="bbs-btn-more">
          <button onClick={() => setShowAmount(showAmount + 50)} type="button">
            더보기 &#40;+50위&#41;
          </button>
        </div>
      )}
      {rankingItems.length <= dropDownSize && <div className="more-space"></div>}
    </div>
  )
}
