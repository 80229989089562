import styles from './inquiryWrite.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import useUserStore from '@/stores/user'
import MyPageHeader from '../MyPageHeader'
import MyPageAside from '../MyPageAside'
import { useRef, useState } from 'react'
import Select from 'react-dropdown-select'
import { whitespaceOnly } from '@/helpers/community/BoardFunctions'
import { addInquiry, uploadImage } from '@/api/inquiry'

export default function InquiryWrite() {
  const { user, fetch } = useUserStore()
  const navigate = useNavigate()
  const [category, setCategory] = useState<{ value: string; label: string }[]>([])
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [imgurl, setImgurl] = useState('')
  const options = [
    { value: '계정관련', label: '계정관련' },
    { value: '게시글관련', label: '게시글관련' },
    { value: '포인트관련', label: '포인트관련' },
    { value: '광고문의', label: '광고문의' },
    { value: '기타문의', label: '기타문의' },
  ]
  const thumbnailInput = useRef<HTMLInputElement>(null)

  function handleSubmitInquiry() {
    if (!user) {
      alert('로그인 해주세요')
    } else if (whitespaceOnly(content, true) || whitespaceOnly(title, false) || !category[0]) {
      alert('문의 내용을 작성하여 주십시요.')
    } else if (content.length > 200) {
      alert('문의 내용이 200자 이내인지 확인해주세요')
    } else {
      addInquiry({
        title: title,
        category: category[0].value,
        content: content,
        image: imgurl,
      })
      fetch()
      navigate(`/my/inquiries`)
    }
  }

  async function imageHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]

    const formData = new FormData()
    if (file) formData.append('image', file)

    try {
      const result = (await uploadImage(formData)) as any
      setImgurl(result.url)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <MyPageHeader user={user} fetch={fetch} />

      {user ? (
        <div className="history-wrap">
          <MyPageAside />

          <div className="history-conts">
            <div className={styles.inquiryHeader}>
              <span className={styles.txt}>1:1 문의내역</span>
            </div>
            <div className={styles.inquiryBody}>
              <ul className={styles.infoList}>
                <li className={styles.infoRow}>
                  <span className={styles.label}>문의유형</span>
                  <div className="tab-select-area">
                    <div className={`select-box ${styles.selectBox}`}>
                      <div className="select-area">
                        <Select
                          options={options}
                          values={category}
                          searchBy="value"
                          onChange={(values: { value: string; label: string }[]) => setCategory(values)}
                          dropdownPosition="bottom"
                          searchable={false}
                          placeholder="문의 유형을 선택해 주세요"
                          addPlaceholder="검색"
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className={styles.infoRow}>
                  <span className={styles.label}>제목</span>
                  <input
                    type="text"
                    onChange={e => setTitle(e.target.value)}
                    value={title}
                    className="w-100"
                    placeholder="제목을 입력하여 주세요."
                  ></input>
                </li>
                <li className={styles.infoRow}>
                  <span className={styles.label}>문의 내용</span>
                  <textarea
                    value={content}
                    className={styles.contentBox}
                    onChange={e => setContent(e.target.value)}
                    placeholder="문의 내용을 입력해 주세요 (200자 이내)"
                  ></textarea>
                </li>
                <li className={styles.infoRow}>
                  <span className={styles.label}>사진 첨부</span>
                  {imgurl !== '' ? <img src={imgurl} alt={'image'} className={styles.uploadedImg} /> : <></>}
                  <button
                    className={styles.cameraBtn}
                    onClick={() => {
                      if (thumbnailInput.current) {
                        thumbnailInput.current.click()
                      }
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref={thumbnailInput}
                      onChange={imageHandler}
                      style={{ display: 'none' }}
                    />
                    <img src="/images/contents/ico_camera.svg" className={styles.cameraIcon}></img>
                  </button>
                  <span className={styles.rules}>
                    • 파일명은 영문만 가능하며, 파일당 최대 10MB 의 용량 제한이 있습니다.
                  </span>
                  <span className={styles.rules}>
                    • 가로사이즈가 450&#40;px&#41;을 초과하는 경우 자동으로 450&#40;px&#41;로 조정됩니다.
                  </span>
                  <span className={styles.rules}>• 첨부파일은 최대 1개까지 등록가능합니다.</span>
                </li>
              </ul>
              <div className={styles.bottomMenu}>
                <Link to={`/my/inquiries`} className={styles.cancelBtn}>
                  취소하기
                </Link>
                <button onClick={handleSubmitInquiry} className={styles.listBtn}>
                  제출하기
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
