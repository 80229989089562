import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { fetchCommentListByBoard } from '@/api/community/comment'
import { fetchBoardByID, fetchBoardListByCategory, updateBoard } from '@/api/community/board'
import { LIMIT, COMMENTLIMIT } from './shared/consts'
import { checkAuthor, getHowLongAgo, handlePostReaction } from '@/helpers/community/BoardFunctions'
import ListOfBoards from '@components/pages/community/shared/ListOfBoards.tsx'
import Comments from '@components/pages/community/shared/Comments.tsx'
import BoardSignin from '@components/pages/community/shared/BoardSignin.tsx'
import useUserStore from '@/stores/user'
import useAnnouncementStore from '@/stores/announcement'
import commStyles from './commBoard.module.scss'
import styles from './board.module.scss'
import CategorySelector from './shared/CategorySelector'
import Paging from '@/components/elements/Paging'
import { ModalPopup } from '@/components/popups/ModalPortal'
import { ConfirmModal } from '@/components/popups/Confirm'
import DeleteConfirm from '@/components/popups/DeleteConfirm'
import { ThemeContext } from '@/context/ThemeContext'

export default function Board({ id }: { id: string }) {
  const { theme } = useContext(ThemeContext)
  const userData = useUserStore(state => state.user) ?? { id: 0 }
  const userFetch = useUserStore(state => state.fetch)
  const { announcements } = useAnnouncementStore()
  const [categoryID, setCategoryID] = useState(0)
  const [page, setPage] = useState(1)
  const [commentPage, setCommentPage] = useState(1)
  const lastPage = useRef(1)
  const commentLastPage = useRef(1)
  const queryClient = useQueryClient()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const {
    data: boardData,
    isLoading: boardLoad,
    error: boardError,
    refetch: boardRefetch,
  } = useQuery('board', () => fetchBoardByID(id), {
    onSuccess: (data: any) => {
      let commentCount = data.comments.filter((comment: any) => !comment.parentID).length
      commentLastPage.current = commentCount === 0 ? 1 : Math.ceil(commentCount / COMMENTLIMIT)
      setCategoryID(data.categoryID)
    },
  })
  const {
    data: commentData,
    isLoading: commentLoad,
    error: commentError,
    refetch: commentRefetch,
  } = useQuery(
    ['comment', commentPage],
    () => fetchCommentListByBoard(id, COMMENTLIMIT, commentPage, 'createdAt', 'desc'),
    {
      keepPreviousData: true,
    },
  )
  const {
    data: otherBoardData,
    isLoading: otherBoardLoad,
    error: otherBoardError,
  } = useQuery(
    ['other-board', categoryID, page],
    () => fetchBoardListByCategory(String(categoryID), String(LIMIT), page, 'createdAt', 'desc', ''),
    {
      onSuccess: (data: any) => {
        lastPage.current = data.totalPostsCount === 0 ? 1 : Math.ceil(data.totalPostsCount / LIMIT)
      },
      keepPreviousData: true,
    },
  )
  const navigate = useNavigate()

  useEffect(() => {
    setPage(1)
  }, [categoryID])

  function refresh() {
    queryClient.invalidateQueries({ queryKey: ['board', 'comment'] })
    boardRefetch()
    commentRefetch()
  }

  function handleBoardDelete() {
    updateBoard(id, { title: '', content: '', edited: true, isDeleted: true }).catch(error => console.log(error))
    userFetch()
    navigate(`/community/boards`)
  }

  if (boardLoad || boardError || commentLoad || commentError || otherBoardLoad || otherBoardError) {
    return
  }

  return (
    <div className="contents-area">
      <div className={commStyles.bbsContsArea}>
        {/* contents */}
        <div className={commStyles.contsArea}>
          {boardData.isDeleted ? (
            <div className={styles.bbsViewHeadDeleted}>
              <div className={styles.headTitle}>삭제된 게시글입니다.</div>
            </div>
          ) : (
            <>
              <div className={styles.bbsViewHead}>
                <div className="chip gray natural af">{boardData.category.name}</div>
                <div className={styles.headTitle}>{boardData.title}</div>
                <span className={styles.headName}>{boardData.user.nick}</span>
                <div className={styles.contsBox}>
                  <div className={styles.information}>
                    <div className={styles.headCount}>
                      <span className={commStyles.pipeLine}>{getHowLongAgo(new Date(boardData.createdAt))}</span>
                      {boardData.edited ? <span className={commStyles.pipeLine}>수정됨</span> : <></>}
                      <span className={commStyles.pipeLine}>
                        <img src={`/images/contents/${theme}/ion_chatbox_outline.svg`} alt="댓글" />{' '}
                        {boardData.comments.filter((comment: any) => !comment.isDeleted).length.toLocaleString()}
                      </span>
                      <span>
                        <img src={`/images/contents/${theme}/thumb_thick.svg`} alt="추천" />{' '}
                        {boardData.likes.length.toLocaleString()}
                      </span>
                      {/* <span>비추천 {boardData.dislikes.length}</span> */}
                    </div>
                  </div>

                  <div className={styles.nicknameBox}>
                    {checkAuthor(boardData.authorID, boardData, userData.id) ? (
                      <>
                        <span className={styles.btn}>
                          <button type="button" onClick={() => navigate(`/community/boards/edit/${id}`)}>
                            수정 하기
                          </button>
                        </span>

                        <span className={styles.btn}>
                          <button type="button" onClick={() => setShowDeleteModal(true)}>
                            삭제 하기
                          </button>
                        </span>

                        <span className={styles.btn}>
                          <button
                            type="button"
                            onClick={() => {
                              navigator.clipboard.writeText(`poong.today/community/boards/view/${id}`)
                            }}
                          >
                            링크 복사
                          </button>
                        </span>
                      </>
                    ) : (
                      <span className={styles.btn}>
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(`poong.today/community/boards/view/${id}`)
                          }}
                        >
                          링크 복사
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.bbsViewContents}>
                <div className={`${styles.contents} ql-editor`}>
                  <div dangerouslySetInnerHTML={{ __html: boardData.content }} />
                </div>

                <div className={styles.contentsBtnArea}>
                  <div className={styles.recommend}>
                    {boardData.likes.filter((like: any) => {
                      return like.userID === userData.id
                    }).length === 0 ? (
                      <button
                        type="button"
                        onClick={() => {
                          handlePostReaction(boardData.id, false, userData.id)
                          refresh()
                          if (userFetch) {
                            userFetch()
                          }
                        }}
                        className={styles.btnRecommend}
                      >
                        <span>
                          <img src={`/images/contents/${theme}/thumb_thick.svg`} alt="추천" />
                        </span>
                        {boardData.likes.length.toLocaleString()}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          handlePostReaction(boardData.id, true, userData.id)
                          refresh()
                          if (userFetch) {
                            userFetch()
                          }
                        }}
                        className={styles.btnRecommended}
                      >
                        <span>
                          <img src={`/images/contents/${theme}/thumb_thick.svg`} alt="추천" />
                        </span>
                        {boardData.likes.length.toLocaleString()}
                      </button>
                    )}
                  </div>
                </div>

                <Comments
                  id={id}
                  boardData={boardData}
                  commentData={commentData}
                  userID={userData.id}
                  refresh={refresh}
                  fetch={userFetch ?? null}
                />
              </div>
              <Paging page={commentPage} setPage={setCommentPage} lastPage={commentLastPage.current} />
            </>
          )}
          <CategorySelector categoryID={categoryID} updateCategory={setCategoryID} />

          <ListOfBoards
            boardData={otherBoardData}
            announcementData={announcements.filter(
              (announcement: any) => announcement.categoryID === 0 || announcement.categoryID === categoryID,
            )}
          />

          <Paging page={page} setPage={setPage} lastPage={lastPage.current} />
        </div>
        <div className={commStyles.aside}>
          <BoardSignin showShop={false} showWrite={true} notLoginText="글쓰기는 로그인 후 이용 가능합니다" />
        </div>
      </div>
      <ModalPopup>
        {showDeleteModal && (
          <ConfirmModal
            type="confirm"
            title=""
            onSubmit={() => {
              handleBoardDelete()
              setShowDeleteModal(false)
            }}
            onClose={() => {
              setShowDeleteModal(false)
            }}
          >
            <DeleteConfirm deleting="게시글" />
          </ConfirmModal>
        )}
      </ModalPopup>
    </div>
  )
}
