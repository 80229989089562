import { googleSignin } from '@/api/auth'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function OauthKakao() {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  useEffect(() => {
    if (code) {
      googleSignin({ code })
        .then(() => {
          window.location.href = '/'
        })
        .catch(error => console.log(error))
    }
  }, [code])

  return <div style={{ color: 'var(--pt-grayscale-900)' }}>{code}</div>
}
