import { useQuery } from 'react-query'
import styles from './pointGuide.module.scss'
import { fetchAllEarning } from '@/api/community/earning'

export default function PointGuide() {
  const {
    data: earningData,
    isLoading: earningLoad,
    error: earningError,
  } = useQuery(['earning'], () => fetchAllEarning())

  if (earningLoad || earningError) {
    return
  }

  return (
    <div className={styles.pointGuideArea}>
      <div className={styles.title}>포인트 획득 가이드</div>
      <ul className={styles.guide}>
        <li>
          <div className={styles.subject}>
            {earningData[0].info}
            <span>&#42; 1일 1회 지급</span>
          </div>
          <div className={styles.pointTxt}>+ {earningData[0].points} Point</div>
        </li>
        <li>
          <div className={styles.subject}>
            {earningData[1].info}
            <span>&#42; 1일 최대 {earningData[1].maxPoints}P 지급</span>
          </div>
          <div className={styles.pointTxt}>+ {earningData[1].points} Point</div>
        </li>
        <li>
          <div className={styles.subject}>
            {earningData[2].info}
            <span>&#42; 한 게시글 당 최대 {earningData[2].maxPoints}p 지급</span>
          </div>
          <div className={styles.pointTxt}>+ {earningData[2].points} Point</div>
        </li>
        <li>
          <div className={styles.subject}>
            {earningData[3].info}
            <span>&#42; 한 게시글 당 최대 {earningData[3].maxPoints}p 지급</span>
          </div>
          <div className={styles.pointTxt}>+ {earningData[3].points} Point</div>
        </li>
        <li>
          <div className={styles.subject}>
            {earningData[4].info}
            <span>
              &#42; 추천 받은 횟수로 지급 / 1일 최대 {earningData[4].maxPoints}P <br /> 지급
            </span>
            <span>
              &#42; 게시글 작성자와 추천인이 같은 경우 <br /> 포인트 획득 불가
            </span>
          </div>
          <div className={styles.pointTxt}>+ {earningData[4].points} Point</div>
        </li>
        <li>
          <div className={styles.subject}>
            {earningData[5].info}
            <span>
              &#42; 추천한 횟수로 지급 / 1일 최대 {earningData[5].maxPoints}P <br />
              지급
            </span>
            <span>
              &#42; 게시글 작성자와 추천인이 같은 경우 <br /> 포인트 획득 불가
            </span>
          </div>
          <div className={styles.pointTxt}>+ {earningData[5].points} Point</div>
        </li>
      </ul>
      <div className={styles.notice}>
        <p className={styles.subject}>포인트 획득 유의 사항</p>
        <ul>
          <li>게시글 작성 포인트는 1일 최대 100 Point 지급</li>
          <li>댓글/답글은 한 게시글 당 최대 6 Point 지급</li>
          <li>게시글 작성자와 댓글/답글 작성자가 같은 경우 포인트 획득 불가</li>
          <li>게시글 작성자와 게시글 추천인이 같은 경우 포인트 획득 불가</li>
          <li>게시글 삭제 시 지급된 포인트 차감</li>
        </ul>
      </div>
    </div>
  )
}
