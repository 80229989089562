export function getBroadcastHourWithDonation(hourly: number[]) {
  const startHour = hourly.findIndex(h => !!h)
  let endHour = hourly.length - 1
  for (let i = hourly.length - 1; i >= 0; i--) {
    if (hourly[i]) {
      endHour = i
      break
    }
  }
  return Math.max(endHour - startHour, 0) + 1
}
