import styles from './pointDetails.module.scss'
import { useState } from 'react'
import { ModalPopup } from '../../../popups/ModalPortal'
import { AlertModal } from '../../../popups/Alert'
import useUserStore from '@/stores/user'
import BoardSignin from '@components/pages/community/shared/BoardSignin.tsx'
import PointGuide from '@components/popups/PointGuide'
import { format } from 'date-fns'
import Paging from '@/components/elements/Paging'
import { couponPageLimit } from '../../community/shared/consts'
import MorePoints from '../../pointshop/MorePoints'
import { getImgByCompany } from '@/helpers/community/Point'
import CouponInfo from '@/components/popups/rewards/CouponInfo'
import PointAside from './PointAside'

export default function Coupons() {
  const [page, setPage] = useState(1)
  const [showCoupon, setShowCoupon] = useState(null)
  const user = useUserStore(state => state.user)
  const lastPage = !user || user.coupons.length === 0 ? 1 : Math.ceil(user.coupons.length / couponPageLimit)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="contents-area">
      <div className={styles.titleArea}>
        <h2>쿠폰 보관함</h2>
      </div>

      <PointAside />

      <div className={styles.pointContsArea}>
        <div className={styles.contsArea}>
          <ul className={styles.rewardList}>
            {!user || user.coupons.length === 0 ? (
              <li className={`${styles.ptNull}`}>쿠폰이 없습니다.</li>
            ) : (
              user.coupons
                .sort((c1: any, c2: any) => c2.id - c1.id)
                .slice((page - 1) * couponPageLimit, page * couponPageLimit)
                .map((coupon: any) => (
                  <li className={styles.reward} key={coupon.id}>
                    <img src={getImgByCompany(coupon.reward.company)} alt={'logo'} className={styles.logo}></img>
                    <span className={styles.rewardInfo}>{coupon.reward.name}</span>
                    <span className={styles.date}>발급일 : {format(coupon.startAt, 'yyyy-MM-dd HH:mm:ss')}</span>
                    <button className={styles.infoBtn} onClick={() => setShowCoupon(coupon)}>
                      상세 정보 보기
                    </button>
                  </li>
                ))
            )}
          </ul>
          <Paging page={page} setPage={setPage} lastPage={lastPage} />
        </div>

        <div className={styles.aside}>
          <BoardSignin showShop={true} showWrite={false} notLoginText="쿠폰 보관함은 로그인 후 이용 가능합니다" />
          <button type="button" className={styles.btnGuide} onClick={() => setShowModal(true)}>
            포인트획득 가이드
          </button>
          <MorePoints />
        </div>
      </div>

      <ModalPopup>
        {showModal && (
          <AlertModal type="alert" title="" onClose={setShowModal}>
            <PointGuide />
          </AlertModal>
        )}
        {showCoupon && <CouponInfo onClose={setShowCoupon} coupon={showCoupon} />}
      </ModalPopup>
    </div>
  )
}
