import { useSearchParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'
import { fetchBoardListByCategory } from '@/api/community/board'
import useAnnouncementStore from '@/stores/announcement'
import { useEffect, useRef } from 'react'
import ListOfBoards from './shared/ListOfBoards'
import BoardSignin from '@components/pages/community/shared/BoardSignin.tsx'
import CategorySelector from './shared/CategorySelector'
import { LIMIT } from './shared/consts'
import commStyles from './commBoard.module.scss'
import styles from './boardList.module.scss'
import Paging from '@/components/elements/Paging'
import { ThemeContext } from '@/context/ThemeContext'

export default function BoardList() {
  const { theme } = useContext(ThemeContext)
  const { announcements } = useAnnouncementStore()
  const [categoryID, setCategoryID] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(1)
  const [searchContent, setSearchContent] = useState(searchParams.get('search') ?? '')
  const lastPage = useRef(1)
  const {
    data: boardData,
    isLoading: boardLoad,
    error: boardError,
  } = useQuery(
    ['board-list', categoryID, page],
    () => fetchBoardListByCategory(String(categoryID), LIMIT, page, 'createdAt', 'desc', searchParams.get('search')),
    {
      onSuccess: (data: any) => {
        lastPage.current = data.totalPostsCount === 0 ? 1 : Math.ceil(data.totalPostsCount / LIMIT)
      },
    },
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.get('search') === null) {
      searchParams.set('search', '')
    }
    setSearchParams(searchParams)
    setPage(1)
  }, [categoryID])

  if (boardLoad || boardError) {
    return
  }

  const handleQueryChange = (queryName: string, value: string, refresh: boolean) => {
    searchParams.set(queryName, value)
    setSearchParams(searchParams)
    if (refresh) {
      navigate(0)
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault()
    if (searchContent?.replace(/ /g, '') !== '') {
      setPage(1)
      handleQueryChange('search', searchContent ? searchContent?.trim() : '', true)
    }
    return false
  }

  return (
    <div className="contents-area">
      <div className={`${commStyles.titleArea} ${commStyles.borderNone}`}>
        <h2 className={styles.test}>커뮤니티</h2>
      </div>

      <BoardSignin showShop={false} showWrite={true} notLoginText="글쓰기는 로그인 후 이용 가능합니다" />

      <CategorySelector categoryID={categoryID} updateCategory={setCategoryID} />

      <div className={commStyles.bbsContsArea}>
        <div className={commStyles.contsArea}>
          <div className={`community-area ${styles.communityArea}`}>
            <ListOfBoards
              boardData={boardData}
              announcementData={announcements.filter(
                (announcement: any) => announcement.categoryID === 0 || announcement.categoryID === categoryID,
              )}
            />

            <div className={styles.bbsControl}>
              <div className={styles.bbsSearch}>
                <div className={`${styles.selectSearch}`}></div>
                <Paging page={page} setPage={setPage} lastPage={lastPage.current} />
                <div className={`search-area ${styles.searchBox}`}>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="search"
                      id="inputSearch"
                      name="inputSearch"
                      value={searchContent ? searchContent : ''}
                      onChange={e => setSearchContent(e.target.value)}
                    />
                    <span
                      className={`ico-search ${theme === 'light' ? '' : theme}`}
                      onClick={() => {
                        setPage(1)
                        handleQueryChange('search', searchContent ? searchContent.trim() : '', true)
                      }}
                    ></span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={commStyles.aside}>
          <BoardSignin showShop={false} showWrite={true} notLoginText="글쓰기는 로그인 후 이용 가능합니다" />
        </div>
      </div>
    </div>
  )
}
