import cx from 'classnames'
import useCategoryStore from '@/stores/category'
import styles from '../boardList.module.scss'

export default function CategorySelector({ categoryID, updateCategory }: { categoryID: number; updateCategory: any }) {
  const categories = useCategoryStore(state => state.categories)

  return (
    <div className={styles.bbsSelector}>
      {/* 게시판 카테고리 */}
      <div className="tab-select-area">
        <div className="tab-type-horizon scroll w-100">
          {/* <div>게시판 선택</div> */}
          <ul>
            {categories.map((category: any) => (
              <li key={category.id}>
                <a onClick={() => updateCategory(category.id)} className={cx({ active: categoryID === category.id })}>
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
