import { uploadImage } from '@/api/community/board'

export function returnQuillModule(quillRef: any) {
  return {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'underline', { color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
        ['link', 'image', 'video'],
      ],
      handlers: {
        image: () => imageHandler(quillRef),
      },
    },
  }
}

function imageHandler(quillRef: any) {
  const input = document.createElement('input')

  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.click()

  input.addEventListener('change', async () => {
    const file = input.files?.[0]

    const formData = new FormData()
    if (file) formData.append('image', file)

    try {
      const result = (await uploadImage(formData)) as any
      const img_url = result.url
      const qr = quillRef.current as any
      const editor = qr.getEditor()
      const range = editor.getSelection()
      editor.insertEmbed(range.index, 'image', img_url)
    } catch (error) {
      console.log(error)
    }
  })
}
