import styles from './button.module.scss'

interface iButtonProps {
  size? : string;
  color?: string;
  type? : string;
  width?: string;
  text: string;
}

export default function Button(props : iButtonProps) {
  // const { 
  //   size,
  //   color,
  //   type,
  //   width,
  //   text = '확인'
  // } = props;
  const { 
    size='medium',
    color='primary',
    type='normal',
    width='inline',
    text = '확인'
  } = props;

  // const result = Object.values(props);
  // const newProps = result.map((value: string)=> value);

  // console.log('result', result);
  // const result: string[] = newProps.map(( value: string)=> value !== undefined);

  return (
      <button
        type="button"
        className={`${styles.btn} ${styles[size]} ${styles[color]} ${styles[type]} ${styles[width]}`}
      >
        {text}
      </button>
  )
 
}

// Button.defaultProps = {
//   color: "green",
//   size: "normal"    
// }

// export default function Button( props: { color: string; size:string; } ) {

  // const cssColor = styles[color];
  // const cssSize = styles[size];
  // const cssColor = styles[props.color];
  // const cssSize = styles[props.size];

//   return (
//     <div>
//       <h1 className={cssColor}>props 컬러 테스트</h1>
//       <span className={cssSize}>props 사이즈 테스트</span>
//     </div>
//   )
 
// }

// Button.defaultProps = {
//   color: "green",
//   size: "normal"    
// }
