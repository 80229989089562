export function getTier(pct: number) {
  if (pct <= 0.001) {
    return 1
  } else if (0.001 < pct && pct <= 0.01) {
    return 2
  } else if (0.01 < pct && pct <= 0.05) {
    return 3
  } else if (0.05 < pct && pct <= 0.1) {
    return 4
  } else if (0.1 < pct && pct <= 0.3) {
    return 5
  } else if (0.3 < pct && pct <= 0.4) {
    return 6
  } else if (0.4 < pct && pct <= 0.6) {
    return 7
  } else if (0.6 < pct && pct <= 0.9) {
    return 8
  } else {
    return 9
  }
}

export function getTopPct(tier: number) {
  if (tier === 1) {
    return 0.1
  } else if (tier === 2) {
    return 1
  } else if (tier === 3) {
    return 5
  } else if (tier === 4) {
    return 10
  } else if (tier === 5) {
    return 30
  } else if (tier === 6) {
    return 40
  } else if (tier === 7) {
    return 60
  } else if (tier === 8) {
    return 90
  } else {
    return 100
  }
}
