import styles from './mypage.module.scss'
import { Link } from 'react-router-dom'
import useUserStore from '@/stores/user'
import MyPageHeader from './MyPageHeader'
import MyPageAside from './MyPageAside'

export default function Mypage() {
  const { user, fetch } = useUserStore()

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <MyPageHeader user={user} fetch={fetch} />

      {user ? (
        <div className="history-wrap">
          <MyPageAside />

          <div className="history-conts">
            <div className={styles.userData}>
              <h2>계정 관리하기</h2>
              <div className={styles.contsBox}>
                {/* <dl className={styles.authBox}>
                  <dt>본인인증</dt>
                  <dd>- 마일리지를 사용하시려면 본인인증이 필요합니다.</dd>
                  <dd>
                    - 이름, 휴대폰번호, 생년월일 변경이 필요하신 경우, 본인 인증하기를 완료하시면 자동으로 일괄
                    변경됩니다.
                  </dd>
                  <dd>
                    <Link to="!#" className={styles.btnAuth}>
                      본인 인증하기
                    </Link>
                  </dd>
                </dl> */}

                {/* <div className={styles.userAuthBox}>
                  <dl>
                    <dt>이름</dt>
                    <dd>{user.name}</dd>
                  </dl>
                  <dl>
                    <dt>연락처</dt>
                    <dd>010-9999-9999</dd>
                  </dl>
                  <dl>
                    <dt>생년월일</dt>
                    <dd>1999.09.09</dd>
                  </dl>
                </div> */}
                <dl>
                  <dt>이름</dt>
                  <dd>{user.name}</dd>
                </dl>
                <dl>
                  <dt>이메일</dt>
                  <dd>{user.email}</dd>
                </dl>
                <dl>
                  <dt>아이디</dt>
                  <dd>{user.userid}</dd>
                </dl>
                {/* <dl>
                  <dt>비밀번호</dt>
                  <dd>
                    <button type="button" className={styles.btnNickname}>
                      비밀번호 변경하기
                    </button>
                  </dd>
                </dl> */}
                <dl>
                  <dt>닉네임</dt>
                  <dd>{user.nick}</dd>
                  <dd>
                    <Link to="/my/changeNickname" className={styles.btnNickname}>
                      닉네임 변경하기
                    </Link>
                  </dd>
                </dl>
                {/* <dl>
                  <dt>이벤트 정보</dt>
                  <span className="block">
                    <input type="radio" id="ad1" name="ad" value="1" defaultChecked={true} />
                    <label htmlFor="ad1">수신</label>

                    <input type="radio" id="ad0" name="ad" value="0" />
                    <label htmlFor="ad0">비수신</label>
                  </span>
                </dl> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
