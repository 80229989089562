export function convertToJson(jsonl: string) {
  const lines = jsonl.split('\n')
  return lines
    .map(line => {
      if (line.trim() !== '') {
        try {
          return JSON.parse(line)
        } catch {
          return null
        }
      }
    })
    .filter(Boolean)
}
