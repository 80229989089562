import Select from 'react-dropdown-select'
import { increasingList, PERIOD_TYPE } from '../../pages/consts'
import { startYear, today } from '@/utils/date'
import { useContext } from 'react'
import { ThemeContext } from '@/context/ThemeContext'

export default function MonthSelector({
  date,
  selectedYear,
  selectedMonth,
  dateButtonHandler,
  smaller,
}: {
  date: Date
  selectedYear: { key: string; value: string }[]
  selectedMonth: { key: string; value: string }[]
  dateButtonHandler: any
  smaller: boolean
}) {
  const { theme } = useContext(ThemeContext)
  const months =
    date.getFullYear() === today().getFullYear()
      ? increasingList(0, today().getMonth()).map((m: number) => {
          return { key: String(m), value: String(m + 1).padStart(2, '0') }
        })
      : increasingList(0, 11).map((m: number) => {
          return { key: String(m), value: String(m + 1).padStart(2, '0') }
        })
  const years = increasingList(startYear, today().getFullYear()).map((y: number) => {
    return { key: String(y), value: String(y) }
  })
  return (
    <div className="datepicker-area">
      <div className={smaller ? 'datepicker small-year' : 'datepicker'}>
        <span className="left-arrow" onClick={() => dateButtonHandler(PERIOD_TYPE.YEAR, date.getFullYear() - 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_left_sm.svg`} alt="prev" />
        </span>
        <div className={`form-select`}>
          <Select
            options={years}
            values={selectedYear}
            labelField="value"
            valueField="key"
            onChange={(values: { key: string; value: string }[]) => {
              dateButtonHandler(PERIOD_TYPE.YEAR, parseInt(values[0].key))
            }}
            multi={false}
            dropdownPosition="bottom"
            searchable={false}
          />
        </div>
        <span className="right-arrow" onClick={() => dateButtonHandler(PERIOD_TYPE.YEAR, date.getFullYear() + 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_right_sm.svg`} alt="next" />
        </span>
      </div>
      <div className={smaller ? 'datepicker small-month' : 'datepicker'}>
        <span className="left-arrow" onClick={() => dateButtonHandler(PERIOD_TYPE.MONTH, date.getMonth() - 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_left_sm.svg`} alt="prev" />
        </span>
        <div className={`form-select`}>
          <Select
            options={months}
            values={selectedMonth}
            labelField="value"
            valueField="key"
            onChange={(values: { key: string; value: string }[]) => {
              dateButtonHandler(PERIOD_TYPE.MONTH, parseInt(values[0].key))
            }}
            multi={false}
            dropdownPosition="bottom"
            searchable={false}
          />
        </div>
        <span className="right-arrow" onClick={() => dateButtonHandler(PERIOD_TYPE.MONTH, date.getMonth() + 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_right_sm.svg`} alt="next" />
        </span>
      </div>
    </div>
  )
}
