import { create } from 'zustand'
import { fetchUser } from '@/api/auth.ts'

interface User {
  email: string
  id: number
  name: string
  nick: string
  picture: string
  userid: string
  boards: any
  comments: any
  points: any
  coupons: any
  inquiries: any
}

export default create<{ user: User | null; fetch: any }>(set => ({
  user: null,
  fetch: async () => {
    const user = await fetchUser()
    if (!user?.id) return
    set({ user })
  },
}))
