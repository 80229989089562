import { useNavigate } from 'react-router-dom'
import { useState, useMemo, useRef } from 'react'
import ReactQuill from 'react-quill'
import { addBoard } from '@/api/community/board'
import useUserStore from '@/stores/user'
import { returnQuillModule } from '@/helpers/community/QuillModule'
import { whitespaceOnly } from '@/helpers/community/BoardFunctions'
import commStyles from './commBoard.module.scss'
import styles from './boardWrite.module.scss'

import useCategoryStore from '@/stores/category'

export default function BoardWrite() {
  const user = useUserStore(state => state.user)
  const fetch = useUserStore(state => state.fetch)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [currentCategory, setCurrentCategory] = useState(1)
  const categories = useCategoryStore(state => state.categories)
  const quillRef = useRef(null)
  const navigate = useNavigate()
  const modules = useMemo(() => {
    return returnQuillModule(quillRef)
  }, [])

  function handleUploadBoard() {
    if (!user) {
      alert('로그인 해주세요')
    } else if (whitespaceOnly(content, true) || whitespaceOnly(title, false)) {
      alert('게시판 글을 작성하여 주십시요.')
    } else {
      addBoard({
        categoryID: currentCategory,
        title: title,
        content: content,
      })
      fetch()
      navigate(`/community/boards`)
    }
  }

  return (
    <div className="contents-area" style={{ maxWidth: '800px' }}>
      <div className={commStyles.titleArea}>
        <h2>커뮤니티 글쓰기</h2>
      </div>

      {/* 게시판 카테고리 */}

      <div className="tab-select-area">
        <div className="tab-type-round w-100">
          <div>게시판 선택</div>
          <ul style={{ position: 'relative', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
            {categories.slice(1).map((category: any) =>
              currentCategory == category.id ? (
                <li key={category.id}>
                  <button type="button" onClick={() => setCurrentCategory(category.id)} className="active">
                    {category.name}
                  </button>
                </li>
              ) : (
                <li key={category.id}>
                  <button type="button" onClick={() => setCurrentCategory(category.id)}>
                    {category.name}
                  </button>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>

      <div className={styles.boardWriteWrap}>
        <div className={styles.writeArea}>
          <div>
            <label className="blind">제목</label>
            <input
              type="text"
              onChange={e => setTitle(e.target.value)}
              value={title}
              className="w-100"
              placeholder="제목을 입력하여 주세요."
            />
          </div>

          <div className={styles.writeEditor}>
            <ReactQuill
              ref={quillRef}
              modules={modules}
              placeholder="내용을 입력하여 주세요."
              theme="snow"
              onChange={setContent}
            ></ReactQuill>
          </div>

          <div className={styles.txt}>※ 주의 : 게시물은 텍스트, 이미지를 포함해 10MB 이하입니다.</div>
        </div>

        <div className={`btn-area center ${commStyles.btnArea}`}>
          <button type="button" onClick={() => navigate(`/community/boards`)} className={commStyles.cancel}>
            취소
          </button>
          <button type="button" onClick={handleUploadBoard} className={commStyles.ok}>
            등록하기
          </button>
        </div>

        {/* <BoardPolicy /> */}
      </div>
    </div>
  )
}
