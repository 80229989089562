import { useSearchParams } from 'react-router-dom'
import styles from './results.module.scss'
import { useQuery } from 'react-query'
import { fetchBoardListByCategory } from '@/api/community/board'
import { useState, useRef, useEffect, useMemo } from 'react'
import useAfreecaStore from '@/stores/afreeca'
import useHiddenStore from '@/stores/hidden'
import BroadcastResult from './BroadcastResult'
import CommunityResult from './CommunityResult'
import { RESULT_CATEGORY } from '../consts'
import Paging from '@/components/elements/Paging'
import { bjSmallLimit, bjBigLimit, boardSmallLimit, boardBigLimit } from './consts'
import { getBjListMonth } from '@/api/bjpt'
import { today } from '@/utils/date'

export default function SearchResults({ isMobile }: { isMobile: boolean }) {
  const bjList = useAfreecaStore(state => state.bjList)
  const { hiddenBjs } = useHiddenStore()
  const [searchParams, _] = useSearchParams()
  const [boardPage, setboardPage] = useState(1)
  const [bjPage, setbjPage] = useState(1)
  const [boardPageLimit, setBoardPageLimit] = useState(boardSmallLimit)
  const [bjPageLimit, setBjPageLimit] = useState(isMobile ? bjSmallLimit : bjBigLimit)
  const boardLastPage = useRef(1)
  const bjLastPage = useRef(1)

  const [resultCategory, setResultCategory] = useState<RESULT_CATEGORY>(RESULT_CATEGORY.ALL) // a: 전체, b: 방송, c: 커뮤니티

  const bjData = useMemo(() => {
    const search = searchParams.get('search')
    const filtered = bjList.filter((bj: any) => bj.n.includes(search) || bj.i?.includes(search))
    bjLastPage.current = filtered.length === 0 ? 1 : Math.ceil(filtered.length / bjPageLimit)
    return filtered
  }, [searchParams.get('search'), bjPageLimit])

  const {
    data: boardData,
    isLoading: boardLoad,
    error: boardError,
  } = useQuery(
    ['board-list', searchParams.get('search'), boardPageLimit],
    () => fetchBoardListByCategory('0', null, 1, 'createdAt', 'desc', searchParams.get('search')),
    {
      onSuccess: (data: any) => {
        boardLastPage.current = data.boards.length === 0 ? 1 : Math.ceil(data.boards.length / boardPageLimit)
      },
      keepPreviousData: true,
    },
  )

  const {
    data: currentMonthBjStats,
    isLoading: currentMonthBjStatsLoading,
    isError: currentMonthBjStatsError,
  } = useQuery(['currentMonthBjStats'], () => {
    const currentDate = today()
    return getBjListMonth(currentDate.getFullYear(), currentDate.getMonth() + 1)
  })

  useEffect(() => {
    setbjPage(1)
    setboardPage(1)
    if (resultCategory === RESULT_CATEGORY.COMMUNITY) {
      setBoardPageLimit(boardBigLimit)
    } else {
      setBoardPageLimit(boardSmallLimit)
    }
    if (resultCategory === RESULT_CATEGORY.ALL && isMobile) {
      setBjPageLimit(bjSmallLimit)
    } else {
      setBjPageLimit(bjBigLimit)
    }
  }, [searchParams.get('search'), resultCategory])

  if (boardLoad || boardError || currentMonthBjStatsLoading || currentMonthBjStatsError) {
    return
  }

  return (
    <div className="contents-area">
      {/* 검색 탭 */}
      <div className={styles.tabArea}>
        <div className={styles.title}>
          <h2>
            <span>{searchParams.get('search')}</span> 검색 결과
          </h2>
        </div>
        <div className="tab-type-horizon">
          <ul>
            <li>
              <a
                onClick={() => {
                  setResultCategory(RESULT_CATEGORY.ALL)
                }}
                className={resultCategory === RESULT_CATEGORY.ALL ? 'active' : ''}
              >
                전체
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setResultCategory(RESULT_CATEGORY.BJ)
                }}
                className={resultCategory === RESULT_CATEGORY.BJ ? 'active' : ''}
              >
                방송
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setResultCategory(RESULT_CATEGORY.COMMUNITY)
                }}
                className={resultCategory === RESULT_CATEGORY.COMMUNITY ? 'active' : ''}
              >
                커뮤니티
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* 검색 결과 > 전체 결과 */}
      <div className={styles.resultHead}>
        <div className={styles.resultCnt}>
          <span>총 {(bjData.length + boardData.boards.length).toLocaleString()}</span>
          <p>개의 검색 결과가 있습니다.</p>
        </div>
      </div>

      {/* 검색 결과 > 방송 */}
      {resultCategory !== RESULT_CATEGORY.COMMUNITY ? (
        <div className={styles.resultBroadcast}>
          <BroadcastResult
            bjData={
              resultCategory === RESULT_CATEGORY.BJ && isMobile
                ? bjData.slice(0, bjPageLimit * bjPage)
                : bjData.slice(bjPageLimit * (bjPage - 1), bjPageLimit * bjPage)
            }
            total={bjData.length}
            monthlyStat={currentMonthBjStats}
            hiddenIDs={hiddenBjs}
          />
          {resultCategory === RESULT_CATEGORY.BJ && isMobile && bjPage < bjLastPage.current && (
            <div className="bbs-btn-more">
              <button
                type="button"
                onClick={() => {
                  setbjPage(bjPage + 1)
                }}
              >
                더보기 &#40;+6&#41;
              </button>
            </div>
          )}
          {!(resultCategory === RESULT_CATEGORY.BJ && isMobile) && (
            <Paging page={bjPage} setPage={setbjPage} lastPage={bjLastPage.current} />
          )}
        </div>
      ) : (
        <></>
      )}

      {/* 검색 결과 > 커뮤니티 */}
      {resultCategory !== RESULT_CATEGORY.BJ ? (
        <div className={styles.resultCommunity}>
          <CommunityResult
            boardData={
              resultCategory === RESULT_CATEGORY.COMMUNITY && isMobile
                ? boardData.boards.slice(0, boardPageLimit * boardPage)
                : boardData.boards.slice(boardPageLimit * (boardPage - 1), boardPageLimit * boardPage)
            }
            total={boardData.boards.length}
          />
          {resultCategory === RESULT_CATEGORY.COMMUNITY && isMobile && boardPage < boardLastPage.current && (
            <div className="bbs-btn-more">
              <button
                type="button"
                onClick={() => {
                  setboardPage(boardPage + 1)
                }}
              >
                더보기 &#40;+15&#41;
              </button>
            </div>
          )}
          {!(resultCategory === RESULT_CATEGORY.COMMUNITY && isMobile) && (
            <Paging page={boardPage} setPage={setboardPage} lastPage={boardLastPage.current} />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
