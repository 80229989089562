import Select from 'react-dropdown-select'
import { increasingList, PERIOD_TYPE } from '../../pages/consts'
import { startYear, today } from '@/utils/date'
import { useContext } from 'react'
import { ThemeContext } from '@/context/ThemeContext'

export default function YearSelector({
  date,
  selectedYear,
  dateButtonHandler,
}: {
  date: Date
  selectedYear: { key: string; value: string }[]
  dateButtonHandler: any
}) {
  const { theme } = useContext(ThemeContext)
  const years = increasingList(startYear, today().getFullYear()).map((y: number) => {
    return { key: String(y), value: String(y) }
  })
  return (
    <div className="datepicker-area">
      <div className={`datepicker `}>
        <span onClick={() => dateButtonHandler(PERIOD_TYPE.YEAR, date.getFullYear() - 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_left_sm.svg`} alt="prev" />
        </span>
        <div className={`form-select`}>
          <Select
            options={years}
            values={selectedYear}
            labelField="value"
            valueField="key"
            onChange={(values: { key: string; value: string }[]) => {
              dateButtonHandler(PERIOD_TYPE.YEAR, parseInt(values[0].key))
            }}
            multi={false}
            dropdownPosition="bottom"
            searchable={false}
          />
        </div>
        <span onClick={() => dateButtonHandler(PERIOD_TYPE.YEAR, date.getFullYear() + 1)}>
          <img src={`/images/contents/${theme}/ico_arrow_right_sm.svg`} alt="next" />
        </span>
      </div>
    </div>
  )
}
