import React, { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import useUserStore from '@/stores/user'
import useAfreecaStore from '@/stores/afreeca'
import useCategoryStore from '@/stores/category'
import useBannerStore from '@/stores/banner'
import useHiddenStore from '@/stores/hidden'
import useAnnouncementStore from '@/stores/announcement'
import Header from './components/layout/header/Header'
import Footer from './components/layout/footer/Footer'
import { ThemeContext } from './context/ThemeContext'
import { calculateSettingAsThemeString, Theme } from './helpers/theme'

const Layout: React.FC = () => {
  const fetchUser = useUserStore(state => state.fetch)
  const fetchAfreeca = useAfreecaStore(state => state.fetch)
  const fetchCategory = useCategoryStore(state => state.fetch)
  const fetchBanner = useBannerStore(state => state.fetch)
  const fetchHidden = useHiddenStore(state => state.fetch)
  const fetchAnnouncement = useAnnouncementStore(state => state.fetch)
  const isBaseAfreecaLoaded = useAfreecaStore(state => state.initialized)
  const [theme, setTheme] = useState<Theme>('light')

  useEffect(() => {
    fetchUser()
    fetchAfreeca()
    fetchCategory()
    fetchBanner()
    fetchHidden()
    fetchAnnouncement()

    const currentThemeSetting = calculateSettingAsThemeString(
      localStorage.getItem('theme'),
      window.matchMedia('(prefers-color-scheme: dark)'),
    )
    document.querySelector('html')?.setAttribute('data-theme', currentThemeSetting)
    setTheme(currentThemeSetting)

    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('f3a8caa20c0d589cfaa74a908bb52bda')
    }
  }, [])

  return (
    <Suspense fallback={'loading...'}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <Header />
        <div id="container">
          <div id="contents">{isBaseAfreecaLoaded ? <Outlet /> : <div />}</div>
        </div>
        <Footer />
      </ThemeContext.Provider>
    </Suspense>
  )
}

export default Layout
