import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { addChannelViewLog } from '@/api/statistics'
import BroadcastView from '@components/pages/broadcast/BroadcastView'

export default function BroadcastViewPage() {
  const { id } = useParams() as { id: string }
  useEffect(() => {
    addChannelViewLog(id)
  }, [id])

  return <BroadcastView id={id} />
}
