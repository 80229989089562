import { create } from 'zustand'
import { fetchAllBanners } from '@/api/banner'

export default create<{ banners: any; fetch: any }>(set => ({
  banners: [],
  fetch: async () => {
    const banners = await fetchAllBanners()
    set({ banners })
  },
}))
