import v2 from '../v2'

export async function fetchCommentListByBoard(boardID: string, limit: any, page: any, sortBy: any, orderBy: any) {
  return v2
    .get('/api/community/comments/boards/' + boardID, {
      params: { limit: limit, page: page, sortBy: sortBy, orderBy: orderBy },
    })
    .then(resp => resp.data)
}

export async function addComment(params: { boardID: number; parentID: number | null; content: string }) {
  return v2.post('/api/community/comments', params, { withCredentials: true }).then(resp => resp.data)
}

export async function updateComment(
  id: string,
  params: {
    boardID: number
    content: string
    edited: boolean
    isDeleted: boolean
  },
) {
  return v2.patch('/api/community/comments/' + id, params, { withCredentials: true }).then(resp => resp.data)
}
