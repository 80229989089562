import { DATE_TEMPLATE } from '../../pages/consts'

export default function DateTabItem({
  value = DATE_TEMPLATE.TODAY,
  selectedDate = DATE_TEMPLATE.TODAY,
  text = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_v: DATE_TEMPLATE) => {},
}) {
  return (
    <li>
      <a
        onClick={e => {
          e.preventDefault()
          onChange(value)
        }}
        className={value === selectedDate ? 'active' : ''}
      >
        {text}
      </a>
    </li>
  )
}
