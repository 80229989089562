import styles from './history.module.scss'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useQuery } from 'react-query'
import useAfreecaStore from '@/stores/afreeca'
import useHiddenStore from '@/stores/hidden'
import { getBigDonorDay, getBigDonorMonth } from '@/api/poongtoday'
import HistoryTable from '@components/pages/bigDonor/HistoryTable.tsx'
import { DATA_COLUMN, PERIOD_TYPE } from '../consts'
import PeriodTabItem from '@/components/elements/tabs/PeriodTabItem'
import { today, startYear } from '@/utils/date.ts'
import DateSelector from '@/components/elements/selectors/DateSelector'
import MonthSelector from '@/components/elements/selectors/MonthSelector'
import CategorySelector from '@/components/elements/selectors/CategorySelector'

export default function BigDonorHistory() {
  const { categories, liveChannels } = useAfreecaStore()
  const { hiddenBjs, hiddenDonors } = useHiddenStore()
  const [period, setPeriod] = useState<PERIOD_TYPE>(PERIOD_TYPE.MONTH)
  const [listDate, setListDate] = useState(today())
  const [sortBy, setSortBy] = useState<DATA_COLUMN>(DATA_COLUMN.DONATION)
  const [search, setSearch] = useState('')
  const [selectedCategories, setSelectedCategories] = useState<{ key: string; value: string }[]>([])
  const [selectedMonth, setSelectedMonth] = useState<{ key: string; value: string }[]>([
    { key: String(listDate.getMonth()), value: String(listDate.getMonth() + 1).padStart(2, '0') },
  ])
  const [selectedYear, setSelectedYear] = useState<{ key: string; value: string }[]>([
    { key: String(listDate.getFullYear()), value: String(listDate.getFullYear()) },
  ])

  const {
    data: bigDonorList,
    isLoading: bigDonorListLoading,
    isError: bigDonorListError,
  } = useQuery(['bigDonorList', period, listDate], () => {
    if (period === PERIOD_TYPE.DAY) {
      return getBigDonorDay(listDate.getFullYear(), listDate.getMonth() + 1, listDate.getDate())
    } else {
      return getBigDonorMonth(listDate.getFullYear(), listDate.getMonth() + 1)
    }
  })

  if (bigDonorListError) {
    return
  }

  function handleChangePeriod(period: PERIOD_TYPE) {
    setPeriod(period)
    setListDate(today())
    setSortBy(DATA_COLUMN.DONATION)
    setSearch('')
    setSelectedCategories([])
    setSelectedMonth([{ key: String(today().getMonth()), value: String(today().getMonth() + 1).padStart(2, '0') }])
    setSelectedYear([{ key: String(today().getFullYear()), value: String(today().getFullYear()) }])
  }

  function dateButtonHandler(periodToAdd: PERIOD_TYPE, change: number) {
    const newDate = new Date(listDate)
    if (periodToAdd === PERIOD_TYPE.DAY) {
      newDate.setDate(change)
    } else if (periodToAdd === PERIOD_TYPE.MONTH) {
      newDate.setMonth(change)
    } else if (periodToAdd === PERIOD_TYPE.YEAR) {
      newDate.setFullYear(change)
    }
    if (startYear <= newDate.getFullYear() && newDate <= today()) {
      setListDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
      setSearch('')
    } else if (newDate.getFullYear() === today().getFullYear() && newDate > today()) {
      newDate.setMonth(today().getMonth())
      newDate.setDate(today().getDate())
      setListDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
      setSearch('')
    }
  }

  const isLoading = bigDonorListLoading
  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <div className={styles.tabArea}>
        <div className={styles.title}>
          <h2>큰손 랭킹</h2>
          {/* <span>아프리카TV와 치지직을 다양한 기준으로 분석해 봤어요</span> */}
        </div>
        <div className="tab-type-horizon">
          <ul>
            <li>
              <Link to="/rankings/donor">큰손 랭킹</Link>
            </li>
            <li>
              <Link to="/rankings/donor/history" className="active">
                히스토리
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="history-wrap">
        <div className="history-aside">
          {/* 기간 선택 */}
          <div className="tab-select-area">
            <div className="tab-type-round w-100">
              <div>기간 선택</div>
              <ul>
                <PeriodTabItem
                  text={'일별 보기'}
                  selectedPeriod={period}
                  value={PERIOD_TYPE.DAY}
                  onChange={handleChangePeriod}
                />
                <PeriodTabItem
                  text={'월별 보기'}
                  selectedPeriod={period}
                  value={PERIOD_TYPE.MONTH}
                  onChange={handleChangePeriod}
                />
                {/* <li>
                  <a onClick={() => setPeriod('Y')} className={period === 'Y' ? 'active' : ''}>
                    연별 보기
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="history-conts">
          {period === PERIOD_TYPE.DAY ? (
            <DateSelector
              date={listDate}
              setDate={setListDate}
              setSearch={setSearch}
              dateButtonHandler={dateButtonHandler}
            />
          ) : (
            <MonthSelector
              date={listDate}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              dateButtonHandler={dateButtonHandler}
              smaller={false}
            />
          )}

          {/* 정렬 선택 */}
          <div className="tab-select-area bg">
            <div className="select-tab">
              <h2>카테고리 선택</h2>
              {period === PERIOD_TYPE.MONTH ? (
                <CategorySelector
                  categories={categories}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="search-box">
              <div className="search-area">
                <input
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                  type="search"
                  id="donorSearch"
                  name="donorSearch"
                  placeholder="큰손검색"
                />
                <span className="ico-search"></span>
              </div>
            </div>
          </div>
          {!isLoading && bigDonorList ? (
            <HistoryTable
              {...{
                bigDonorList,
                categories,
                liveChannels,
                period,
                sortBy,
                search,
                setSortBy,
                selectedCategories,
                hiddenDonors,
                hiddenBjs,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
