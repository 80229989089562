import { kakaoSignin } from '@/api/auth'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function OauthKakao() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('code')

  useEffect(() => {
    if (token) {
      kakaoSignin({ token })
        .then(() => {
          window.location.href = '/'
        })
        .catch(error => console.log(error))
    }
  }, [token])

  return <div style={{ color: 'var(--pt-grayscale-900)' }}>{token}</div>
}
