import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styles from './mobile.module.scss'
import { useSearchParams } from 'react-router-dom'
import SearchResults from './results'
import { Link } from 'react-router-dom'
import useAfreecaStore from '@/stores/afreeca'
import { getTopViewChannels } from '@/api/statistics'
import { useQuery } from 'react-query'
import { ThemeContext } from '@/context/ThemeContext'

export default function SearchMobile({
  pastSearches,
  setPastSearches,
}: {
  pastSearches: string[]
  setPastSearches: any
}) {
  const { theme } = useContext(ThemeContext)
  const { bjList } = useAfreecaStore()
  const inputRef = useRef<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [searched, setSearched] = useState(false)
  const { data: topChannels } = useQuery(['topViewChannels'], () => getTopViewChannels())
  const channels = useMemo(() => {
    if (!topChannels) return []
    return topChannels.map((channel: any) => {
      const foundBj = bjList.find((bj: any) => bj.i === channel.channelId)
      return {
        id: channel.channelId,
        name: foundBj?.n ?? channel.channelId,
      }
    })
  }, [topChannels, bjList])

  useEffect(() => {
    const handleFocus = () => setSearched(false)

    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.addEventListener('focus', handleFocus)
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('focus', handleFocus)
      }
    }
  }, [])

  function handleSubmit(e: any, givenSearch?: string) {
    e.preventDefault()
    const toSearch = givenSearch ? givenSearch.trim() : search.trim()
    if (toSearch.replace(/ /g, '') !== '') {
      const index = pastSearches.indexOf(toSearch)
      if (index === -1) {
        pastSearches.push(toSearch)
      }
      searchParams.set('search', toSearch)
      setSearchParams(searchParams)
      setSearch(toSearch)
      setSearched(true)
    }
    return false
  }

  return (
    <div className="contents-area">
      <div className={styles.searchHead}>
        <span className={styles.prev}>
          <Link to="/" type="button">
            <img src={`/images/contents/${theme}/ico_arrow_left.svg`} alt="prev" />
          </Link>
        </span>
        <form className={`search-area ${styles.search}`} onSubmit={handleSubmit}>
          <input
            type="search"
            id="search"
            name="search"
            ref={inputRef}
            placeholder="통합검색"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <span
            onClick={handleSubmit}
            className={`ico-search ${theme === 'light' ? '' : theme} ${styles.icoSearch}`}
          ></span>
        </form>
      </div>

      {searched ? (
        <SearchResults isMobile={true} />
      ) : (
        <div className={styles.mobileSearchArea}>
          <div className={styles.recentSearches}>
            <div className={styles.titleBox}>
              <p className={styles.title}>최근 검색어</p>
              <span>
                <button type="button" onClick={() => setPastSearches([])}>
                  전체 삭제
                </button>
              </span>
            </div>

            <div className={styles.recentSearchesList}>
              <ul>
                {pastSearches.map((search: string, index: number) => (
                  <li key={index}>
                    <button type="button">
                      <p onClick={e => handleSubmit(e, search)}>{search}</p>
                      <i
                        className={styles.times}
                        onClick={() => {
                          const index = pastSearches.indexOf(search)
                          if (index !== -1) {
                            let newList = [...pastSearches]
                            newList.splice(index, 1)
                            setPastSearches(newList)
                          }
                        }}
                      >
                        &times;
                      </i>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={styles.popularSearches}>
            <div className={styles.titleBox}>
              <p className={styles.title}>인기 방송</p>
            </div>
            <div className={styles.popularSearchesList}>
              <ul>
                {channels.map((channel: any, i: number) => {
                  return (
                    <li key={channel.id}>
                      <span>{i + 1}</span>
                      <button
                        onClick={e => {
                          handleSubmit(e, channel.name)
                        }}
                        type="button"
                      >
                        {channel.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
