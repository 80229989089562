import { createLike, deleteLike } from '@/api/community/reaction'

export function checkAuthor(authorID: any, boardData: any, userID: number) {
  return boardData.category.managerID === userID || authorID === userID
}

export function handlePostReaction(id: number, isAlreadyLiked: boolean, userID: number) {
  if (userID === 0) {
    alert('로그인 해주세요')
  } else {
    deleteLike(id)
    if (!isAlreadyLiked) {
      createLike({ boardID: id })
    }
  }
}

export function whitespaceOnly(str: any, isHtml: boolean) {
  let nowhitespace = ''
  if (isHtml) {
    nowhitespace = str
      .replaceAll(/<[^>]*>?/gm, '')
      .replaceAll(' ', '')
      .replaceAll('\n', '')
  } else {
    nowhitespace = str.replaceAll(' ', '').replaceAll('\n', '')
  }
  return nowhitespace === ''
}

export function getHowLongAgo(createdAt: Date) {
  const now = new Date()
  const timePast = Math.abs(now.getTime() - createdAt.getTime())

  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  const day = 24 * hour
  const month = 31 * day
  const year = 365 * day

  if (timePast > year) {
    return `${Math.floor(timePast / year)}년 전`
  } else if (timePast > month) {
    return `${Math.floor(timePast / month)}달 전`
  } else if (timePast > day) {
    return `${Math.floor(timePast / day)}일 전`
  } else if (timePast > hour) {
    return `${Math.floor(timePast / hour)}시간 전`
  } else if (timePast > minute) {
    return `${Math.floor(timePast / minute)}분 전`
  } else if (timePast > second) {
    return `${Math.floor(timePast / second)}초 전`
  } else {
    return '방금 전'
  }
}
