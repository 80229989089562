import useUserStore from '@/stores/user'
import { useQuery } from 'react-query'
import { getUserComments } from '@/api/user'
import MyPageHeader from '../../mypage/MyPageHeader'
import { useRef, useState } from 'react'
import MyCommunityAside from './MyCommunityAside'
import { format } from 'date-fns'
import Paging from '@/components/elements/Paging'
import { LIMIT } from '../shared/consts'
import { Link } from 'react-router-dom'

export default function MyComment() {
  const [page, setPage] = useState(1)
  const { user, fetch } = useUserStore()
  const { data: comments, isLoading: commentsLoading } = useQuery(['userComments'], () => getUserComments())

  const lastPage = useRef(!user || !comments?.length ? 1 : Math.ceil(comments?.length / LIMIT))

  if (commentsLoading) {
    return null
  }

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <MyPageHeader user={user} fetch={fetch} />

      {user ? (
        <div className="history-wrap">
          <MyCommunityAside />

          <div className="history-conts">
            <div className="community-area" style={{ marginTop: '0' }}>
              <div className="community-box">
                <div className="main-header">
                  <span className="bolded-title">{comments?.length?.toLocaleString() ?? 0}</span>
                  <span className="title">개의 내역이 있습니다.</span>
                </div>
                <div className={`community-head`}>
                  <span>내용</span>
                  <span>닉네임</span>
                  <span>작성일</span>
                  <span>답글수</span>
                </div>
                <div className={`community-body`}>
                  <ul className="body">
                    {!comments?.length ? (
                      <li className="row list-null">댓글이 없습니다.</li>
                    ) : (
                      comments
                        .sort((c1: any, c2: any) => c2.id - c1.id)
                        .slice((page - 1) * LIMIT, page * LIMIT)
                        .map((comment: any) => (
                          <li className="row" key={comment.id}>
                            <div className="subject">
                              <div className="chip gray sm natural af">{comment.board.category.name}</div>
                              {/* <div className="hidden">{board.id}</div> */}
                              <div className="post">
                                <Link to={`/community/boards/view/${comment.board.id}`}>
                                  <span className="title">{comment.board.title}</span>
                                  <img src="/images/contents/ico_enter.png" alt={'enter'} className="enter-icon"></img>
                                  <span className="content">{comment.content}</span>
                                  <span className="reply-cnt">
                                    {'[' + comment.replies.filter((reply: any) => !reply.isDeleted)?.length + ']'}
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <span className="nickname">{user.nick}</span>
                            <span className="date-noline">
                              {format(new Date(comment.createdAt), 'yyyy.MM.dd HH:mm')}
                            </span>
                            <span className="reply">
                              {comment.replies.filter((reply: any) => !reply.isDeleted).length.toLocaleString()}
                            </span>
                          </li>
                        ))
                    )}
                  </ul>
                </div>
              </div>
              <Paging page={page} setPage={setPage} lastPage={lastPage.current} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
