import { create } from 'zustand'
import { fetchCategoryList } from '@/api/community/category'

export default create<{ categories: any; fetch: any }>(set => ({
  categories: [],
  fetch: async () => {
    const categories = await fetchCategoryList()
    set({ categories })
  },
}))
