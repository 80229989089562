import { Link } from 'react-router-dom'
import { ReactNode } from 'react'
import { generateRandomString } from '@/helpers/hidden'

function HiddenItem({ children }: { children: ReactNode }) {
  return (
    <div className="subject">
      <Link to={`https://www.afreecatv.com`} target="_blank" rel="noopener noreferrer" className="post">
        <span className="nickname">
          <span className="thumnail">
            <img src={`https://profile.img.afreecatv.com/LOGO/sample.jpg`} alt="thumn" />
          </span>
          <span className="nick">{generateRandomString(10)}</span>
        </span>
        {children}
      </Link>
    </div>
  )
}

export default function DonorListItem({ children, donorData }: { children?: ReactNode; donorData: any }) {
  if (!donorData) {
    return <HiddenItem>{children}</HiddenItem>
  }
  return (
    <div className="subject">
      <Link to={`https://www.afreecatv.com/${donorData.i}`} target="_blank" rel="noopener noreferrer" className="post">
        <span className="nickname">
          <span className="thumnail">
            <img
              src={`https://profile.img.afreecatv.com/LOGO/${donorData.i.substring(0, 2)}/${donorData.i}/${donorData.i}.jpg`}
              alt="thumn"
            />
          </span>
          <span className="nick">
            {donorData.n}
            {/* {donorData.is_afreeca ? (
              <span className="ico af">
                <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
              </span>
            ) : (
              <></>
            )}
            {donorData.is_chzzk ? (
              <span className="ico ch">
                <img src="/images/contents/ico_logo_chzzk.svg" alt="chzzk" />
              </span>
            ) : (
              <></>
            )} */}
          </span>
        </span>
        {children}
      </Link>
    </div>
  )
}
