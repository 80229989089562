import styles from './inquiry.module.scss'
import { Link, useParams } from 'react-router-dom'
import useUserStore from '@/stores/user'
import MyPageHeader from '../MyPageHeader'
import MyPageAside from '../MyPageAside'
import { format } from 'date-fns'

export default function Inquiry() {
  const { id } = useParams() as { id: string }
  const { user, fetch } = useUserStore()
  const inquiry = user?.inquiries.find((inquiry: any) => inquiry.id === parseInt(id)) ?? null

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <MyPageHeader user={user} fetch={fetch} />

      {inquiry ? (
        <div className="history-wrap">
          <MyPageAside />

          <div className="history-conts">
            <div className={styles.inquiryHeader}>
              <span className={styles.txt}>1:1 문의내역</span>
            </div>
            <div className={styles.inquiryBody}>
              <ul className={styles.infoList}>
                <li className={styles.infoRow}>
                  <span className={styles.label}>문의유형</span>
                  <span className={`${styles.content} ${styles.small}`}>{inquiry.category}</span>
                  <span className={styles.date}>{format(inquiry.createdAt, 'yyyy-MM-dd HH:mm:ss')}</span>
                </li>
                <li className={styles.infoRow}>
                  <span className={styles.label}>제목</span>
                  <span className={styles.content}>{inquiry.title}</span>
                </li>
                <li className={styles.infoRow}>
                  <span className={styles.label}>내용</span>
                  <span className={styles.content}>{inquiry.content}</span>
                </li>
                {inquiry.image && inquiry.image !== '' ? (
                  <li className={styles.infoRow}>
                    <span className={styles.label}>첨부한 사진</span>
                    {inquiry.image !== '' ? (
                      <img src={inquiry.image} alt={'image'} className={styles.uploadedImg} />
                    ) : (
                      <></>
                    )}
                  </li>
                ) : (
                  <></>
                )}
                <li className={styles.infoRow}>
                  <span className={styles.label}>
                    {inquiry.status === '답변 완료' && <div className="chip blue natural">답변 완료</div>}
                    {inquiry.status === '답변 불가' && <div className="chip red natural">답변 불가</div>}
                    {inquiry.status === '접수 완료' && <div className="chip green natural">접수 완료</div>}
                  </span>
                  <span className={styles.content}>{inquiry.reply ?? ''}</span>
                </li>
              </ul>
              <div className={styles.bottomMenu}>
                <Link to={`/my/inquiries`} className={styles.listBtn}>
                  목록보기
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
