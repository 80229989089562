import styles from './bigDonor.module.scss'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useQuery } from 'react-query'
import useAfreecaStore from '@/stores/afreeca'
import useHiddenStore from '@/stores/hidden'
import { getBigDonorDay, getBigDonorMonth } from '@/api/poongtoday'
import BigDonorTable from '@components/pages/bigDonor/BigDonorTable.tsx'
import { DATA_COLUMN, DATE_TEMPLATE, PERIOD_TYPE } from '../consts'
import DateTabItem from '@/components/elements/tabs/DateTabItem'
import { lastMonth, today, yesterday } from '@/utils/date.ts'
import CategorySelector from '@/components/elements/selectors/CategorySelector'

export default function Platform() {
  const { categories, liveChannels } = useAfreecaStore()
  const { hiddenBjs, hiddenDonors } = useHiddenStore()
  const [period, setPeriod] = useState<PERIOD_TYPE>(PERIOD_TYPE.MONTH)
  const [date, setDate] = useState<DATE_TEMPLATE>(DATE_TEMPLATE.THIS_MONTH)
  const [listDate, setListDate] = useState(today())
  const [sortBy, setSortBy] = useState<DATA_COLUMN>(DATA_COLUMN.DONATION)
  const [search, setSearch] = useState('')
  const [selectedCategories, setSelectedCategories] = useState<{ key: string; value: string }[]>([])

  const {
    data: bigDonorList,
    isLoading: bigDonorListLoading,
    isError: bigDonorListError,
  } = useQuery(['bigDonorList', date], () => {
    if (period === PERIOD_TYPE.DAY) {
      return getBigDonorDay(listDate.getFullYear(), listDate.getMonth() + 1, listDate.getDate())
    } else {
      return getBigDonorMonth(listDate.getFullYear(), listDate.getMonth() + 1)
    }
  })

  if (bigDonorListError) {
    return
  }
  const isLoading = bigDonorListLoading

  function handleChangeDate(date: DATE_TEMPLATE) {
    if ([DATE_TEMPLATE.TODAY, DATE_TEMPLATE.YESTERDAY].includes(date)) {
      setPeriod(PERIOD_TYPE.DAY)
    } else {
      setPeriod(PERIOD_TYPE.MONTH)
    }
    setDate(date)

    if ([DATE_TEMPLATE.TODAY, DATE_TEMPLATE.THIS_MONTH].includes(date)) {
      setListDate(today())
    } else if (date === DATE_TEMPLATE.YESTERDAY) {
      setListDate(yesterday())
    } else if (date === DATE_TEMPLATE.LAST_MONTH) {
      setListDate(lastMonth())
    }

    setSortBy(DATA_COLUMN.DONATION)
    setSearch('')
    setSelectedCategories([])
  }

  return (
    <div className="contents-area">
      {/* 타이틀 탭 */}
      <div className={styles.tabArea}>
        <div className={styles.title}>
          <h2>큰손 랭킹</h2>
          {/* <span>아프리카TV와 치지직을 다양한 기준으로 분석해 봤어요</span> */}
        </div>
        <div className="tab-type-horizon">
          <ul>
            <li>
              <Link to="/rankings/donor" className="active">
                큰손 랭킹
              </Link>
            </li>
            <li>
              <Link to="/rankings/donor/history">히스토리</Link>
            </li>
          </ul>
        </div>
      </div>

      {/* 기간 선택 */}
      <div className="tab-select-area scroll">
        <div className="tab-type-round">
          <div>기간 선택</div>
          <ul>
            <DateTabItem text={'오늘'} selectedDate={date} value={DATE_TEMPLATE.TODAY} onChange={handleChangeDate} />
            <DateTabItem
              text={'어제'}
              selectedDate={date}
              value={DATE_TEMPLATE.YESTERDAY}
              onChange={handleChangeDate}
            />
            <DateTabItem
              text={'이번 달'}
              selectedDate={date}
              value={DATE_TEMPLATE.THIS_MONTH}
              onChange={handleChangeDate}
            />
            <DateTabItem
              text={'지난 달'}
              selectedDate={date}
              value={DATE_TEMPLATE.LAST_MONTH}
              onChange={handleChangeDate}
            />
          </ul>
        </div>
      </div>

      {/* 정렬 선택 */}
      <div className={`tab-select-area bg`}>
        <div className="select-tab">
          <h2>카테고리 선택</h2>
          {period === PERIOD_TYPE.MONTH ? (
            <CategorySelector
              categories={categories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="search-box">
          <div className="search-area">
            <input
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
              type="search"
              id="donorSearch"
              name="donorSearch"
              placeholder="큰손검색"
            />
            <span className="ico-search"></span>
          </div>
        </div>
      </div>

      {/* 리스트 영역 */}
      {!isLoading && bigDonorList ? (
        <BigDonorTable
          {...{
            bigDonorList,
            categories,
            liveChannels,
            period,
            search,
            sortBy,
            setSortBy,
            selectedCategories,
            hiddenDonors,
            hiddenBjs,
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
