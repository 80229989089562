import couponStyles from './couponInfo.module.scss'
import styles from '../popup.module.scss'
import { format } from 'date-fns'

export default function CouponInfo({ onClose, coupon }: { onClose: (showCoupon: any) => void; coupon: any }) {
  return (
    <div className={styles.modalWrap}>
      <div className={`${styles.modalContent} ${styles.alert}`}>
        <div className={styles.header}>
          <p className={styles.title}> 쿠폰 상세 정보 보기 </p>
          <span onClick={() => onClose(null)} className={styles.close}>
            &times;
          </span>
        </div>
        <div className={styles.contsBox}>
          <div className={couponStyles.pointGuideArea}>
            <ul className={couponStyles.guide}>
              <li>
                <div className={couponStyles.subject}>상품명</div>
                <div className={couponStyles.pointTxt}>{coupon.reward.name}</div>
              </li>
              <li>
                <div className={couponStyles.subject}>쿠폰 번호</div>
                <div className={couponStyles.pointTxt}>{coupon.code}</div>
              </li>
              <li>
                <div className={couponStyles.subject}>유효 기간</div>
                <div className={couponStyles.pointTxt}>{format(coupon.endAt, 'yyyy년 MM월 dd일 HH:mm')} 까지</div>
              </li>
            </ul>
            <div className={couponStyles.notice}>
              <p className={couponStyles.subject}>이용 안내</p>
              <p className={couponStyles.couponGuide}>{coupon.guide}</p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <button type="button" onClick={() => navigator.clipboard.writeText(coupon.code)} className={styles.btnCancel}>
            쿠폰 번호 복사하기
          </button>
        </div>
      </div>
    </div>
  )
}
