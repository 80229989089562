import { useEffect, useRef, useState } from 'react'
import useAnnouncementStore from '@/stores/announcement'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { fetchBoardListByCategory } from '@/api/community/board'
import { LIMIT } from './shared/consts'
import { getHowLongAgo } from '@/helpers/community/BoardFunctions'
import ListOfBoards from '@components/pages/community/shared/ListOfBoards.tsx'
import BoardSignin from '@components/pages/community/shared/BoardSignin.tsx'
import commStyles from './commBoard.module.scss'
import styles from './board.module.scss'
import CategorySelector from './shared/CategorySelector'
import Paging from '@/components/elements/Paging'

export default function Announcement() {
  const [categoryID, setCategoryID] = useState(0)
  const [page, setPage] = useState(1)
  const { id } = useParams() as { id: string }
  const lastPage = useRef(1)
  const { announcements } = useAnnouncementStore()
  const announcement = announcements.find((announcement: any) => announcement.id === parseInt(id))

  const {
    data: otherBoardData,
    isLoading: otherBoardLoad,
    error: otherBoardError,
  } = useQuery(
    ['other-board', categoryID, page],
    () => fetchBoardListByCategory(String(categoryID), String(LIMIT), page, 'createdAt', 'desc', ''),
    {
      onSuccess: (data: any) => {
        lastPage.current = data.totalPostsCount === 0 ? 1 : Math.ceil(data.totalPostsCount / LIMIT)
      },
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    setPage(1)
  }, [categoryID])

  useEffect(() => {
    setCategoryID(announcement?.categoryID ?? 0)
  }, [announcement])

  if (otherBoardLoad || otherBoardError || !announcement) {
    return
  }

  return (
    <div className="contents-area">
      <div className={commStyles.bbsContsArea}>
        {/* contents */}
        <div className={commStyles.contsArea}>
          <div className={styles.bbsViewHead}>
            <div className="chip gray natural af">{announcement.category.name}</div>
            <div className={styles.headTitle}>{announcement.title}</div>
            <span className={styles.headName}>운영자</span>
            <div className={styles.contsBox}>
              <div className={styles.information}>
                <div className={styles.headCount}>
                  <span>{getHowLongAgo(new Date(announcement.createdAt))}</span>
                </div>
              </div>

              <div className={styles.nicknameBox}>
                <span className={styles.btn}>
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(`poong.today/community/announcement/${id}`)
                    }}
                  >
                    링크 복사
                  </button>
                </span>
              </div>
            </div>
          </div>

          <div className={styles.bbsViewContents}>
            <div className={`${styles.contents} ql-editor`}>
              <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
            </div>
          </div>
          <CategorySelector categoryID={categoryID} updateCategory={setCategoryID} />

          <ListOfBoards
            boardData={otherBoardData}
            announcementData={announcements.filter(
              (announcement: any) => announcement.categoryID === 0 || announcement.categoryID === categoryID,
            )}
          />

          <Paging page={page} setPage={setPage} lastPage={lastPage.current} />
        </div>
        <div className={commStyles.aside}>
          <BoardSignin showShop={false} showWrite={true} notLoginText="글쓰기는 로그인 후 이용 가능합니다" />
        </div>
      </div>
    </div>
  )
}
