import v2 from '../v2'

export async function fetchBoardByID(id: string) {
  return v2.get('/api/community/boards/' + id).then(resp => resp.data)
}

export async function fetchBoardListByCategory(
  categoryID: string,
  limit: any,
  page: any,
  sortBy: any,
  orderBy: any,
  search: any,
) {
  return v2
    .get('/api/community/boards/category/' + categoryID, {
      params: { limit: limit, page: page, sortBy: sortBy, orderBy: orderBy, search: search },
    })
    .then(resp => resp.data)
}

export async function addBoard(params: { categoryID: number; title: string; content: string }) {
  return v2.post('/api/community/boards', params, { withCredentials: true }).then(resp => resp.data)
}

export async function uploadImage(fileData: any) {
  return v2.post('/api/community/boards/uploads', fileData, { withCredentials: true }).then(resp => resp.data)
}

export async function updateBoard(
  id: string,
  params: {
    title: string
    content: string
    edited: boolean
    isDeleted: boolean
  },
) {
  return v2.patch('/api/community/boards/' + id, params, { withCredentials: true }).then(resp => resp.data)
}
