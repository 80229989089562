import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

export interface iPortalProps {
  children: ReactNode
}

export const ModalPopup = (props: iPortalProps) => {
  const el = document.getElementById('modal')!
  return ReactDOM.createPortal(props.children, el)
}
