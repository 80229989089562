import { DATA_COLUMN } from '../../pages/consts'

export default function ColumnTabItem({
  value = DATA_COLUMN.DONATION,
  selectedColumn = DATA_COLUMN.DONATION,
  text = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_v: DATA_COLUMN) => {},
}) {
  return (
    <span
      onClick={e => {
        e.preventDefault()
        onChange(value)
      }}
      className={value === selectedColumn ? 'sortableColumn active' : 'sortableColumn'}
    >
      {text}
    </span>
  )
}
