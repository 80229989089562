import { useState } from 'react'
import { useQuery } from 'react-query'
import { getBjListDay, getBjListMonth, getBjListYear } from '@/api/bjpt.ts'
import HistoryContent from '@components/pages/broadcast/History/HistoryContent.tsx'
import useAfreecaStore from '@/stores/afreeca.ts'
import useHiddenStore from '@/stores/hidden'
import { DATA_COLUMN, PERIOD_TYPE } from '../../consts'
import PeriodTabItem from '@/components/elements/tabs/PeriodTabItem'
import { today, startYear } from '@/utils/date.ts'
import DateSelector from '@/components/elements/selectors/DateSelector'
import MonthSelector from '@/components/elements/selectors/MonthSelector'
import YearSelector from '@/components/elements/selectors/YearSelector'
import CategorySelector from '@/components/elements/selectors/CategorySelector'
import BroadcastHeader from '../BroadcastHeader'

export default function BroadcastHistory() {
  const { categories, liveChannels, bjList } = useAfreecaStore()
  const { hiddenBjs } = useHiddenStore()
  const [period, setPeriod] = useState<PERIOD_TYPE>(PERIOD_TYPE.MONTH)
  const [listDate, setListDate] = useState(today())
  const [sortBy, setSortBy] = useState<DATA_COLUMN>(DATA_COLUMN.DONATION)
  const [search, setSearch] = useState('')
  const [selectedCategories, setSelectedCategories] = useState<{ key: string; value: string }[]>([])
  const [selectedMonth, setSelectedMonth] = useState<{ key: string; value: string }[]>([
    { key: String(listDate.getMonth()), value: String(listDate.getMonth() + 1).padStart(2, '0') },
  ])
  const [selectedYear, setSelectedYear] = useState<{ key: string; value: string }[]>([
    { key: String(listDate.getFullYear()), value: String(listDate.getFullYear()) },
  ])

  const {
    data: bjRanking,
    isLoading: bjRankingLoading,
    isError: bjRankingError,
  } = useQuery(['bjRanking', period, listDate], () => {
    if (period === PERIOD_TYPE.DAY) {
      return getBjListDay(listDate.getFullYear(), listDate.getMonth() + 1, listDate.getDate())
    } else if (period === PERIOD_TYPE.MONTH) {
      return getBjListMonth(listDate.getFullYear(), listDate.getMonth() + 1)
    } else if (period === PERIOD_TYPE.YEAR) {
      return getBjListYear(listDate.getFullYear())
    }
  })

  if (bjRankingError) {
    return
  }

  const isLoading = bjRankingLoading

  function handleChangePeriod(period: PERIOD_TYPE) {
    setPeriod(period)
    setListDate(today())
    setSortBy(DATA_COLUMN.DONATION)
    setSearch('')
    setSelectedMonth([{ key: String(today().getMonth()), value: String(today().getMonth() + 1).padStart(2, '0') }])
    setSelectedYear([{ key: String(today().getFullYear()), value: String(today().getFullYear()) }])
  }

  function dateButtonHandler(periodToAdd: PERIOD_TYPE, change: number) {
    const newDate = new Date(listDate)
    if (periodToAdd === PERIOD_TYPE.DAY) {
      newDate.setDate(change)
    } else if (periodToAdd === PERIOD_TYPE.MONTH) {
      newDate.setMonth(change)
    } else if (periodToAdd === PERIOD_TYPE.YEAR) {
      newDate.setFullYear(change)
    }
    if (startYear <= newDate.getFullYear() && newDate <= today()) {
      setListDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
      setSearch('')
    } else if (newDate.getFullYear() === today().getFullYear() && newDate > today()) {
      newDate.setMonth(today().getMonth())
      newDate.setDate(today().getDate())
      setListDate(newDate)
      setSelectedMonth([{ key: String(newDate.getMonth()), value: String(newDate.getMonth() + 1).padStart(2, '0') }])
      setSelectedYear([{ key: String(newDate.getFullYear()), value: String(newDate.getFullYear()) }])
      setSearch('')
    }
  }

  return (
    <div className="contents-area">
      <BroadcastHeader />
      <div className="history-wrap">
        <div className="history-aside">
          {/* 기간 선택 */}
          <div className="tab-select-area scroll">
            <div className="tab-type-round">
              <div>기간 선택</div>
              <ul>
                <PeriodTabItem
                  text={'일별 보기'}
                  selectedPeriod={period}
                  value={PERIOD_TYPE.DAY}
                  onChange={handleChangePeriod}
                />
                <PeriodTabItem
                  text={'월별 보기'}
                  selectedPeriod={period}
                  value={PERIOD_TYPE.MONTH}
                  onChange={handleChangePeriod}
                />
                <PeriodTabItem
                  text={'연별 보기'}
                  selectedPeriod={period}
                  value={PERIOD_TYPE.YEAR}
                  onChange={handleChangePeriod}
                />
              </ul>
            </div>
          </div>
        </div>
        <div className="history-conts">
          {period === PERIOD_TYPE.DAY && (
            <DateSelector
              date={listDate}
              setDate={setListDate}
              setSearch={setSearch}
              dateButtonHandler={dateButtonHandler}
            />
          )}
          {period === PERIOD_TYPE.MONTH && (
            <MonthSelector
              date={listDate}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              dateButtonHandler={dateButtonHandler}
              smaller={false}
            />
          )}
          {period === PERIOD_TYPE.YEAR && (
            <YearSelector date={listDate} selectedYear={selectedYear} dateButtonHandler={dateButtonHandler} />
          )}

          {/* 정렬 선택 */}
          <div className="tab-select-area bg">
            <div className="select-tab">
              <h2>카테고리 선택</h2>
              <CategorySelector
                categories={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </div>

            <div className="search-box">
              <div className="search-area">
                <input
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                  type="search"
                  id="broadcastSearch"
                  name="broadcastSearch"
                  placeholder="방송검색"
                />
                <span className="ico-search"></span>
              </div>
            </div>
          </div>
          {!isLoading && bjRanking ? (
            <HistoryContent
              {...{
                bjRanking: bjRanking.ranking,
                liveChannels,
                categories,
                bjList,
                period,
                search,
                listDate,
                sortBy,
                setSortBy,
                selectedCategories,
                hiddenIDs: hiddenBjs,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
