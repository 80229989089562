import styles from './mypage.module.scss'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

export const enum TAB {
  MYPAGE = 'MYPAGE',
  INQUIRY = 'INQUIRY',
}

function getActiveTab(pathname: string) {
  if (pathname.includes('mypage')) return TAB.MYPAGE
  if (pathname.includes('inquiries')) return TAB.INQUIRY
  return TAB.MYPAGE
}

export default function MyPageAside() {
  const location = useLocation()

  function getLink() {
    return (
      <ul>
        <li>
          <Link to="/my/mypage" className={cx({ active: getActiveTab(location.pathname) === TAB.MYPAGE })}>
            내 계정
          </Link>
        </li>
        <li>
          <Link to="/my/inquiries" className={cx({ active: getActiveTab(location.pathname) === TAB.INQUIRY })}>
            1:1 문의
          </Link>
        </li>
      </ul>
    )
  }
  return (
    <>
      <div className={`history-aside ${styles.mypageAside}`}>
        {/* 메뉴 선택 */}
        <div className="tab-select-area">
          <div className="tab-type-round w-100">
            {/* <div>메뉴 선택</div> */}
            {getLink()}
          </div>
        </div>
      </div>
      <div className={styles.moTabArea}>
        <div className="tab-type-horizon">{getLink()}</div>
      </div>
    </>
  )
}
