import { today } from '@/utils/date'
import axios from 'axios'
import { getBroadcastHourWithDonation } from '@/helpers/broadcast.ts'

const baseUrl = import.meta.env.VITE_STATIC_HOSTNAME

export async function getBjListLive(liveChannels: any) {
  const currentDate = today()
  return axios
    .get(
      `${baseUrl}/chart/get?ctype=day&ks=false&year=${currentDate.getFullYear()}&month=${currentDate.getMonth() + 1}&day=${currentDate.getDate()}`,
    )
    .then(response => {
      if (response.data.error) {
        return []
      } else {
        return response.data
          .filter((bj: any) => liveChannels.includes(bj.i))
          .sort((bj1: any, bj2: any) => bj2.b - bj1.b)
          .map((bj: any) => ({
            ...bj,
            t: getBroadcastHourWithDonation(bj.h) * 3600,
          }))
      }
    })
    .then(data => {
      if (data.length === 0) {
        return {
          ranking: [],
        }
      } else {
        return {
          ranking: data,
          topDonated: data[0],
          topViewer: data.reduce((bj1: any, bj2: any) => (bj1.v > bj2.v ? bj1 : bj2)),
          topHourly: data.reduce((bj1: any, bj2: any) => (bj1.b / bj1.t > bj2.b / bj2.t || bj2.t === 0 ? bj1 : bj2)),
        }
      }
    })
    .catch(error => console.error(error))
}

export async function getBjListDay(year: number, month: number, day: number) {
  return axios
    .get(`${baseUrl}/chart/get?ctype=day&ks=false&year=${year}&month=${month}&day=${day}`)
    .then(response => {
      if (response.data.error) {
        return []
      } else {
        return response.data
          .sort((bj1: any, bj2: any) => bj2.b - bj1.b)
          .map((bj: any) => ({
            ...bj,
            t: getBroadcastHourWithDonation(bj.h) * 3600,
          }))
      }
    })
    .then(data => {
      if (data.length === 0) {
        return {
          ranking: [],
        }
      } else {
        return {
          ranking: data,
          topDonated: data[0],
          topViewer: data.reduce((bj1: any, bj2: any) => (bj1.v > bj2.v ? bj1 : bj2)),
          topHourly: data.reduce((bj1: any, bj2: any) => (bj1.b / bj1.t > bj2.b / bj2.t || bj2.t === 0 ? bj1 : bj2)),
        }
      }
    })
    .catch(error => console.error(error))
}

export async function getBjListMonth(year: number, month: number) {
  return axios
    .get(`${baseUrl}/chart/get?ctype=month&ks=false&year=${year}&month=${month}&day=undefined`)
    .then(response => {
      if (response.data.error) {
        return {
          ranking: [],
        }
      } else {
        return {
          ranking: response.data.b.sort((bj1: any, bj2: any) => bj2.b - bj1.b),
          topDonated: response.data.b.reduce((bj1: any, bj2: any) => (bj1.b > bj2.b ? bj1 : bj2)),
          topViewer: response.data.b.reduce((bj1: any, bj2: any) => (bj1.v > bj2.v ? bj1 : bj2)),
          topHourly: response.data.b.reduce((bj1: any, bj2: any) =>
            bj1.b / bj1.t > bj2.b / bj2.t || bj2.t === 0 ? bj1 : bj2,
          ),
        }
      }
    })
    .catch(error => console.error(error))
}

export async function getBjListYear(year: number) {
  return axios
    .get(`${baseUrl}/chart/get?ctype=year&year=${year}&vtype=all&ks=false`)
    .then(response => {
      if (response.data.error) {
        return {
          ranking: [],
        }
      } else {
        return {
          ranking: response.data,
        }
      }
    })
    .catch(error => console.error(error))
}
