import axios from 'axios'
import v2 from './v2'
import { format } from 'date-fns'
import { ko } from 'date-fns/locale'
import { convertToJson } from '@/utils/jsonl'
import { HOUR_BUFFER } from '@/utils/date'

const baseUrl = import.meta.env.VITE_STATIC_HOSTNAME

export async function getMonthlyBjBroadStats(id: string, year: number, month: number) {
  return axios
    .get(`${baseUrl}/bj/detail/get?id=${id}&year=${year}&month=${month}`)
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function getAfreecaCount() {
  return axios
    .get(`${baseUrl}/broad/count`)
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return { v: 0, m: 0, c: 0, b: 0 }
    })
}

export async function getBigDonorDay(year: number, month: number, day: number) {
  return axios
    .get(`${baseUrl}/chart/get?ctype=day&ks=true&year=${year}&month=${month}&day=${day}`)
    .then(response => {
      if (response.data.error) {
        return []
      } else {
        return response.data
      }
    })
    .catch(error => console.error(error))
}

export async function getBigDonorMonth(year: number, month: number) {
  return axios
    .get(`${baseUrl}/chart/get?ctype=month&ks=true&year=${year}&month=${month}&day=undefined`)
    .then(response => {
      if (response.data.error) {
        return []
      } else {
        return response.data
      }
    })
    .catch(error => console.error(error))
}

export async function getLiveList() {
  return axios
    .get(`${baseUrl}/broad/live`)
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function getCategories() {
  return axios
    .get(`${baseUrl}/category/list`)
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function getBjList() {
  return axios
    .get(`${baseUrl}/bj/list`)
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function getAD() {
  return axios
    .get('https://uploads.poong.today/assets/ad_list.json')
    .then(response => response.data)
    .catch(error => console.error(error))
}

export async function get2DayBroadcastSummary(today: Date, yday: Date) {
  let tList: string[] = []
  let bList: number[] = []
  let vList: number[] = []
  let cList: number[] = []
  let bTodayMax = 0
  let vTodayMax = 0
  let cTodayMax = 0
  let bYdayMax = 0
  let vYdayMax = 0
  let cYdayMax = 0
  await v2
    .get('/broad/summary?date=' + format(new Date(yday), 'yyyyMMdd'))
    .then(response => {
      let data = convertToJson(response.data)
      let accum = 0
      for (let s of data) {
        const [hour, min] = s.t.split(':')
        const h = parseInt(hour)
        const m = parseInt(min)
        if (h < HOUR_BUFFER || (h === 8 && m < 6)) {
          const date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), h, m)
          tList.push(format(date, 'MM.dd (E) HH:mm', { locale: ko }))
        } else {
          const date = new Date(yday.getFullYear(), yday.getMonth(), yday.getDate(), h, m)
          tList.push(format(date, 'MM.dd (E) HH:mm', { locale: ko }))
        }
        let bVal = s.b - accum
        if (bVal > bYdayMax) {
          bYdayMax = bVal
        }
        accum = s.b
        if (s.v > vYdayMax) {
          vYdayMax = s.v
        }
        if (s.c > cYdayMax) {
          cYdayMax = s.c
        }
        bList.push(bVal)
        vList.push(s.v)
        cList.push(s.c)
      }
    })
    .catch(error => console.error(error))

  await v2
    .get('/broad/summary?date=' + format(new Date(today), 'yyyyMMdd'))
    .then(response => {
      let data = convertToJson(response.data)
      let accum = 0
      for (let s of data) {
        const [hour, min] = s.t.split(':')
        const h = parseInt(hour)
        const m = parseInt(min)
        if (h < HOUR_BUFFER || (h === 8 && m < 6)) {
          const now = new Date()
          now.setHours(h)
          now.setMinutes(m)
          tList.push(format(now, 'MM.dd (E) HH:mm', { locale: ko }))
        } else {
          const date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), h, m)
          tList.push(format(date, 'MM.dd (E) HH:mm', { locale: ko }))
        }
        let bVal = s.b - accum
        if (bVal > bTodayMax) {
          bTodayMax = bVal
        }
        accum = s.b
        if (s.v > vTodayMax) {
          vTodayMax = s.v
        }
        if (s.c > cTodayMax) {
          cTodayMax = s.c
        }
        bList.push(bVal)
        vList.push(s.v)
        cList.push(s.c)
      }
    })
    .catch(error => console.error(error))

  return {
    tList,
    bList,
    vList,
    cList,
    bTodayMax,
    vTodayMax,
    cTodayMax,
    bYdayMax,
    vYdayMax,
    cYdayMax,
  }
}
