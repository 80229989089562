import commStyles from './commBroad.module.scss'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

export const enum TAB {
  LIVE = 'LIVE',
  BROADCAST = 'BROADCAST',
  HISTORY = 'HISTORY',
}

function getActiveTab(pathname: string) {
  if (pathname.includes('live')) return TAB.LIVE
  if (pathname.includes('history')) return TAB.HISTORY
  return TAB.BROADCAST
}

export default function BroadcastHeader() {
  const location = useLocation()
  return (
    <div className={commStyles.tabArea}>
      <div className={commStyles.title}>
        <h2>방송 랭킹</h2>
        {/* <span>아프리카TV와 치지직을 다양한 기준으로 분석해 봤어요</span> */}
      </div>
      <div className="tab-type-horizon">
        <ul>
          <li>
            <Link
              to="/rankings/broadcast/live"
              className={cx({ active: getActiveTab(location.pathname) === TAB.LIVE })}
            >
              Live 방송 랭킹
            </Link>
          </li>
          <li>
            <Link
              to="/rankings/broadcast"
              className={cx({ active: getActiveTab(location.pathname) === TAB.BROADCAST })}
            >
              방송 랭킹
            </Link>
          </li>
          <li>
            <Link
              to="/rankings/broadcast/history"
              className={cx({ active: getActiveTab(location.pathname) === TAB.HISTORY })}
            >
              히스토리
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
